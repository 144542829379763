import { CustomerComponent } from './customer.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddCustomerComponent } from './components/add-customer/add-customer.component';
import { RouterModule } from '@angular/router';
import { CustomerListComponent } from './components/customer-list/customer-list.component';
import { ViewCustomerComponent } from './components/view-customer/view-customer.component';
import { TableModule } from 'src/app/lib/table/table.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from "@angular/common";


import { AuthorizationModule } from '../../modules/authorization/authorization.module';
import { AddOrEditCustomerComponent } from './components/add-or-edit-customer/add-or-edit-customer.component';
import { AddCustomerAdminComponent } from './components/add-customer-admin/add-customer-admin.component';
import { ApplicationResourcesTableComponent } from './components/application-resources-table/application-resources-table.component';
import { AssignOrUpdateCustomerResourcesComponent } from '../popups/assign-or-update-customer-resources/assign-or-update-customer-resources.component';
import { PopupModule } from '../popups/popup.module';
import { AssignResourcesComponent } from './components/assign-resources/assign-resources.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';

const routes = [
  { path: '', pathMatch: "full", component: CustomerComponent },
  { path: 'addCustomer', pathMatch: "full", component: AddOrEditCustomerComponent },
  { path: 'assignUpdateResources', pathMatch: "full", component: AssignOrUpdateCustomerResourcesComponent },
  { path: 'assignResources', pathMatch: "full", component: AssignResourcesComponent },
]
@NgModule({
  declarations: [
    CustomerComponent,
    AddCustomerComponent,
    CustomerListComponent,
    ViewCustomerComponent,
    AddOrEditCustomerComponent,
    AddCustomerAdminComponent,
    ApplicationResourcesTableComponent,
    AssignResourcesComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    NgxIntlTelInputModule,
    PopupModule,
    TableModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    AuthorizationModule,
    RouterModule.forChild(routes),
  ], 
  exports:[
    ViewCustomerComponent
  ],
  entryComponents:[
    ViewCustomerComponent,
    AddCustomerComponent,
    AddCustomerAdminComponent
  ],
  providers: [
    DatePipe
  ],
})
export class CustomerModule { }
