import { isNullOrUndefined } from 'util';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, EventEmitter, Output, Input, OnChanges, SimpleChanges } from '@angular/core';
import { HttpService } from 'src/app/services';

@Component({
  selector: 'app-role-valid-scope',
  templateUrl: './role-valid-scope.component.html',
  styleUrls: ['./role-valid-scope.component.scss']
})
export class RoleValidScopeComponent implements OnInit, OnChanges {
  @Input('initScope') selectedScope: any[] = [];
  @Input('scopeEndPoint') scopeEndPoint: string = '';
  @Input('application') application: string = '';
  @Output('onScopeUpdate') onScopeUpdate = new EventEmitter<any>();

  scopeTypes: any[] = [];
  scopeNames: {[key: string]: {[key: string]: any[]}} = {};

  max: number;
  userMgmtApplicationId: string = '6d421cf8-5ed8-459b-a9b3-6236c57b7094';

  scopeForm = new FormGroup({
    validScopes: new FormArray([])
  })

  constructor(
    private httpSrv: HttpService
  ) { }

  ngOnInit() {
    this.validScopesArray.valueChanges.subscribe(data => {
      const areValid = data.reduce((a, e) => !a ? a : e.scopeType && e.scopeName,true);
      if(areValid) {
        // console.log("*********Validscope changed*************:: ", data);
        // Flatten the scope records.
        let flattenedRecords: any[] = [];

        data.forEach(scope => {
          if(typeof scope.scopeName === 'string') {
            flattenedRecords.push(this.buildScopeObj(scope.scopeType, scope.scopeName));
            return;
          }

          scope.scopeName.forEach(sn => {
            flattenedRecords.push(this.buildScopeObj(scope.scopeType, sn));
          })
        })
        this.onScopeUpdate.emit(flattenedRecords);
      }
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    // If application changes
    if(changes.application) {
      this.validScopesArray.clear();
      this.getScopeTypesInApplication();
    }

    // If scope updated
    if(changes.selectedScope) {
      this.validScopesArray.clear();

      // Group by scopeType
      const currentScope = changes.selectedScope.currentValue || [];
      const scopeNamesGroupByType = {};
      
      currentScope.forEach(s => {
        // Fetch the existing scope names in that type
        // if not there insert new key with scope type
        scopeNamesGroupByType[s.scopeType] = scopeNamesGroupByType[s.scopeType] || [];

        // Push the current scope name into the array.
        scopeNamesGroupByType[s.scopeType].push(s.scopeName);
      });

      Object.keys(scopeNamesGroupByType).forEach(st => {
        let scopeObj = {
          scopeType: st,
          scopeName: scopeNamesGroupByType[st]
        }
        this.addNewScope(scopeObj);
      })
    }
  }

  updateMax() {
    this.max = this.scopeTypes.length;
  }

  getScopeTypesInApplication() {
    this.scopeTypes = [];
    this.updateMax();

    if(!this.scopeEndPoint) {
      console.error("Scope end point is missing for application: " + this.application);
      return;
    }

    this.httpSrv.makeGetApiCall("GET_SCOPE_TYPES", this.scopeEndPoint)
    .subscribe(res => {
      this.scopeTypes = res;
      this.updateMax();
      const types = {};
      this.selectedScope.forEach(s => types[s.scopeType] = true);

      // Load options for all the types that are already selected.
      this.scopeTypes.forEach(type => this.getScopeNamesUnderTypes(type));
    }, err => {
      console.log("err:: ", err);
    })

    // TODO:
    // this.scopeTypes = this.application === this.userMgmtApplicationId ? ['NON_HIERARCHY'] : [];
    // this.getScopeNamesUnderTypes('NON_HIERARCHY');
    // this.max = this.scopeTypes.length;
  }

  getScopeNamesUnderTypes(type: string) {
    // TODO:
    if(!this.scopeNames[this.application]) {
      this.httpSrv.makeGetApiCall('GET_SCOPE_NAMES_UNDER_TYPE', this.scopeEndPoint, {replaceParams: {REPLACE_SCOPE_TYPE: type}})
      .subscribe(res => {
        const scopeNames = res.response || [];
        this.scopeNames = this.scopeNames || {};
        this.scopeNames[this.application] = this.scopeNames[this.application] || {};
        this.scopeNames[this.application][type] = scopeNames;
      }, err => {
        console.log("err:: ", err);
      })
    }
    // this.scopeNames = {
    //   [this.userMgmtApplicationId]: ['Application']
    // }
  }

  getScopeTypeFromValidScopesArray(index: number) {
    if(isNullOrUndefined(index)) return null;

    if(!this.validScopesArray.controls || !this.validScopesArray.controls[index]) return null;
    
    return this.validScopesArray.controls[index].get('scopeType').value;
  }

  getScopeNamesForType(index: number) {
    const type = this.getScopeTypeFromValidScopesArray(index);
    // console.log("getScopeNamesForType:: ", type);
    if(!type || !this.scopeNames[this.application]) return [];
    return this.scopeNames[this.application][type] || [];
  }

  // get scopeNamesInApplication(): any[] {
  //   return this.scopeNames && this.scopeNames[this.application] ? this.scopeNames[this.application] : [];
  // }

  get validScopesArray(): FormArray {
    return this.scopeForm.get('validScopes') as FormArray
  }

  get selectedScopeTypes(): string[] {
    const currentScope = this.validScopesArray.value;
    return currentScope.map(s => s.scopeType);
  }

  getScopeType(index: number) {
    const scopeInfo = this.validScopesArray.value[index];
    return scopeInfo ? scopeInfo.scopeType : null;
  }

  buildScopeId(st: string, sn: string) {
    return st + "##" + sn;
  }

  buildScopeObj(st: string, sn: string) {
    return {
      scopeType: st,
      scopeName: sn
    }
  }

  newScope(value: any): FormGroup {
    value = value || {};
    return new FormGroup({
      scopeType: new FormControl(value.scopeType || '', [Validators.required]),
      scopeName: new FormControl(value.scopeName || '', [Validators.required])
    });
  }

  addNewScope(value: any = {scopeType: '', scopeName: ''}) {
    let isValidScopes = this.validScopesArray.controls.reduce((a, e) => !a ? a : e.valid, true);
    if(!isValidScopes || (this.max && this.validScopesArray.length >= this.max)) {
      console.log("Existing form is empty or limit exceeded");
      return;
    }
    this.validScopesArray.push(this.newScope(value));
  }

  removeScope(index: number) {
    this.validScopesArray.removeAt(index);
  }

  updateSelectedScope() {
    // this.scope = (this.data[this.application] || []).map(s => {
    //   console.log('selectedScope:: ', this.selectedScope);
    //   s.selectedNames = this.selectedScope.filter(cs => s.type === cs.scopeType).map(cs => cs.scopeName);
    //   s.selectedNames = s.selectedNames || [];

    //   // if(s.type === 'HIERARCHY') {
    //   //   s.parents = this.getParents('District', this.data[this.application]);
    //   // }
    //   return s;
    // });
    console.log("Updated scope:: ", this.validScopesArray.value);
  }

}
