import { Component, OnInit } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { CommonService } from '../../services/common.service';
import { HttpService } from '../../services/http.service';
import { UtilsService } from '../../services/utils.service';

import { AddApplicationComponent } from './components/add-application/add-application.component';
import { USER_MGMT_CNST } from 'src/app/constants/proj.cnst';
import { EnvService } from 'src/app/services/env.service';

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss']
})
export class ApplicationsComponent implements OnInit {

  tableConfig: any = {}
  tableData: any = [];

  constructor(
    private modal: NgbModal,
    private commonSrv : CommonService,
    private httpSrv: HttpService,
    private utilSrv: UtilsService,
    private envService : EnvService
  ) { }

  ngOnInit() {
    this.setTableConfig();
    this.setTableData();
  }

  setTableConfig() {
    this.tableConfig = this.commonSrv.setTableConfig('applicationlist')
  }

  setTableData() {
    this.httpSrv
      .makeGetApiCall('FETCH_ALL_RESOURCES_UNDER_RESOURCE_TYPE', this.envService.baseUrl)
      .subscribe((response: any) => {
        let appData = response;
        let applications = Object.keys(appData);
        let tableData = applications.map(app=> {
          let data = {
          applicationName: app,
          resources: appData[app]
          }
          return data
        })
        this.tableData = tableData;
      }, error => {
        this.utilSrv.showToastMessage("Failed to fetch applications list", "error");
      })
  }

  openAddApplicationModal() {
    this.modal.open(AddApplicationComponent, USER_MGMT_CNST.MODAL_OPTIONS);
  }

}
