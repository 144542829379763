import { RolesComponent } from './roles.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddRoleComponent } from './components/add-role/add-role.component';
import { RouterModule } from '@angular/router';
import { RolesListComponent } from './components/roles-list/roles-list.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { EditRoleComponent } from './components/edit-role/edit-role.component';
import { TableModule } from 'src/app/lib/table/table.module';
import { ViewRolesComponent } from './components/view-roles/view-roles.component';
import { SharedModule } from 'src/app/modules/shared/shared.module';

const routes = [
  { path: '', redirectTo: "list", pathMatch: "full" },
  { path: 'list', component: RolesListComponent },
  { path: 'add', component: AddRoleComponent }
]


@NgModule({
  declarations: [
    RolesComponent,
    RolesListComponent,
    AddRoleComponent,
    EditRoleComponent,
ViewRolesComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
TableModule,
SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    RouterModule.forChild(routes),
  ],
  entryComponents:[AddRoleComponent, EditRoleComponent,ViewRolesComponent]

})
export class RolesModule { }
