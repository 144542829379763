import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router';

import { AuthorizationModule } from '../authorization/authorization.module';
import { LayoutComponent } from './layout.component';
import { InitService } from 'src/app/parsers/init.service';
import { Config, Path } from 'src/app/enums';

export function initializeLogoConfig(initSrv: InitService) {
  return () => initSrv.loadConfig({
    path: Path.LOGO_CONFIG_PATH,
    configType: Config.LOGO_CONFIG
  });
}
export function initializeSideBarConfig(initSrv: InitService) {
  return () => initSrv.loadConfig({
    path: Path.SIDEBAR_CONFIG_PATH,
    configType: Config.SIDEBAR_CONFIG
  });
}

@NgModule({
  declarations: [
    SidebarComponent,
    HeaderComponent,
    FooterComponent,
    LayoutComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    AuthorizationModule
  ],
  exports: [
    SidebarComponent,
    HeaderComponent,
    FooterComponent
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeLogoConfig,
      deps: [InitService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeSideBarConfig,
      deps: [InitService],
      multi: true,
    }
  ],
})
export class LayoutModule { }
