import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { USER_MGMT_CNST } from 'src/app/constants';
import { UM_TABLE_CNST } from 'src/app/constants/table.cnst'
import { DeleteComponent } from 'src/app/modules/shared/components/delete/delete.component';
import { AppStateService, UtilsService } from 'src/app/services';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';


@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.scss']
})
export class CustomerListComponent implements OnInit {
  @Input() tableConfig: any = {}
  @Input() tableData: any = [];
  
  dataTypes = UM_TABLE_CNST.CLICKABLE_DATA_TYPE;
  modalData: any = [];

  constructor(
    private modal: NgbModal,
    private appStateSrv: AppStateService,
    private utilSrv : UtilsService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  handleOptionClick(e) {
    let rowData = e.data;
    let { CUSTOMER_MODULE_OPERATIONS } = USER_MGMT_CNST;

    if (e.val == USER_MGMT_CNST.OPTIONS.VIEW) {
      let postData = this.tableData[e.key];

      let { customerId } = postData;

      if (!isNullOrUndefined(customerId)) {
        this.appStateSrv.setCustomerData({ operation: CUSTOMER_MODULE_OPERATIONS.VIEW_CUSTOMER, info: postData });
       
        this.router.navigate(['', 'customers', 'addCustomer'], {
          queryParams: {
            operation: CUSTOMER_MODULE_OPERATIONS.VIEW_CUSTOMER,
            customerId
          }
        });

      } else {
        console.log("Customer id is null or undefined");
        this.utilSrv.showToastMessage("Customer id is missing", "error");
      }
    }

    else if (e.val == USER_MGMT_CNST.OPTIONS.DELETE) {
      const modalRef = this.modal.open(DeleteComponent, USER_MGMT_CNST.DELETE_POPUP_OPTIONS);

      let selectedCustomerRow = this.tableData[e.key];

      if (Object.keys(selectedCustomerRow).length > 0) {
        let { customerId } = selectedCustomerRow;

        let postData = { customerId }

        if (!isNullOrUndefined(customerId)) {
          modalRef.result
            .then((result: any) => {
              if (!isNullOrUndefined(result)) {
                this.setOperationType({
                  operation: USER_MGMT_CNST.OPERATION_TYPES.CUSTOMER.DELETE_CUSTOMER,
                  postData
                })
              }
            })
            .catch((reason: any) => {
              console.log("Reason for rejection ", reason);
            })
        }
      }
    }

    else {
         let postData = this.tableData[e.key];
         this.setOperationType({operation: USER_MGMT_CNST.OPERATION_TYPES.CUSTOMER.EDIT_CUSTOMER,
        postData})
    }
  }

  setOperationType(value : any) {
    this.appStateSrv.operationRef.next(value);
  }
}
