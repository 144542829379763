import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { USER_MGMT_CNST } from 'src/app/constants/proj.cnst';

@Component({
  selector: 'app-view-roles',
  templateUrl: './view-roles.component.html',
  styleUrls: ['./view-roles.component.scss']
})
export class ViewRolesComponent implements OnInit {

  @Input() modalData;
  modalDataStatus : any = USER_MGMT_CNST.MODAL_STATUS;


  constructor(
    public activeModal: NgbActiveModal
    ) { }
//   dummyData = [
//     {label:'Role',value:'Role Name 01'},
//     {label:'Description',value:'Some Content......'}
// ]

  ngOnInit() {
  }
}
