import { Component, OnInit,Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { USER_MGMT_CNST } from 'src/app/constants';

@Component({
  selector: 'app-view-customer',
  templateUrl: './view-customer.component.html',
  styleUrls: ['./view-customer.component.scss']
})
export class ViewCustomerComponent implements OnInit {
  @Input() modalData;
  modalDataStatus :any = USER_MGMT_CNST.MODAL_STATUS;
  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
  }
}
