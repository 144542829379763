import { Component, OnInit, Input, TemplateRef } from '@angular/core';

@Component({
  selector: '[lib-total-row]',
  templateUrl: './total-row.component.html',
  styleUrls: ['./total-row.component.css']
})
export class TableTotalRowComponent implements OnInit {

  @Input() totalrow: any;
  @Input() noData: boolean;
  @Input() indexCons: any;
  @Input() customTemplates: TemplateRef<any>;

  constructor() { }

  ngOnInit() { }

}
