const TABLE_COLUMNS = {
  'userlist': [
    {
      name: 'Action',
      dataType: "OPTIONS",
      jsonKey: {
        path: 'options'
      },
      hasMultiColumnFilter: false,
      // styles: {
      //   header: {
      //     width: '50px',
      //     marginBottom: '20px'
      //   }
      // },
    },
    {
      name: 'Email',
      dataType: "TEXT",
      jsonKey: {
        path: 'emailId'
      },
      hasMultiColumnFilter: true,
      styles: {
        header: {
          width: '75px'
        }
      }
    },
    {
      name: 'Username',
      dataType: "TEXT",
      jsonKey: {
        path: 'username'
      },
      hasMultiColumnFilter: true,
      styles: {
        header: {
          width: '70px'
        }
      }
    },
    {
      name: 'Customer Name',
      dataType: "TEXT",
      jsonKey: {
        path: 'customerName'
      },
      hasMultiColumnFilter: true,
      styles: {
        header: {
          width: '105px'
        }
      }
    },
    {
      name: 'Role',
      dataType: "TEXT",
      jsonKey: {
        path: 'applicationDetails.roleName'
      },
      hasMultiColumnFilter: true,
      styles: {
        header: {
          width: '70px'
        }
      }
    },
    {
      name: 'Applications',
      dataType: "TEXT",
      jsonKey: {
        path: 'applicationDetails.applicationName'
      },
      hasMultiColumnFilter: true,
      // templateName: 'status',
      styles: {
        header: {
          width: '115px'
        }
      }
    },
    {
      name: 'Last Login',
      dataType: "TEXT",
      jsonKey: {
        path: 'lastLoginTs'
      },
      hasMultiColumnFilter: true,
      styles: {
        header: {
          width: '95px'
        }
      }
    },
    {
      name: 'Status',
      dataType: "TEXT",
      jsonKey: {
        path: 'status'
      },
      hasMultiColumnFilter: true,
      templateName: 'status',
      styles: {
        header: {
          width: '95px'
        }
      }
    }
  ],

  'usercustomerlist': [
    {
      name: 'Action',
      dataType: "OPTIONS",
      jsonKey: {
        path: 'options'
      },
      hasMultiColumnFilter: false,
      styles: {
        header: {
          width: '50px',
          marginBottom: '20px'
        }
      },
    },
    {
      name: 'Email',
      dataType: "TEXT",
      jsonKey: {
        path: 'emailId'
      },
      hasMultiColumnFilter: true,
      styles: {
        header: {
          width: '75px'
        }
      }
    },
    {
      name: 'Username',
      dataType: "TEXT",
      jsonKey: {
        path: 'username'
      },
      hasMultiColumnFilter: true,
      styles: {
        header: {
          width: '70px'
        }
      }
    },
    {
      name: 'Role',
      dataType: "TEXT",
      jsonKey: {
        path: 'applicationDetails.roleName'
      },
      hasMultiColumnFilter: true,
      styles: {
        header: {
          width: '70px'
        }
      }
    },
    {
      name: 'Applications',
      dataType: "TEXT",
      jsonKey: {
        path: 'applicationDetails.applicationName'
      },
      hasMultiColumnFilter: true,
      // templateName: 'status',
      styles: {
        header: {
          width: '115px'
        }
      }
    },
    {
      name: 'Last Login',
      dataType: "TEXT",
      jsonKey: {
        path: 'lastLoginTs'
      },
      hasMultiColumnFilter: true,
      styles: {
        header: {
          width: '95px'
        }
      }
    },
    {
      name: 'Status',
      dataType: "TEXT",
      jsonKey: {
        path: 'status'
      },
      hasMultiColumnFilter: true,
      templateName: 'status',
      styles: {
        header: {
          width: '95px'
        }
      }
    }
  ],

  'rolelist' : [
    {
      name: 'Role Name',
      dataType: "TEXT",
      jsonKey: {
        path: 'rolename'
      },
      hasMultiColumnFilter: true,
      styles: {
        header: {
          width: '190px'
        }
      }
    },
    {
      name: 'Role Description',
      dataType: "TEXT",
      jsonKey: {
        path: 'roledesc'
      },
      hasMultiColumnFilter: true,
      styles: {
        header: {
          width: '190px'
        }
      }
    },
    {
      name: 'Status',
      dataType: "TEXT",
      jsonKey: {
        path: 'status'
      },
      hasMultiColumnFilter: true,
      styles: {
        header: {
          width: '190px'
        }
      }
    },
    {
      name: 'Action',
      dataType: "OPTIONS",
      jsonKey: {
        path: 'options'
      },
      hasMultiColumnFilter: false,
      // styles: {
      //   header: {
      //     width: '50px',
      //     marginBottom: '20px'
      //   }
      // },
    }
  ],
  'profilelistcustomer' : [
    {
      name: 'Action',
      dataType: "OPTIONS",
      jsonKey: {
        path: 'options'
      },
      hasMultiColumnFilter: false,
      // styles: {
      //   header: {
      //     width: '50px',
      //     marginBottom: '20px'
      //   }
      // },
    },
    {
      name: 'Role Name',
      dataType: "TEXT",
      jsonKey: {
        path: 'roleName'
      },
      hasMultiColumnFilter: true,
      styles: {
        header: {
          width: '190px'
        }
      }
    },
    {
      name: 'Application Name',
      dataType: "TEXT",
      jsonKey: {
        path: 'applicationName'
      },
      hasMultiColumnFilter: true,
      styles: {
        header: {
          width: '190px'
        }
      }
    },
    {
      name: 'Description',
      dataType: "TEXT",
      jsonKey: {
        path: 'description'
      },
      hasMultiColumnFilter: true,
      styles: {
        header: {
          width: '190px'
        }
      }
    },
    {
      name: 'Status',
      dataType: "TEXT",
      jsonKey: {
        path: 'status'
      },
      hasMultiColumnFilter: true,
      templateName: 'status',
      styles: {
        header: {
          width: '190px'
        }
      }
    },
    {
      name: 'Date Added',
      dataType: "TEXT",
      jsonKey: {
        path: 'addedDate'
      },
      hasMultiColumnFilter: true,
      styles: {
        header: {
          width: '190px'
        }
      }
    },
    {
      name: '# of URL',
      dataType: "NUMBER",
      jsonKey: {
        path: 'resourceCount.URLs'
      },
      hasMultiColumnFilter: true,
      styles: {
        header: {
          width: '120px'
        }
      }
    },
    {
      name: '# of Dashboard',
      dataType: "NUMBER",
      jsonKey: {
        path: 'resourceCount.Dashboards'
      },
      hasMultiColumnFilter: true,
      styles: {
        header: {
          width: '120px'
        }
      }
    },
    {
      name: '# of Widget',
      dataType: "NUMBER",
      jsonKey: {
        path: 'resourceCount.Widgets'
      },
      hasMultiColumnFilter: true,
      styles: {
        header: {
          width: '120px'
        }
      }
    },
    {
      name: '# of API',
      dataType: "NUMBER",
      jsonKey: {
        path: 'resourceCount.APIs'
      },
      hasMultiColumnFilter: true,
      styles: {
        header: {
          width: '120px'
        }
      }
    },
    {
      name: '# of Others',
      dataType: "NUMBER",
      jsonKey: {
        path: 'resourceCount.Others'
      },
      hasMultiColumnFilter: true,
      styles: {
        header: {
          width: '120px'
        }
      }
    }
  ],

  'profilelist' : [
    {
      name: 'Action',
      dataType: "OPTIONS",
      jsonKey: {
        path: 'options'
      },
      hasMultiColumnFilter: false,
      // styles: {
      //   header: {
      //     width: '50px',
      //     marginBottom: '20px'
      //   }
      // },
    },
    {
      name: 'Role Name',
      dataType: "TEXT",
      jsonKey: {
        path: 'roleName'
      },
      hasMultiColumnFilter: true,
      styles: {
        header: {
          width: '190px'
        }
      }
    },
    {
      name: 'Application Name',
      dataType: "TEXT",
      jsonKey: {
        path: 'applicationName'
      },
      hasMultiColumnFilter: true,
      styles: {
        header: {
          width: '190px'
        }
      }
    },
    {
      name: 'Customer Name',
      dataType: "TEXT",
      jsonKey: {
        path: 'customerName'
      },
      hasMultiColumnFilter: true,
      styles: {
        header: {
          width: '190px'
        }
      }
    },
    {
      name: 'Description',
      dataType: "TEXT",
      jsonKey: {
        path: 'description'
      },
      hasMultiColumnFilter: true,
      styles: {
        header: {
          width: '190px'
        }
      }
    },
    {
      name: 'Status',
      dataType: "TEXT",
      jsonKey: {
        path: 'status'
      },
      hasMultiColumnFilter: true,
      templateName: 'status',
      styles: {
        header: {
          width: '190px'
        }
      }
    },
    {
      name: 'Date Added',
      dataType: "TEXT",
      jsonKey: {
        path: 'addedDate'
      },
      hasMultiColumnFilter: true,
      styles: {
        header: {
          width: '190px'
        }
      }
    },
    {
      name: '# of URL',
      dataType: "NUMBER",
      jsonKey: {
        path: 'resourceCount.URLs'
      },
      hasMultiColumnFilter: true,
      styles: {
        header: {
          width: '120px'
        }
      }
    },
    {
      name: '# of Dashboard',
      dataType: "NUMBER",
      jsonKey: {
        path: 'resourceCount.Dashboards'
      },
      hasMultiColumnFilter: true,
      styles: {
        header: {
          width: '120px'
        }
      }
    },
    {
      name: '# of Widget',
      dataType: "NUMBER",
      jsonKey: {
        path: 'resourceCount.Widgets'
      },
      hasMultiColumnFilter: true,
      styles: {
        header: {
          width: '120px'
        }
      }
    },
    {
      name: '# of API',
      dataType: "NUMBER",
      jsonKey: {
        path: 'resourceCount.APIs'
      },
      hasMultiColumnFilter: true,
      styles: {
        header: {
          width: '120px'
        }
      }
    },
    {
      name: '# of Others',
      dataType: "NUMBER",
      jsonKey: {
        path: 'resourceCount.Others'
      },
      hasMultiColumnFilter: true,
      styles: {
        header: {
          width: '120px'
        }
      }
    }
  ],

  'adminlist' : [
    {
      name: 'Administrator Name',
      dataType: "TEXT",
      jsonKey: {
        path: 'administratorName'
      },
      hasMultiColumnFilter: true,
      styles: {
        header: {
          width: '190px'
        }
      }
    },
    {
      name: 'Administrator Description',
      dataType: "TEXT",
      jsonKey: {
        path: 'administratorDesc'
      },
      hasMultiColumnFilter: true,
      styles: {
        header: {
          width: '190px'
        }
      }
    },
    {
      name: 'Status',
      dataType: "TEXT",
      jsonKey: {
        path: 'status'
      },
      hasMultiColumnFilter: true,
      styles: {
        header: {
          width: '190px'
        }
      }
    },
    {
      name: 'Action',
      dataType: "OPTIONS",
      jsonKey: {
        path: 'options'
      },
      hasMultiColumnFilter: false,
      // styles: {
      //   header: {
      //     width: '50px',
      //     marginBottom: '20px'
      //   }
      // },
    }
  ],


  'customerlist' : [
    {
      name: 'Action',
      dataType: "OPTIONS",
      jsonKey: {
        path: 'options'
      },
      hasMultiColumnFilter: false,
      // styles: {
      //   header: {
      //     width: '50px',
      //     marginBottom: '20px'
      //   }
      // },
    },
    {
      name: 'Customer Name',
      dataType: "TEXT",
      jsonKey: {
        path: 'customerName'
      },
      hasMultiColumnFilter: true,
      styles: {
        header: {
          width: '150px'
        }
      }
    },
    {
      name: 'Customer Admin',
      dataType: "TEXT",
      jsonKey: {
        path: 'customerAdminName'
      },
      hasMultiColumnFilter: true,
      styles: {
        header: {
          width: '150px'
        }
      }
    },
    {
      name: 'Email',
      dataType: "TEXT",
      jsonKey: {
        path: 'email'
      },
      hasMultiColumnFilter: true,
      styles: {
        header: {
          width: '150px'
        }
      }
    },
    {
      name: 'Date Added',
      dataType: "TEXT",
      jsonKey: {
        path: 'addedDate'
      },
      hasMultiColumnFilter: true,
      styles: {
        header: {
          width: '150px'
        }
      }
    },
    {
      name: '# of URL',
      dataType: "NUMBER",
      jsonKey: {
        path: 'resourceCount.URLs'
      },
      hasMultiColumnFilter: true,
      styles: {
        header: {
          width: '120px'
        }
      }
    },
    {
      name: '# of Dashboard',
      dataType: "NUMBER",
      jsonKey: {
        path: 'resourceCount.Dashboards'
      },
      hasMultiColumnFilter: true,
      styles: {
        header: {
          width: '120px'
        }
      }
    },
    {
      name: '# of Widget',
      dataType: "NUMBER",
      jsonKey: {
        path: 'resourceCount.Widgets'
      },
      hasMultiColumnFilter: true,
      styles: {
        header: {
          width: '120px'
        }
      }
    },
    {
      name: '# of API',
      dataType: "NUMBER",
      jsonKey: {
        path: 'resourceCount.APIs'
      },
      hasMultiColumnFilter: true,
      styles: {
        header: {
          width: '120px'
        }
      }
    }
  ],

  applicationlist : [
    {
      name: 'Application Name',
      dataType: "TEXT",
      jsonKey: {
        path: 'applicationName'
      },
      hasMultiColumnFilter: true,
    },
    {
      name: 'Description',
      dataType: "TEXT",
      jsonKey: {
        path: 'description'
      },
      hasMultiColumnFilter: true,
    },
    {
      name: 'Application URL',
      dataType: "TEXT",
      jsonKey: {
        path: 'url'
      },
      hasMultiColumnFilter: true,
    },
    {
      name: 'Application Scope EndPoint',
      dataType: "TEXT",
      jsonKey: {
        path: 'scopeEndPoint'
      },
      hasMultiColumnFilter: true,
    }
    // {
    //   name: 'Action',
    //   dataType: "OPTIONS",
    //   jsonKey: {
    //     path: 'options'
    //   },
    //   hasMultiColumnFilter: false,
    //   styles: {
    //     header: {
    //       marginBottom: '20px'
    //     }
    //   },
    // }
  ],

  'resourcelist' : [
    {
      name: 'Resource Name',
      dataType: "TEXT",
      jsonKey: {
        path: 'resourceName'
      },
      hasMultiColumnFilter: true,
    },
    {
      name: 'Description',
      dataType: "TEXT",
      jsonKey: {
        path: 'description'
      },
      hasMultiColumnFilter: true,
    },
    {
      name: 'Resource Type',
      dataType: "TEXT",
      jsonKey: {
        path: 'resourceTypeName'
      },
      hasMultiColumnFilter: true,
    },
    {
      name: 'Application',
      dataType: "TEXT",
      jsonKey: {
        path: "applicationName"
      },
      hasMultiColumnFilter: true
    },
    {
      name: 'Status',
      dataType: "TEXT",
      jsonKey: {
        path: 'status'
      },
      hasMultiColumnFilter: true,
      templateName: 'status',
    }
    // {
    //   name: 'Action',
    //   dataType: "OPTIONS",
    //   jsonKey: {
    //     path: 'options'
    //   },
    //   hasMultiColumnFilter: false,
    //   styles: {
    //     header: {
    //       marginBottom: '20px'
    //     }
    //   },
    // }
    
  ],
  'actionlist' : [
    {
      name: 'Action Name',
      dataType: "TEXT",
      jsonKey: {
        path: 'action'
      },
      hasMultiColumnFilter: true,
    },
    {
      name: 'Description',
      dataType: "TEXT",
      jsonKey: {
        path: 'desc' 
      },
      hasMultiColumnFilter: true,
    },
    {
      name: 'Status',
      dataType: "TEXT",
      jsonKey: {
        path: 'status'
      },
      hasMultiColumnFilter: true,
      templateName: 'status',
    }
    // {
    //   name: 'Action',
    //   dataType: "OPTIONS",
    //   jsonKey: {
    //     path: 'options'
    //   },
    //   hasMultiColumnFilter: false,
    //   styles: {
    //     header: {
    //       marginBottom: '20px'
    //     }
    //   },
    // }

  ]
}


const TABLE_CONFIG  = {
  tableInfo: {
    type: 'dataTable'
  },
  searchFilter: false,
  hasInfiniteScroll: true,
  paginatorOptions: {
    visible: true,
    limit: 25,
    dropdownOptions: [25, 50, 100].map(n => ({ display: n, value: n }))
  },
  tableHeaderFixerInfo: {
    headerFix: true,
    colFix: false,
    height: '500px',
  },
  indexCons: {
    show: false
  }
}

const ES_OPTIONS = {
  READ: 'Read',
  READ_SELF: 'Read_Self',
  EDIT: 'Edit',
};

const resourcesList = {
  user : 'USER',
  profile: 'ROLE',
  resource: 'RESOURCE',
  application: 'APPLICATION',
  actions: 'ACTIONS',
  customers: 'CUSTOMER' 
}

const PREVIOUS_ORDER_OPTIONS = {
  VIEW: 'View Order',
  REPLICATE: 'Duplicate Order',
  COMPARE: 'Compare Order'
}

const CLICKABLE_DATA_TYPE = ['CHECKBOX', 'OPTIONS', 'POPUP', 'WATCHLIST'];


const USER_LIST = [
  { id: '1', name: 'John D' },
  { id: '2', name: 'Dana J' },
  { id: '3', name: 'Lily C' },
  { id: '4', name: 'Rose B' },
  { id: '5', name: 'Jane K' },
];


export const UM_TABLE_CNST = {
  TABLE_COLUMNS,
  ES_OPTIONS,
  PREVIOUS_ORDER_OPTIONS,
  USER_LIST,
  CLICKABLE_DATA_TYPE,
  TABLE_CONFIG,
  RESOURCES_LIST : resourcesList
}
