import { UserComponent } from './user.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { UsersListComponent } from './components/users-list/users-list.component';
import { AddUserComponent } from './components/add-user/add-user.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ViewUsersComponent } from './components/view-users/view-users.component';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { EditUserComponent } from './components/edit-user/edit-user.component';
import { TableModule } from 'src/app/lib/table/table.module';
import { AuthorizationModule } from '../../modules/authorization/authorization.module';
import { OtpValidationComponent } from 'src/app/modules/authentication/otp-validation/otp-validation.component';
import { OtpValidationChangePasswordComponent } from 'src/app/modules/authentication/otp-validation-change-password/otp-validation-change-password.component';
import { AuthGuard } from 'src/app/guards/auth.guard';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';

const routes = [
   { path: '', pathMatch: "full", component: UserComponent ,canActivate:[AuthGuard]},
  //  {path: 'change-password', component: ChangePasswordComponent}

]

@NgModule({
  declarations: [
    UserComponent,
    UsersListComponent,
    AddUserComponent,
    ViewUsersComponent,
    EditUserComponent,
    OtpValidationComponent,
    OtpValidationChangePasswordComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    SharedModule,
    FormsModule,
    NgxIntlTelInputModule,
    TableModule,
    ReactiveFormsModule,
    NgSelectModule,
    AuthorizationModule,
    InfiniteScrollModule,
    RouterModule.forChild(routes),
  ],
  entryComponents: [
    AddUserComponent,
    ViewUsersComponent, 
    EditUserComponent,
    OtpValidationComponent,
    OtpValidationChangePasswordComponent
  ]
})
export class UserModule { }
