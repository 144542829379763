import { Component, OnDestroy, OnInit  } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AddUserComponent } from './components/add-user/add-user.component';
import { USER_MGMT_CNST } from 'src/app/constants/proj.cnst';

import { CommonService } from '../../services/common.service';
import { DataService } from '../../services/data.service';
import { HttpService } from '../../services/http.service';
import { UtilsService } from '../../services/utils.service'
import { UM_TABLE_CNST } from 'src/app/constants/table.cnst';
import { AppStateService } from 'src/app/services';
import { AuthenticationService } from 'src/app/modules/authentication/services/authentication.service';
import { Config, Path } from 'src/app/enums';
import { InitService } from 'src/app/parsers/init.service';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { isNullOrUndefined } from 'util';
import { EnvService } from 'src/app/services/env.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit, OnDestroy {
  tableConfig: any = {}
  tableData: any = [];

  loading: boolean = false;
  updateInProcess: boolean = false;
  addConfig: any;
  isEditPermission: boolean;

  loadMoreRecordsOnScroll: boolean = true;
  fetchedRecordsCount: number = 0;
  currentPage: number = 0;
  currentOffset: number = 0;

  //*Infinite scrolling related variables
  scrollDistance: number;
  throttle: number;
  isScrollOnWindow: boolean;

  subscriptions : Subscription[] = [];
  wasPageScrolled: boolean;
  loggedInUserFlag: boolean = false;
  currentResponseLength: number;
  customerListData: any[];
  isCustomer: any;
  customerSelected: any;
  userListData: any[];
  userSelected: any[];

  constructor(
    private modal: NgbModal,
    private commonSrv: CommonService,
    private httpSrv: HttpService,
    private dataSrv: DataService,
    private utilSrv: UtilsService,
    private appStateSrv: AppStateService,
    private authStateSrv: AuthenticationService,
    private initSrv: InitService,
    private envService : EnvService,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    let permissions = this.appStateSrv.getUserPermissions();
    
    if(permissions && permissions['User Management'] && permissions['User Management']['resources'] && permissions['User Management']['resources']['ROLE'] && permissions['User Management']['resources']['ROLE']['actions']){
      this.isEditPermission = permissions['User Management']['resources']['ROLE']['actions'].indexOf('EDIT') !==-1 ? true: false;
    }

    let metaInfo = this.appStateSrv.getUserMetaInfo();
    this.isCustomer = metaInfo && (metaInfo['customerId']|| metaInfo['customerName']) ? true : false;

    this.setTableConfig();
    this.setTableData();

    const operationRef = this.appStateSrv.operationRef.subscribe(
      (value: any) => {
        let { operation, postData } = value;

        if (operation === USER_MGMT_CNST.OPERATION_TYPES.USER.DELETE_USER) {
          this.deleteUser(postData)
        } else if (operation === USER_MGMT_CNST.OPERATION_TYPES.USER.EDIT_USER) {
          this.updateUser(postData);
        }
      }
    );

    this.wasPageScrolled = this.appStateSrv.getWasPageScrolled();

    const pageScrolledRef = this.appStateSrv.wasPageScrolledRef.subscribe((value: boolean) => {
      this.wasPageScrolled = value;
      if (this.loadMoreRecordsOnScroll){
        if(this.customerSelected){
          this.customerBasedUserList(this.customerSelected['customerId']);
        }else{
          this.setTableData();
        }
      }
    });

    this.subscriptions.push(operationRef);
    this.subscriptions.push(pageScrolledRef);
    this.initializeInfiniteScrollVariables();
    if(!this.isCustomer){
      this.setCustomerListData();
      }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription : Subscription) => subscription.unsubscribe());

  this.modal.dismissAll();
    
  }

  initializeInfiniteScrollVariables() {
    let { SCROLL_DISTANCE, THROTTLE, SCROLL_ON_WINDOW } = USER_MGMT_CNST.INFINITE_SCROLL_OPTIONS;

    this.scrollDistance = SCROLL_DISTANCE;
    this.throttle = THROTTLE;
    this.isScrollOnWindow = SCROLL_ON_WINDOW;
  }

  addUserModal() {
    this.initializeAddUserConfig()
    .then ( data => {
      // let userPermission = this.appStateSrv.getUserPermissions();
      if(!this.isCustomer){
      this.httpSrv
      .makeGetApiCall('GET_CUSTOMERS_LIST', this.envService.baseUrl)
      .subscribe((response: any) => {
        let formattedResponse = this.formatCustomerList(response);
        let customerList = this.dataSrv.addOptionsToTableData(formattedResponse, UM_TABLE_CNST.RESOURCES_LIST.customers);
        const modalRef = this.modal.open(AddUserComponent, USER_MGMT_CNST.MODAL_OPTIONS);
        modalRef.componentInstance.addUserConfig = data;
        modalRef.componentInstance.customerList = customerList;
        modalRef.result.then((data) => {    
          // TODO: update table.
          if (data && data.updateTable) {
            this.resetVariables();
            this.setTableData();
          }
        });
      }, error => {
        this.utilSrv.showToastMessage("Failed to fetch customers list", "error");
      })
    }else{
        const modalRef = this.modal.open(AddUserComponent, USER_MGMT_CNST.MODAL_OPTIONS);
        modalRef.componentInstance.addUserConfig = data;
        modalRef.componentInstance.customerList = [];
        modalRef.result.then((data) => {    
          // TODO: update table.
          if (data && data.updateTable) {
            this.setTableData();
          }
        });
    }

    })
    .catch( err =>  {
      console.log(err, 'waringin here');
    })
    
  }

  initializeAddUserConfig() {
    return this.initSrv.loadConfig({
      path: Path.ADD_USER_CONFIG_PATH,
      configType: Config.ADD_USER_CONFIG
    });
  }

  setTableConfig() {
    this.tableConfig = this.commonSrv.setTableConfig(!this.isCustomer ? USER_MGMT_CNST.LIST_NAME_MAPPING.USER_LIST : USER_MGMT_CNST.LIST_NAME_MAPPING.USER_CUSTOMER_LIST);
    this.tableConfig.hasInfiniteScroll = true;
  }

  setTableData() {

    let options = {
      'queryParams': {
        size : USER_MGMT_CNST.INFINITE_SCROLL_OPTIONS.INFINITE_SCROLL_LIMIT,
        offset : this.currentOffset
      }
    }

    this.loading = true;
    this.httpSrv
      .makeGetApiCall('GET_USER_LIST', this.envService.baseUrl, options)
      .subscribe((userList: any[]) => {
        userList = Array.isArray(userList) ? userList : userList['response'];
        this.commonTableDataFormater(userList);
        }, error => {
        this.loading = false;
        this.tableData = [];
        this.utilSrv.showToastMessage("Failed to fetch users list", "error");
      })
  }

  deleteUser(postdata: any) {
    this.httpSrv.makeDeleteApiCall('KEYCLOAK_DELETE_USER', this.envService.baseUrl, { uriParams: { userId: postdata.userId } })
      .subscribe(res => {
        this.resetVariables();
        this.setTableData();
        this.utilSrv.showToastMessage('User deleted Successfully', 'success');
      }, err => {
        this.utilSrv.showToastMessage('Error occured while deleting the user', 'error');
      })
  }

  updateUser(postdata: any) {
    
    if (this.updateInProcess) return;

    this.updateInProcess = true;
    if(postdata['metaInfo']){
    postdata['metaInfo']['status'] = postdata['metaInfo']['status'] === USER_MGMT_CNST.STATUS_TYPE_LIST.ACTIVE ? true : false;
    }
    const { userId = '', operation, ...rest } = postdata;
    this.httpSrv.makePutApiCall('UPDATE_USER', rest, this.envService.baseUrl, {
      uriParams: {
        userId
      }
    }).subscribe(res => {
      this.updateInProcess = false;
      this.resetVariables();
      this.setTableData();
      this.utilSrv.showToastMessage('Successfully updated user details', 'success');
    }, err => {
      this.updateInProcess = false;
      // this.setTableData();
      this.utilSrv.showToastMessage("Error occured while updating the user", 'error');
    })
  }

  formatCustomerList(response : any) {
    let formattedCustomerList = [];

    for (let custId in response) {
      let customerInfo = response[custId];
      formattedCustomerList.push(customerInfo);
    }

    return formattedCustomerList;
  }

  handleTableScroll() {
    if (this.loadMoreRecordsOnScroll) {
      this.setTableData();
    }
  }

  setCustomerListData() {
    this.httpSrv
      .makeGetApiCall('GET_CUSTOMERS_LIST', this.envService.baseUrl)
      .subscribe((response: any) => {
        let customerListData = this.formatTableData(response);
        this.customerListData = this.dataSrv.addOptionsToTableData(customerListData, UM_TABLE_CNST.RESOURCES_LIST.customers);
      }, error => {
        this.utilSrv.showToastMessage("Failed to fetch customers list", "error");
      })
  }

  formatTableData(data){
    let customerKeys = Object.keys(data);
    let customerList = customerKeys.map(cust =>  {
      let customer = data[cust];
      customer['addedDate'] = this.datePipe.transform(new Date(customer['addedDate']), "dd MMM yyyy");
      return customer;
    }) 
    return customerList;
 }

 onCustomerSelect(e){
  if(!isNullOrUndefined(e)){
    this.resetVariables();
    this.userSelected = [];
    this.userListData = [];
    this.customerBasedUserList(e.customerId)
  }
}

clearCustomerOptions(){
  this.resetVariables();
  this.setTableData();
}

resetVariables(){
  this.loadMoreRecordsOnScroll = true;
  this.currentPage = 0;
  this.currentOffset = 0;
  this.loggedInUserFlag = false;
  this.tableData = [];
}
customerBasedUserList(customerId) {
  let options = {
    'uriParams':{
      customerId : customerId
    },
    'queryParams': {
      size : USER_MGMT_CNST.INFINITE_SCROLL_OPTIONS.INFINITE_SCROLL_LIMIT,
      offset : this.currentOffset
    }
  }
  this.loading = true;
  this.httpSrv
    .makeGetApiCall('GET_USER_LIST', this.envService.baseUrl, options)
    .subscribe((userList: any[]) => {
      userList = Array.isArray(userList) ? userList : userList['response'];
      this.commonTableDataFormater(userList);
    }, error => {
      this.loading = false;
      this.tableData = [];
      this.utilSrv.showToastMessage("Failed to fetch users list", "error");
    })
}

commonTableDataFormater(userList){
      
      if (userList.length === 0) {
        this.loadMoreRecordsOnScroll = false;
      }

      this.currentResponseLength = userList.length;

      let updatedList = userList.map(user =>  {
        if(user['lastLoginTs']){
        user['lastLoginTs'] = this.datePipe.transform(new Date(user['lastLoginTs']), "dd MMM yyyy");
        }
        return user;
      });

      let tableData = this.dataSrv.addOptionsToTableData(updatedList, UM_TABLE_CNST.RESOURCES_LIST.user);
      const userMap = {};
      this.loading = false;

      const loggedInUserId = this.authStateSrv.getUserId();
      if(tableData.find(r => r.userId === loggedInUserId)){
        this.loggedInUserFlag = true;
     }
      // Filter the current user record
      tableData = tableData.filter(r => r.userId !== loggedInUserId);
      

      tableData.forEach((record,i) => {

        let user = userMap[record.userId];
        const { applicationName, applicationId, roleName, roleId, ...rest } = record;
     
        const application = {
          applicationName,
          applicationId,
          roleName,
          roleId
        }

        if (!user) {
          user = { ...rest };
          user.applications = [application];
        } else {
          user.applications = [...user['applications'], application];
        }
        // user.status = record.status === USER_MGMT_CNST.STATUS_TYPE_LIST.ACTIVE ? true : false;
        userMap[record.userId] = user;
      })

      this.tableData = [ ...Object.values(userMap)] ;
      
      let modifiedTableData = this.tableData.map(e=>{
        let indexWithCustomerAdminRole = 0; // Initialize with -1 indicating not found

for (let i = 0; i < e.applications.length; i++) {
    if (e.applications[i].roleName === "Customer Administrator") {
        indexWithCustomerAdminRole = i;
        break; // Stop the loop once a match is found
    }
}

        e['applicationDetails'] = {
          applicationName: e.applications[indexWithCustomerAdminRole]['applicationName'],roleName:e.applications[indexWithCustomerAdminRole]['roleName']
        }
        return e;
      });

      this.tableData = modifiedTableData;

      this.currentPage++;
      if(this.currentResponseLength === USER_MGMT_CNST.INFINITE_SCROLL_OPTIONS.INFINITE_SCROLL_LIMIT){
        this.currentOffset += USER_MGMT_CNST.INFINITE_SCROLL_OPTIONS.INFINITE_SCROLL_LIMIT;
      }
      else{
        this.loadMoreRecordsOnScroll = false;
      }
}

fetchUsers(text){
    let optionsList = {
      'queryParams': {
        userName:text,
        ...((this.customerSelected) && { customerId:  this.customerSelected['customerId']}),
      }
    }

    this.httpSrv
    .makeGetApiCall('SEARCH_USERS', this.envService.baseUrl, optionsList)
    .subscribe((userList: any[]) => {
      userList = Array.isArray(userList) ? userList : userList['response'];
      this.userListData = userList;
    }, error => {
      this.loading = false;
      this.tableData = [];
      this.utilSrv.showToastMessage("Failed to fetch users list", "error");
    })
}

makeChoice(e){
  this.userListData = [];
  if(e && e['term'] && e.term.length > 2){
    this.fetchUsers(e['term']);
  }
}

onUserSelect(e){
  if(!isNullOrUndefined(e) && e.length > 0){
    this.resetVariables();
    this.commonTableDataFormater(e);
  }else{
    this.clearCustomerOptions();
  }
}

onSelectAll() {
  this.userSelected = this.userListData;
  this.onUserSelect(this.userSelected);
}

clearUserOptions(){
  this.resetVariables();
  this.userListData = [];
  this.userSelected = [];
  if(this.customerSelected){
    this.customerBasedUserList(this.customerSelected['customerId']);
  }
}

}
