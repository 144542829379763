import { Component, OnInit, Input } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ViewApplicationComponent } from '../view-application/view-application.component';
import { AddApplicationComponent } from '../add-application/add-application.component';
import { DeleteComponent } from 'src/app/modules/shared/components/delete/delete.component';

import { UM_TABLE_CNST } from 'src/app/constants/table.cnst'
import { USER_MGMT_CNST } from 'src/app/constants';
import { Router } from '@angular/router';
import { AppStateService } from 'src/app/services';

@Component({
  selector: 'app-applications-list',
  templateUrl: './applications-list.component.html',
  styleUrls: ['./applications-list.component.scss']
})
export class ApplicationsListComponent implements OnInit {

  @Input() tableData : any;
  @Input() tableConfig : any;

  dataTypes = UM_TABLE_CNST.CLICKABLE_DATA_TYPE;
  modalData: any = [];

  constructor(private modal: NgbModal,
              private router: Router,
              private appStateSrv: AppStateService) { }

  ngOnInit() { }

  assignResource(data,type){
    console.log("Data ", data);
    this.appStateSrv.setApplicationData({info:data,type: type});
    this.router.navigate(['','applications','assignResource']);
  }

  handleOptionClick(e) {
    let rowData = e.data;

    if (e.val == USER_MGMT_CNST.OPTIONS.VIEW) {
      const template = this.modal.open(ViewApplicationComponent, USER_MGMT_CNST.MODAL_OPTIONS);
      template.componentInstance.modalData = this.modalData;
      this.modalData.name = rowData[0] ? rowData[0]['values'][0] : '-'
      this.modalData.desc = rowData[1] ? rowData[1]['values'][0] : '-'
      this.modalData.url = rowData[2] ? rowData[2]['values'][0] : '-'
      this.modalData.status = rowData[3] ? rowData[3]['values'][0] : '-'
    }

    else if (e.val == USER_MGMT_CNST.OPTIONS.DELETE) {
      const modalRef = this.modal.open(DeleteComponent, USER_MGMT_CNST.DELETE_POPUP_OPTIONS);
    }

    else {
      const template = this.modal.open(AddApplicationComponent, USER_MGMT_CNST.MODAL_OPTIONS);
      template.componentInstance.modalData = this.modalData;
      this.modalData.name = rowData[0] ? rowData[0]['values'][0] : '-'
      this.modalData.desc = rowData[1] ? rowData[1]['values'][0] : '-'
      this.modalData.url = rowData[2] ? rowData[2]['values'][0] : '-'
      this.modalData.status = rowData[3] ? rowData[3]['values'][0] : '-'
      this.modalData.editAction = true;
    }
  }
}
