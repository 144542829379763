import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { isNullOrUndefined } from 'util';

import { USER_MGMT_CNST } from '../../constants/proj.cnst';
import { UtilsService } from '../../services/utils.service';
import { EnvService } from 'src/app/services/env.service';
import { AppStateService, HttpService } from 'src/app/services';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
  customerId: any;
  listOfApplications: any;

  constructor(
    private router: Router,
    private utilSrv: UtilsService,
    private appStateSrv: AppStateService,
    private httpSrv: HttpService,
    private envSrv: EnvService) { }

  isOneOfPredefinedResources: boolean;


  ngOnInit(): void {
    let redirectUrl: any;   
    let userPermissions = this.appStateSrv.getUserPermissions();    

    let currentResourceName = this.appStateSrv.getCurrentResourceName();
    // console.log("Current Resource Name in landing page ", currentResourceName);

    this.isOneOfPredefinedResources = USER_MGMT_CNST.PREDEFINED_RESOURCES.indexOf(currentResourceName) !== -1;

    // console.log("is one of predefined resources in landing page ", this.isOneOfPredefinedResources);

    this.appStateSrv.setIsPredefinedResource(this.isOneOfPredefinedResources);

    if (this.utilSrv.isObjectNotNullAndEmpty(userPermissions)) {      

      // setTimeout(() => {
        //TODO : Considering only first application assigned to user
        //TODO : Generalize when user has been assigned multiple applications
      let appName = Object.keys(userPermissions)[0];
      this.deleteCookies();
      // let appName = USER_MGMT_CNST.CURRENT_APPLICATION;
      console.log("appName landingpage 888", appName);
      if (appName != 'User Management') {
        redirectUrl = appName;
        if (redirectUrl == 'TNWRIMS') {
          redirectUrl = 'tnwris';
        }
        else if (redirectUrl == 'TNSWIP') {
          redirectUrl = 'tnswip';
        } 
        this.envSrv.appRedirectUrl  = redirectUrl;
        localStorage.setItem('applicationName', redirectUrl);
        if (!isNullOrUndefined(redirectUrl)) {
          setTimeout(() => {  
            document.location.href = this.envSrv.uiBaseUrl + this.envSrv.appRedirectUrl;
            return;
          }, 3000);
        }
      }
      else{
        
        if (!isNullOrUndefined(appName)) {
          let roleName :any= null;
           roleName = userPermissions[appName];
          if (!isNullOrUndefined(roleName)) {

            // let isUserSuperAdminOrCustAdminOrCSE = (roleName === USER_MGMT_CNST.CUSTOMER_ADMIN_ROLE
            //   || roleName === USER_MGMT_CNST.SUPER_ADMIN
            //   || roleName === USER_MGMT_CNST.CUSTOMER_SERVICE_EXECUTIVE) ? true : false;

            // console.log("Is user super admin or cust admin or cse ", isUserSuperAdminOrCustAdminOrCSE);
    
            // let redirectUrl = isUserSuperAdminOrCustAdminOrCSE
            //   ? this.envSrv.userMgmtRedirectUrl
            //   : this.envSrv.appRedirectUrl;
            let customerDetails = JSON.parse(localStorage.getItem('userInfo'));                        
            this.customerId = customerDetails['userId'];
            let Options: any = {}
            Options = {
              'Csrf-Token': localStorage.getItem('csrfToken'),
              'Authorization': "bearer " + localStorage.getItem('token'),
              'customerId': this.customerId
            }
            this.httpSrv
              .makeGetApiCall(
                'FETCH_ASSIGNED_APPLICATION_FOR_CUSTOMER',
                this.envSrv.baseUrl, Options)
              .subscribe((res: any) => {
                this.listOfApplications = res['response'];  
                console.log("this.listOfApplications landingpage 888",this.listOfApplications);
             
                if (this.listOfApplications) {
                  redirectUrl = this.listOfApplications[0].applicationName.toString();
                }
                if (redirectUrl == 'TNWRIMS') {
                  redirectUrl = 'tnwris';
                }
                else if (redirectUrl == 'TNSWIP') {
                  redirectUrl = 'tnswip';
                }                
                localStorage.setItem('applicationName',redirectUrl);
                if (!isNullOrUndefined(roleName)) {
                  localStorage.setItem('roleName', roleName);
                }
                
                setTimeout(() => {  
                  if (!isNullOrUndefined(redirectUrl)) {
                    document.location.href = this.envSrv.uiBaseUrl + redirectUrl;
                  }
                },5000);
              });
          } else {
              let redirectUrl = this.envSrv.appRedirectUrl;
              this.router.navigate([redirectUrl, { externalUrl: redirectUrl }])
          }
        }
      // }, 3000)
      }
    } else {
      console.log("user permissions is empty, unable to redirect");
      this.utilSrv.showToastMessage("User does not have permissions to view the application", "error");
    }
  }

  deleteCookies(){
    // console.log("is one of predefined resources in landing page ", this.isOneOfPredefinedResources);
  document.cookie = 'KEYCLOAK_SESSION_LEGACY' + '=; Max-Age=0; path=/auth/realms/tnswipRealm/;';  // Set cookie expiry to delete
  document.cookie = 'KEYCLOAK_IDENTITY_LEGACY' + '=; Max-Age=0; path=/auth/realms/tnswipRealm/;';  // Set cookie expiry to delete
  document.cookie = 'KEYCLOAK_IDENTITY' + '=; Max-Age=0; path=/auth/realms/tnswipRealm/;';  // Set cookie expiry to delete
  document.cookie = 'KEYCLOAK_SESSION' + '=; Max-Age=0; path=/auth/realms/tnswipRealm/;';  // Set cookie expiry to delete
  document.cookie = 'AUTH_SESSION_ID_LEGACY' + '=; Max-Age=0; path=/auth/realms/tnswipRealm/;';  // Set cookie expiry to delete
  document.cookie = 'AUTH_SESSION_ID' + '=; Max-Age=0; path=/auth/realms/tnswipRealm/;';  // Set cookie expiry to delete
  document.cookie = '__ga_VFXZEEN4WD' + '=; Max-Age=0; path=/;';  // Set cookie expiry to delete
  
  }
}