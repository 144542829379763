 import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {UM_TABLE_CNST} from 'src/app/constants/table.cnst'
import { ViewActionsComponent } from '../view-actions/view-actions.component';
import { AddActionComponent } from '../add-action/add-action.component';
import { DeleteComponent } from 'src/app/modules/shared/components/delete/delete.component';
import { USER_MGMT_CNST } from 'src/app/constants/proj.cnst';
import { CommonService } from 'src/app/services/common.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-action-list',
  templateUrl: './action-list.component.html',
  styleUrls: ['./action-list.component.scss']
})
export class ActionListComponent implements OnInit {

  tableConfig : any = {}
  tableData : any = [];
  dataTypes = UM_TABLE_CNST.CLICKABLE_DATA_TYPE;
  values:any;
  modalData :any =[];

  constructor(
    private modal: NgbModal,
    private commonService: CommonService,
    private dataService : DataService
  ) { }

  ngOnInit() {
    this.setTableConfig();
    this.setTableData();
  }


  setTableConfig(){
    this.tableConfig = this.commonService.setTableConfig('actionlist');
  }


  setTableData(){
    this.tableData = this.dataService.getListData('actionlist');
  }

  handleOptionClick(e: any){ 
    if (e.val == USER_MGMT_CNST.OPTIONS.VIEW) {
      const template = this.modal.open(ViewActionsComponent, USER_MGMT_CNST.MODAL_OPTIONS);
      template.componentInstance.modalData = this.modalData;
      this.modalData.name = e.data[0]['values'][0]
      this.modalData.desc = e.data[1]['values'][0]
      this.modalData.status = e.data[2]['values'][0]
    } if (e.val == USER_MGMT_CNST.OPTIONS.DELETE) {
      const template = this.modal.open(DeleteComponent, USER_MGMT_CNST.DELETE_POPUP_OPTIONS);
    }
    if (e.val == USER_MGMT_CNST.OPTIONS.EDIT) {
      const template = this.modal.open(AddActionComponent, USER_MGMT_CNST.MODAL_OPTIONS);
      template.componentInstance.modalData = this.modalData;
      this.modalData.name = e.data[0]['values'][0]
      this.modalData.desc = e.data[1]['values'][0]
      this.modalData.status = e.data[2]['values'][0]
      this.modalData.editAction = true;
    }
  }

 addActionModal() {
    this.modal.open(AddActionComponent, USER_MGMT_CNST.MODAL_OPTIONS)
  }


}
