import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ptypeCtye, USER_MGMT_CNST, UsersMapping } from 'src/app/constants/proj.cnst';
import { HttpService, AppStateService, UtilsService } from 'src/app/services';
import { isNullOrUndefined } from 'util';
import { EnvService } from 'src/app/services/env.service';
import { countryCodes } from 'src/app/constants/country-codes';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { Country } from 'ngx-intl-tel-input/lib/model/country.model';
import { HttpClient } from '@angular/common/http';
import { state } from '@angular/animations';

@Component({
  selector: 'app-add-customer-admin',
  templateUrl: './add-customer-admin.component.html',
  styleUrls: ['./add-customer-admin.component.scss']
})
export class AddCustomerAdminComponent implements OnInit {
  @Input() customerId : string;
  @Input() customerName : string;
  
  emailPattern: any = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
  isEmailValid : boolean = true;

  countryCodeList : any[] = [...countryCodes];
  stateName:any=[]
  designationList=[
    {"key":"IWS","value":"IWS"},
   
  ]
  circleNames:any=[];
  divisionNames:any=[];
  subDivisionNames:any=[];
  sectionNames:any=[];


  customerAdminForm: FormGroup = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', []),
    username: new FormControl('', [Validators.required,Validators.pattern(/^\S*$/)]),
    email: new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]),
    designation: new FormControl({ value: 'IWS', disabled: true }, [Validators.required]), // Set to disabled here
    state:new FormControl('', [Validators.required]),
    // circle:new FormControl('', [Validators.required]),
    // division:new FormControl('', [Validators.required]),
    // subDivision:new FormControl('', [Validators.required]),
    // section:new FormControl('', [Validators.required]),
  
  });
 
  
  isPrimaryMobileNoValid : boolean = true;
  currentCountryCode : string = "in";
  fieldsToAdd: string[];
 
  
  constructor(
    public activeModal: NgbActiveModal,
    private httpSrv: HttpService,
    private utilsSrv: UtilsService,
    private envService : EnvService,
    private http:HttpClient
    ) { }

  ngOnInit() {
    
    this.fieldsToAdd=UsersMapping['IWS'];
    this.getStateData();

  }
  getStateData(){
  let data=  {
    "value":"e98cd5b7-6556-4c0f-a778-3429e1c14a6b",
    "label":"TAMILNADU"
    }
    this.stateName.push(data)
    this.customerAdminForm.controls['state'].setValue(data);
  //   let postData={
  //   pType:'STATE',
  //   cType:'DISTRICT',
   
  // }
  // this.getData(postData).subscribe((res: any) => {
  
  //   for(let key in res){
  //     let obj={}
  //     obj["value"]=key;
  //     obj["label"]=res[key];
  //     this.regionNames.push(obj);

  //   }
  // }, err => console.log(err));

  }
  getData(postdata) {
    return this.http.post(
      // environment.url.PLAY_URL + 'get-loc-types',
     'https://tnswip.vassarlabs.com/encroachmentApi/emApi/'+'get-locations',


      postdata
    );
  }






      
   
  SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;

  primaryMobileForm = new FormGroup({
		primaryMobileNo: new FormControl(undefined, [Validators.required]),
	});


  // onlyAlphabet(event: any) {
  //   const pattern = /[a-zA-Z0-9]/;
  
  //   let inputChar = String.fromCharCode(event.charCode);
  //   if (event.keyCode != 8 && !pattern.test(inputChar)) {
  //     event.preventDefault();
  //   }
  // }

  onlyAlphabetInteger(event: any,flag:any) {
    const pattern = flag == 'Integer' ?  /[a-zA-Z0-9]/ : /[a-zA-Z ]/ ;

    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  

  allowOnlyNumbers(event: any) {
    const pattern = /[0-9\+\-\ ]/;

    let inputChar = String.fromCharCode(event.charCode);

    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  validateEmail(event: any) {
    let inputStr = event.target.value;
    this.isEmailValid = this.utilsSrv.isEmailValid(this.emailPattern, inputStr);
  }

  addCustomerAdmin(){

    if (this.customerAdminForm.invalid) {
      return;
    }
    
    //* Create post data for add user
    const postdata = this.postDataCreationForAddUser();

    console.log("Post data ", postdata);

    //* Api call for add user
    this.httpSrv.makePostApiCall('ADD_USER', postdata, this.envService.baseUrl)
      .subscribe(res => {
        console.log("Res ", res);
        if(res&&res.response){
          this.utilsSrv.showToastMessage(
           "Added",
            "success"
          );
          
            
        }
        if (res.result) {  
          this.updateUser(res.response, postdata);
        } else {
          this.utilsSrv.showToastMessage(
            USER_MGMT_CNST.ERROR_MESSAGES.CUST_ADMIN_GENERAL,
            "error"
          );
        }
      }, err => {
        
        // console.log("Error ", err);
        let errorMessage = USER_MGMT_CNST.ERROR_MESSAGES.CUST_ADMIN_GENERAL;

        if (!isNullOrUndefined(err)) {
          
          if (!isNullOrUndefined(err.error.message)) {

            let { KEYCLOAK_ERROR_MSGS } = USER_MGMT_CNST;

            if (err.error.message.startsWith(`${KEYCLOAK_ERROR_MSGS.incomingMobileNoError} '${postdata['metaInfo']['mobileNo']}'`)) {
              errorMessage = KEYCLOAK_ERROR_MSGS.displayedmobileNoErrorMsg;
            } else {
              errorMessage = err.error.message;
            }

          } else {
            
            let errorText = err.split(USER_MGMT_CNST.CONFLICT_STRING)[1];
  
            if (!isNullOrUndefined(errorText) && errorText !== '') {
              errorText = JSON.parse(errorText);
              errorMessage = errorText[0]["errorMessage"];
            }
          }
        }

        this.utilsSrv.showToastMessage(errorMessage, "error");
      })
  }

  checkLength(event: any) {
    let value = event.target.value;

    if (value.length > 0) {
      this.isPrimaryMobileNoValid = this.primaryMobileForm.invalid ? false : true;
    } else {
      this.isPrimaryMobileNoValid = true;
    }
  }

  resetField(event : Country) {
    this.primaryMobileForm.controls.primaryMobileNo.setValue(null);
    this.isPrimaryMobileNoValid = true;
    this.currentCountryCode = event.iso2;
  }

  //* post data creation for add user
  postDataCreationForAddUser(){
    const userInfoStr = localStorage.getItem('userInfo');
    const token = localStorage.getItem('token');
    const userInfo = userInfoStr ? JSON.parse(userInfoStr) : null;
    const postdata = {
      metaInfo: {
        "username": this.customerAdminForm.value.username,
        "firstName": this.customerAdminForm.value.firstName,
        "lastName": this.customerAdminForm.value.lastName,
        "email": this.customerAdminForm.value.email,
        "mobileNo": this.primaryMobileForm.controls['primaryMobileNo'].value.e164Number,
        "customerId": this.customerId,
        "customerName": this.customerName ,
        // "associatedUserId":userInfo.userId,
        "createdBy":userInfo.userId,
        
        // "associatedUsername" : userInfo.username,
        "userDetailsJson":{
          data:{
            "designation":"IWS",
            "location_type":"STATE",
            "primary_uuid":this.customerAdminForm.value.state.value,
            "primary_name":this.customerAdminForm.value.state.label,
            "secondary_uuid":null,
            "secondary_name":null,
            "locationHierarchy":"State"
          },
        
          hierarchyDetails:{
            "state_uuid":this.customerAdminForm.value.state.value,
            "state_name":this.customerAdminForm.value.state.label,
          }
        }
      }
    }

    return postdata;
  }


  updateUser(userId: any, addUserPostData : any) {
  
    let postData = {
      "metaInfo": null,
      "addRoles": USER_MGMT_CNST.EDIT_USER_ADD_ROLE,
      "updateRoles": {},
      "deleteRoles": [],
      "userId": userId
  }
    this.httpSrv.makePutApiCall('UPDATE_USER', postData, this.envService.baseUrl, {
      uriParams: {
        userId
      }
    }).subscribe(res => {
      setTimeout(() => {
        
        this.utilsSrv.showToastMessage(
          "Successfully added customer admin ",
          "success"
        );   
      }, 3000);
      this.activeModal.close(this.customerAdminForm.value); 
    }, err => {
      this.utilsSrv.showToastMessage(
        "Error occured while creating customer admin",
        "error"
      );    
    })
  }

  onPaste(event: ClipboardEvent,key : any) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text');
    let trimmedText = pastedText.replace(/[^a-zA-Z]/g, '');
    setTimeout(() => {
      trimmedText = trimmedText.substr(0,25);
      this.customerAdminForm.patchValue({
        [key]: trimmedText
     });
    }, 1);
}



getDataList(item) {
  switch (item) {
    case 'state':
    return this.stateName;
    case 'circle':
      return this.circleNames;
    case 'division':
      return this.divisionNames;
    case 'subDivision':
      return this.subDivisionNames;
    case 'section':
     return this.sectionNames;
    default:
      return [];
  }
}


capitalizeFirstLetter(item: string): string {
  if (!item) return item;
  return item.charAt(0).toUpperCase() + item.slice(1).toLowerCase();
}
}
