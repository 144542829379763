import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'time'
})
export class TimePipe implements PipeTransform {
    
    transform(value): string {
        var sec_num = value;
        var m = Math.floor(sec_num % 3600 / 60);
        var s = Math.floor(sec_num % 60);
        return m + ':' + s;
      }
}