import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-delete-application-with-resources',
  templateUrl: './delete-application-with-resources.component.html',
  styleUrls: ['./delete-application-with-resources.component.scss']
})
export class DeleteApplicationWithResourcesComponent implements OnInit {

  @Input() selectedAppInfo : any;
  @Input() tableData : any;
  @Input() customerId : string;

  constructor(public activeModal : NgbActiveModal) { }

  ngOnInit() { }

  buildPostData() {

    let { applicationId, applicationName } = this.selectedAppInfo;

    let postData = {
      applicationId,
      customerId : this.customerId,
      resourceActionIds: []
    }

    let assignedResourcesForApp = this.tableData[applicationName];

    if (Object.keys(assignedResourcesForApp).length > 0) {

      let resourceTypes = Object.keys(assignedResourcesForApp);

      resourceTypes.forEach((resourceType: string) => {

        let resources = assignedResourcesForApp[resourceType];

        resources.forEach((resource: any) => {
          let { resourceActionId } = resource;
          postData.resourceActionIds.push(resourceActionId);
        })
      })
    }

    this.closeModal(postData);
  }

  closeModal(info : any = {}) {
    this.activeModal.close(info);
  }
}
