import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UM_TABLE_CNST } from 'src/app/constants/table.cnst';
import { USER_MGMT_CNST } from 'src/app/constants/proj.cnst';
import { CommonService } from 'src/app/services/common.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AppStateService } from 'src/app/services';
import { DeleteComponent } from 'src/app/modules/shared/components/delete/delete.component';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})

export class UsersListComponent implements OnInit {
  @Input() tableConfig: any = {}
  @Input() tableData: any = [];
  @Input() isCustomer: any;

  dataTypes = UM_TABLE_CNST.CLICKABLE_DATA_TYPE;
  opts = USER_MGMT_CNST.OPTIONS;
  showUserId : boolean = false;
  
  constructor(
    private modal: NgbModal,
    private appStateSrv: AppStateService,
    private commonService: CommonService) { }

  ngOnInit() {}

  handleOptionClick(rowData: any) {
    let data = this.tableData.find(d=> d.emailId === rowData.data[1]['values'][0]);
    let e = {
      val : rowData['val'],
      data : data
    }
     
    if(rowData['val'] == USER_MGMT_CNST.OPTIONS.DELETE) {
      const modalRef = this.modal.open(DeleteComponent, USER_MGMT_CNST.DELETE_POPUP_OPTIONS);
      let postData = {
        userId: data.userId
      }

      modalRef.result
        .then((result: any) => {
          if(result == true){
          this.setOperationType({
          operation: USER_MGMT_CNST.OPERATION_TYPES.USER.DELETE_USER,
          postData
        })
      }
      })
        .catch((reason: any) => {
          console.log("Reason for rejection ", reason);
        })
    }else {
      this.commonService.handleOptionClick(e, !this.isCustomer? USER_MGMT_CNST.LIST_NAME_MAPPING.USER_LIST : USER_MGMT_CNST.LIST_NAME_MAPPING.USER_CUSTOMER_LIST);
    }
  }

  setOperationType(value : any) {
    this.appStateSrv.operationRef.next(value);
  }
}
