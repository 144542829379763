import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { USER_MGMT_CNST } from 'src/app/constants';

@Component({
  selector: 'app-view-actions',
  templateUrl: './view-actions.component.html',
  styleUrls: ['./view-actions.component.scss']
})
export class ViewActionsComponent implements OnInit {
  @Input() modalData:any;
  status : boolean = true;
  constructor(public activeModal: NgbActiveModal
    ) { }
 ngOnInit() {
   this.status = this.modalData.status;
  }

}
