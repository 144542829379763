import { Injectable } from '@angular/core';
import * as Keycloak from 'keycloak-js';
import { Subject } from 'rxjs';

/**
  * NOTE: Use this service as a centralized data store. 
  * Data which can be shared globally across multiple components that you can keep here.
  * Use subjects only if it is needed.
  * Write setters and getters for each variable. all variables except subjects should be private.
  * 
  * EXAMPLE: 
  * private sidebarState: "opened" | "closed" = "closed";
  * sidebarStateRef = new BehaviorSubject<"opened" | "closed">(this.sidebarState);
  * 
  * getSidebarState() {return this.sidebarState;}
  * setSidebarState(state: "opened" | "closed") {
  *  this.sidebarState = sidebarState;
  *  this.sidebarStateRef.next(this.sidebarState);
  * }
  */
@Injectable({
  providedIn: 'root'
})
export class AppStateService {
  constructor() { }

  allRecordsFetched: boolean = false;
  allRecordsFetchedRef = new Subject<boolean>();

  setAllRecordsFetched(value: boolean) {
    this.allRecordsFetched = value;
    this.allRecordsFetchedRef.next(this.allRecordsFetched);
  }

  getAllRecordsFetched() {
    return this.allRecordsFetched;
  }


  //*Infinite Scrolling
  wasPageScrolled: boolean = false;
  wasPageScrolledRef = new Subject<boolean>();

  setWasPageScrolled(value: boolean) {
    this.wasPageScrolled = value;
    this.wasPageScrolledRef.next(this.wasPageScrolled);
  }

  getWasPageScrolled() {
    return this.wasPageScrolled;
  }

  urlDetails: any = {};
  urlDetailsRef = new Subject<any[]>();

  setUrlDetails(value: any) {
    this.urlDetails = value;
    this.urlDetailsRef.next(this.urlDetails);
  }

  getUrlDetails() {
    return this.urlDetails;
  }

  showSidebar: boolean = true;
  showSidebarRef = new Subject<boolean>();

  getSidebarState() {
    return this.showSidebar;
  }

  setSidebarState(state: boolean) {
    this.showSidebar = state;
    this.showSidebarRef.next(this.showSidebar);
  }

  //*Add, Edit, Delete Operations
  operation: any = {};
  operationRef = new Subject<any>();

  setOperationRef(data: any) {
    this.operation = data;
    this.operationRef.next(this.operation);
  }

  getOperationRef() : any {
    return this.operation;
  }

  userPermissions: any = {};
  userPermissionsRef = new Subject<any>();

  setUserPermissionsRef(data: any) {
    // if (!this.getPermissionsLoaded()) {
      this.userPermissions = data;
      this.userPermissionsRef.next(this.userPermissions);
    // }
  }

  getUserPermissions() : any {
    return this.userPermissions;
  }

  userMetaInfo: any = {};
  userMetaInfoRef = new Subject<any>();

  setUserMetaInfo(data: any) {
    this.userMetaInfo = data;
    this.userMetaInfoRef.next(this.userMetaInfo);
  }

  getUserMetaInfo() : any {
    return this.userMetaInfo;
  }

  inProcess : boolean = false;
  permissionsLoaded : boolean = false;

  setInProcess(value : boolean) {
    this.inProcess = value;
  }

  setPermissionsLoaded(value : boolean) {
    this.permissionsLoaded = value;
  }

  getInProcess() : boolean {
    return this.inProcess;
  }

  getPermissionsLoaded() : boolean {
    return this.permissionsLoaded;
  }

  customerData: any = null;

  setCustomerData(value: any){
    this.customerData = value;
  }

  getCustomerData(){
    return this.customerData;
  }

  profileData: any = null;

  setProfileData(value: any){
    this.profileData = value;
  }

  getProfileData(){
    return this.profileData;
  }

  applicationData: any = null;

  setApplicationData(value: any){
    this.applicationData = value;
  }

  getApplicationData(){
    return this.applicationData;
  }

  currentCustomerId : string = null;

  setCurrentCustomerId(customerId : string) {
    this.currentCustomerId = customerId;
  }

  getCurrentCustomerId() : string {
    return this.currentCustomerId;
  }

  currentResourceOperation : string = null;

  setCurrentResourceOperation(operationType : string) {
    this.currentResourceOperation = operationType;
  }

  getCurrentResourceOperation() : string {
    return this.currentResourceOperation;
  }

  currentCustomerDetails : any = {};

  setCurrentCustomerInfo(customerDetails : any) {
    this.currentCustomerDetails = customerDetails;
  }

  getCurrentCustomerInfo() : any {
    return this.currentCustomerDetails;
  }

  selectedResourceDetails : any = {};

  setSelectedResourceDetails(selectedResourceDetails : any) {
    this.selectedResourceDetails = selectedResourceDetails;
  }

  getSelectedResourceDetails() : any {
    return this.selectedResourceDetails;
  }

  deletedAppInfo : any = {};
  deletedAppInfoRef = new Subject<any>();

  setDeletedAppInfoDetails(appInfo : any) {
    this.deletedAppInfo = appInfo;
    this.deletedAppInfoRef.next(this.deletedAppInfo);
  }

  getDeletedAppInfoDetails() : any {
    return this.deletedAppInfo;
  }

  isTokensLoaded : boolean = false;
  tokensLoadedRef = new Subject<boolean>();

  setIsTokensLoaded(status : boolean) {
    this.isTokensLoaded = status;
    this.tokensLoadedRef.next(this.isTokensLoaded);
  }

  getIsTokensLoaded() : boolean {
    return this.isTokensLoaded;
  }

  keyCloakInstance : Keycloak.KeycloakInstance = null;
  
  setKeyCloakInstance(instance : Keycloak.KeycloakInstance) {
    this.keyCloakInstance = instance;
  }

  getKeyCloakInstance() : Keycloak.KeycloakInstance {
    return this.keyCloakInstance;
  }

  currentResourceName : string = null;
  currentResourceNameRef = new Subject<string>();

  setCurrentResourceName(resourceName : string) : void {
    this.currentResourceName = resourceName;
    this.currentResourceNameRef.next(this.currentResourceName);
  }

  getCurrentResourceName() : string {
    return this.currentResourceName;
  }

  isPredefinedResource : boolean = false;
  isPredefinedResourceRef = new Subject<boolean>();

  setIsPredefinedResource(status : boolean) {
    this.isPredefinedResource = status;
    this.isPredefinedResourceRef.next(this.isPredefinedResource);
  }

  getIsPredefinedResource() : boolean {
    return this.isPredefinedResource;
  }
}
