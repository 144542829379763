import { Injector } from '@angular/core';
import { Router, Routes } from '@angular/router';
import { KeycloakService, KeycloakOptions, KeycloakInitOptions } from 'keycloak-angular';
import { EnvService } from './services/env.service';


export function keyCloakInitializer(
  injector : Injector,
  keycloak : KeycloakService,
  env : EnvService): () => Promise<any> {
  
  const options: KeycloakOptions = {
    config: {
      clientId: env.keyCloakOptions.clientId,
      realm: env.keyCloakOptions.realm,
      url: env.keyCloakUrls.baseUrl
    }
  };

  //* To avoid browser from a full redirect to the Keycloak server and 
  //* back to User mgmt application, instead done in a hidden iframe in the background
  const initOptions : KeycloakInitOptions  = {
    onLoad: 'check-sso',
    checkLoginIframe: true,
    silentCheckSsoRedirectUri:
      window.location.origin + '/assets/silent-check-sso.html',
  }

  return (): Promise<any> => keycloak
    .init({ ...options, ...initOptions })
    .then(() => {
      let router : Router = injector.get(Router);
      let routeConfig : Routes = router.config;

      routeConfig.forEach((item : any) => {
        if (item.path === "externalUrl") {
          item.path = env.appRedirectUrl;
        }
      })

      // console.log("Route Config ", routeConfig);

      router.resetConfig(routeConfig);
    });
}