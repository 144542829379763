import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-scope',
  templateUrl: './scope.component.html',
  styleUrls: ['./scope.component.scss']
})
export class ScopeComponent implements OnInit, OnChanges {

  @Input('initScope') selectedScope: any[] = [];
  @Input('application') application: string = '';
  @Output('scopeChange') scopeChange = new EventEmitter<any>();


  scope: any[] = [];
  data: any = {
    '1f06b4fd-10ba-4860-b646-8557a8289ff0': [
      {
        type: "HIERARCHY",
        multiSelect: false,
        names: [
          {name: 'State', parents: []},
          {name: "District", parents: [
            {name: 'State', order: 0}
          ]},
          {name: 'Mandal', parents: [
            {name: 'State', order: 0},
            {name: 'District', order: 1}
          ]},
          {name: 'Village', parents: [
            {name: 'State', order: 0},
            {name: 'District', order: 1},
            {name: 'Mandal', order: 2}
          ]}
        ]
      }
    ],
    '6d421cf8-5ed8-459b-a9b3-6236c57b7094': [
      {
        type: "NON_HIERARCHY",
        multiSelect: true,
        names: [
          {name: "Application"},
          {name: "Customers"}
        ]
      }
    ]
  }

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.application || changes.initScope) {
      // let currentAppliation = changes.application.currentValue;
      this.updateSelectedScope();
    }
  }

  updateSelectedScope() {
    this.scope = (this.data[this.application] || []).map(s => {
      console.log('selectedScope:: ', this.selectedScope);
      s.selectedNames = this.selectedScope.filter(cs => s.type === cs.scopeType).map(cs => cs.scopeName);
      s.selectedNames = s.selectedNames || [];

      // if(s.type === 'HIERARCHY') {
      //   s.parents = this.getParents('District', this.data[this.application]);
      // }
      return s;
    });
    console.log("Scope:: ", this.scope);

  }

  getParents(name, scopeInfo) {
    console.log("Name:: ", name, ", ScopeInfo:: ", scopeInfo);


    return [
      {name: "Mandal", order: 2},
      {name: 'District', order: 1},
      {name: "State", order: 0}
    ]
  }

  onScopeChange(data: any) {
    console.log("onscopechange:: ", data);
    const scopes = this.scope.filter(s => s.selectedNames && s.selectedNames.length)
    const validScopes = [];
    for(let scope of scopes) {
      if(scope.multiSelect) {
        for(let name of scope.selectedNames) {
          const obj = {
            scopeType: scope.type,
            scopeName: name
          }
          validScopes.push(obj);
        }
      }else {
        const obj = {
          scopeType: scope.type,
          scopeName: scope.selectedNames,
          parents: scope.parents
        }
        validScopes.push(obj);
      }
    }
    console.log("ValidScopes:: ", validScopes);
    this.scopeChange.emit({validScopes});
  }

  

}
