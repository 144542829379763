import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { USER_MGMT_CNST } from 'src/app/constants';

@Component({
  selector: 'app-edit-role',
  templateUrl: './edit-role.component.html',
  styleUrls: ['./edit-role.component.scss']
})
export class EditRoleComponent implements OnInit {
 
  constructor(public activeModal: NgbActiveModal) { }


  applicationCount: any = 1
  resourcesCount: any = [];
  selectedApplications: any = [];
  selectedProfiles:any = [];
  selectedResourcess: any ;
  selectedPremissions: any;
  modalData: any = {} ;

  roles = USER_MGMT_CNST.ROLES; 
  ngOnInit() {
    this.setResources();
  }

  setResources() {
    for (let index = 0; index < this.applicationCount; index++) {
      this.resourcesCount[index] = 1;
    }
    this.selectedApplications = [];
    this.selectedResourcess = [['']];
    this.selectedPremissions= [['']];
  }

  counter(i: number) {
    return new Array(i);
  }

  addRemoveApplication(flag, index) {
    if (flag == 0) {
      this.selectedApplications.splice(index, 1);
      this.selectedProfiles.splice(index, 1);
      this.selectedResourcess.splice(index, 1);
      this.selectedPremissions.splice(index, 1);
      this.resourcesCount.splice(index, 1);
      this.applicationCount--
    } if (flag == 1) {
      this.selectedResourcess[index + 1] = new Array(1).fill('');
      this.selectedPremissions[index + 1] = new Array(1).fill('');
      this.resourcesCount[index + 1] = 1;
      this.applicationCount++
    } 
  }

  addRemoveResource(flag, applicationIndex, resourceIndex) {
    if(flag == 1){
      this.selectedResourcess[applicationIndex].push('');
      this.selectedPremissions[applicationIndex].push('');
      this.resourcesCount[applicationIndex]++
    }
    if(flag== 0){
      this.selectedResourcess[applicationIndex].splice(resourceIndex, 1);
      this.selectedPremissions[applicationIndex].splice(resourceIndex, 1);
      this.resourcesCount[applicationIndex]--
    }
  }

  onFormSubmit(form: NgForm) {
  }

}
