import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { DataTableEventsService } from '../../../../services/datatable.events.service';
import { NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: "lib-table-cell, [lib-table-cell]",
  templateUrl: "./table-cell.component.html",
  styleUrls: ["./table-cell.component.css"],
  providers: [NgbPopoverConfig],
})
export class TableCellComponent implements OnInit {

  @Input() cellData: any;
  @Input() rowIndex: number;
  @Input() isTotalData: boolean;
  @Output() onCellClick = new EventEmitter<Object>();
  @Output() onOptionClick = new EventEmitter<Object>();
  @Output() onWatchlistClick = new EventEmitter<Object>();



  constructor(
    private tableEventsHandler: DataTableEventsService,
    private popOverConfig: NgbPopoverConfig
  ) {}

  ngOnInit() {
    this.popOverConfig.autoClose = "outside";
  }

  click(element: any): void {

    this.onCellClick.emit(element);
  }


  handleWatchlistClick(cellData: any) {
    cellData.values[0] = !cellData.values[0]
    this.onWatchlistClick.emit(cellData);
  }

  popupCalled(cellData: any) {
    this.tableEventsHandler.popupCalled.next(cellData);
  }

  selectedOption(val,cell) {
    this.onOptionClick.emit({val:val,index:cell.rowIndex});
  }
  
  //*Handler for option clicked in popover
  handleOptionClick(val: string, cellData: any) {
    this.tableEventsHandler.setRowPopOverDetails({value: val, rowIndex: cellData.rowIndex});
    this.selectedOption(val, cellData);
  }

}
