import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { USER_MGMT_CNST } from 'src/app/constants';
import { TimePipe } from 'src/app/lib/table/common/utils/time.pipe';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit {

  @Output() messageEvent = new EventEmitter<string>();
  interval
  displayTime: string;
  eventTriggering: boolean = false;

  constructor(private time: TimePipe,) { }

  ngOnInit() {
  }
  ngOnDestroy() {
    clearInterval(this.interval)
  }
  clearInterval(interval: any) {
    clearInterval(this.interval)
  }
  //displaying timer for validating OTP
  startTimer() {
    this.eventTriggering = false;
    clearInterval(this.interval)
    let timer = USER_MGMT_CNST.VALIDATION_CONSTANT
    this.interval = setInterval(() => {
      if (timer == 0) {
        clearInterval(this.interval)
        this.eventTriggering = true;
        this.messageEvent.emit('You expired time , please resend Verification Code again')        // this.displayErrorMessage = "You expired time , please resent otp again";
        return;
      } else {
        timer--;
      }
      this.displayTime = this.time.transform(timer)
    }, 1000);
  }
}
