import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-add-administrator',
  templateUrl: './add-administrator.component.html',
  styleUrls: ['./add-administrator.component.scss']
})
export class AddAdministratorComponent implements OnInit {

  adminData: any;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.adminData =[];
  }


  onlyAlphabet(event: any) {
    const pattern = /[a-zA-Z ]/;

    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  onFormSubmit(form: NgForm) {
  }

}
