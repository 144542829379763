import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { USER_MGMT_CNST } from 'src/app/constants/proj.cnst';
import { UM_TABLE_CNST } from 'src/app/constants/table.cnst';
import { AddAdministratorComponent } from '../add-administrator/add-administrator.component';
import { CommonService } from 'src/app/services/common.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-administrators-list',
  templateUrl: './administrators-list.component.html',
  styleUrls: ['./administrators-list.component.scss']
})
export class AdministratorsListComponent implements OnInit {

  tableConfig : any = {}
  tableData : any = [];
  dataTypes = UM_TABLE_CNST.CLICKABLE_DATA_TYPE;
  modalData:any=[];

  constructor(private modal: NgbModal,
    private commonService: CommonService,
    private dataService : DataService
    ) { }

  public addAdminModal(){
    this.modal.open(AddAdministratorComponent,USER_MGMT_CNST.MODAL_OPTIONS)
  };

  ngOnInit() {
    this.setTableConfig();
    this.setTableData();
  }
  setTableConfig(){
    this.tableConfig = this.commonService.setTableConfig(USER_MGMT_CNST.LIST_NAME_MAPPING.ADMIN_LIST);
  }

  setTableData(){
    this.tableData = this.dataService.getListData(USER_MGMT_CNST.LIST_NAME_MAPPING.ADMIN_LIST);
  }

  handleOptionClick(e){
    this.commonService.handleOptionClick(e, USER_MGMT_CNST.LIST_NAME_MAPPING.ADMIN_LIST);
  
  }

}
