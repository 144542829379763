import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { Subscription } from 'rxjs';
import { NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';

import { TableDefaultConstants } from '../../constants/table.constant';
import { DataTableEventsService } from '../../services/datatable.events.service';
import { USER_MGMT_CNST } from 'src/app/constants';

@Component({
  selector: '[lib-header-row]',
  templateUrl: './header-row.component.html',
  styleUrls: ['./header-row.component.css'],
  providers:[NgbPopoverConfig]
})
export class TableHeaderRowComponent implements OnInit, OnDestroy {

  @Input() headers: any;
  @Input() defaultSortOptions: any;
  @Input() indexCons: any;

  sortOptionsSub: Subscription;
  sortOptions: any = TableDefaultConstants.sortOptions;
  subscriptions: Subscription[] = [];

  columnValues: any[];
  filteredColumnRecords: any[];
  columnWiseRecords: any[];
  noRecords: boolean = false;
  previousHeader: any = null;
  selectedItems: any = [];
  searchText: string = '';
  checkAll: boolean = false;
  tableData: any = [];
  popoverRef: any;

  averageValue : number = null;

  // listWidth = USER_MGMT_CNST.TABLE_WIDTH;
  selectedOption: any;

  constructor(
    private tableEventsHandler: DataTableEventsService,
    private popOverConfig: NgbPopoverConfig) { }

  ngOnInit() {  
    this.sortOptions = { ...this.sortOptions, ...this.defaultSortOptions };

    this.columnValues = this.tableEventsHandler.getColumnValues();

    const tableWasScrolledRef = this.tableEventsHandler.tableWasScrolledRef.subscribe((value : boolean) => {
      if(value && !isNullOrUndefined(this.popoverRef)) {
        this.popoverRef.close();
      }
    })

    const columnValuesRef = this.tableEventsHandler.columnValuesRef.subscribe((values: any[]) => {
      this.columnValues = values;     
      this.filteredColumnRecords = [...this.columnValues];
      this.noRecords = this.columnValues.length === 0 ? true : false;
    });

    const dataRef = this.tableEventsHandler.masterRecordDataRef.subscribe((values: any[]) => {
     this.tableData = values;
    });
    
    this.columnWiseRecords = this.tableEventsHandler.getColumnWiseRecords();

    const columnWiseDataRef = this.tableEventsHandler.columnWiseRecordsRef.subscribe((values: any[]) => {
      this.columnWiseRecords = values;
    })

    this.popOverConfig.autoClose = "outside";

    this.determinePopoverPlacement();

    this.subscriptions.push(columnValuesRef);
    this.subscriptions.push(columnWiseDataRef);
    this.subscriptions.push(dataRef);
    this.subscriptions.push(tableWasScrolledRef);
  }

  ngOnChanges() {
    this.setHeaderClass();
  }

  determinePopoverPlacement() {
    let { HEADER_THRESHOLD, STANDARD_SIZE } = USER_MGMT_CNST.POPOVER_OPTIONS;
    let headersLength = this.headers[0].length;

    this.averageValue = headersLength <= HEADER_THRESHOLD 
      ? Math.floor(headersLength / 2)
      : STANDARD_SIZE;    
  }

  setHeaderClass() {
    let i = 1;
    this.headers.forEach(head => {
      head.forEach(h => {
        h['class'] = "fixed-header-" + i;
        // if (h['name'] == USER_MGMT_CNST.SOME_COMMON_LABELS.STANDARD_ORDER) {
        //   h['class'] = 'd-none'
        // }
      })
      i = i + 1;
    })
  }

  sortHeader(header) {
    if (!isNullOrUndefined(header)) {
      let currentSortIndex, currentSortType;
      let currentSortOptions = !isNullOrUndefined(this.sortOptions) ? this.sortOptions : null;
      if (!isNullOrUndefined(currentSortOptions)) {
        currentSortIndex = currentSortOptions.sortIndex;
        currentSortType = currentSortOptions.sortType;
      }
      let order = (!isNullOrUndefined(currentSortOptions) && (header.sortIndex == currentSortIndex)) ? (currentSortType == 'asc' ? 'desc' : 'asc') : 'asc';
      this.sortOptions = { sortIndex: header.sortIndex, sortType: order };
      this.tableEventsHandler.sortOptions.next(this.sortOptions);
    }
  }

  getSortIconClass(header) {
    if (!!header.sortable) {
      let sortOptions: any = this.sortOptions;
      if (!isNullOrUndefined(sortOptions)) {
        let sortIndex = sortOptions.sortIndex;
        let sortType = sortOptions.sortType;
        if (header.sortIndex == sortIndex && !isNullOrUndefined(sortType)) {
          return sortType.toUpperCase() !== 'ASC' ? 'fa fa-arrow-down' : 'fa fa-arrow-up';
        }
      }
      return 'fa fa-sort';
    } else {
      return '';
    }
  }

  //*Multi column multi filter methods start
  toggleChevron(header: any, popOverRef?: any) {
    header.showPopOver = !header.showPopOver;
    this.popoverRef = popOverRef;

    if (this.previousHeader && header.sortIndex !== this.previousHeader.sortIndex) {
      this.previousHeader.showPopOver = false;
    }

    this.previousHeader = header;

    //*If we want to show the popover then fetch records for that column
    if (header.showPopOver) {
      popOverRef.open();
      this.searchText = '';
      this.showPopOverForColumn(header);
    } else {
      popOverRef.close();
    }
  }

  //*hasDistinct - If we want distinct values for column pass true
  showPopOverForColumn(header: object) {
    this.tableEventsHandler.setMultiColMultiFilterOptions({
      sortIndex: header['sortIndex'],
      hasDistinct: true
    })
  }

  searchColumnValues() {
    this.filteredColumnRecords = this.columnValues.filter((item: any) => {

      let result;
      if(typeof item.value === 'number'){
        let itemPresent = item.value.toString();
        let itemSearch = this.searchText.toString();
          result = itemPresent.indexOf(itemSearch) !== -1;
      }else{
        let itemPresent = item.value.toLowerCase();
        let itemSearch = this.searchText.toLowerCase();
          result = itemPresent.indexOf(itemSearch) !== -1;
      }
      return result; 
    }); 
    this.noRecords = this.filteredColumnRecords.length === 0 ? true : false;
  }

  filterTableRows(colInfo: any){
    //*Toggle the checkbox Status
    colInfo.isChecked = !colInfo.isChecked;

    //*Add to selected items only if item is selected else 
    //*Filter out the unselected item from the array
    if(colInfo.isChecked) {
      this.selectedItems.push(colInfo);
    } else {
      this.selectedItems = this.selectedItems.filter(item => item.value !== colInfo.value);
    }       
    this.tableEventsHandler.columnValuesRef.next(this.columnValues); 
    this.updateColumnWiseRecords(colInfo);
    this.tableEventsHandler.setSelectedFilterItems(this.selectedItems);
  }

  selectAll() {
    this.columnValues.forEach((item: any) => {
      if(!item.isChecked) {
        item.isChecked = true;
        this.selectedItems.push(item);
        this.updateColumnWiseRecords(item);
      }
    })
    this.tableEventsHandler.columnValuesRef.next(this.columnValues);
    this.tableEventsHandler.setSelectedFilterItems(this.selectedItems);
  }

  unselectAll() {    
    this.columnValues.forEach((item: any) => {
      if(item.isChecked){
        item.isChecked = false;
        let itemIndex = this.selectedItems.findIndex(record => record.value === item.value);

        if(itemIndex !== -1) {
          this.selectedItems[itemIndex] = item;
        }
        this.updateColumnWiseRecords(item);
      }
    })

    //*Retain only the selected items across all columns on unselection
    this.selectedItems = this.selectedItems.filter(item => item.isChecked === true);

    this.tableEventsHandler.columnValuesRef.next(this.columnValues);
    this.tableEventsHandler.setSelectedFilterItems(this.selectedItems);
  }

  //*For each selection / unselection update the master column records
  updateColumnWiseRecords(colInfo: any) {
    this.columnWiseRecords.forEach((item: any[]) => {
      let row = item[colInfo.sortIndex];

      if (row.value[0] === colInfo.value) {
        row.isChecked = colInfo.isChecked;
      }
    })
    this.tableEventsHandler.columnWiseRecordsRef.next(this.columnWiseRecords);
  }

  onPopOverClose(header: any) {
    header.showPopOver = false;
  }

  //*Multi column multi filter methods end

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    })
  }

  checkStatus(e){
    this.tableEventsHandler.setCheckStatusData(e.srcElement.checked);
  }
}
