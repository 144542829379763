import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { USER_MGMT_CNST } from 'src/app/constants/proj.cnst';

@Component({
  selector: 'app-view-users',
  templateUrl: './view-users.component.html',
  styleUrls: ['./view-users.component.scss']
})
export class ViewUsersComponent implements OnInit {
  @Input() modalData;
  @Input() viewUserConfig: any;
  @Input() sortedData: any;
  userData: any = {};
  modalDataStatus : any = USER_MGMT_CNST.MODAL_STATUS;
  
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {  }

}
