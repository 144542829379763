import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { USER_MGMT_CNST } from 'src/app/constants/proj.cnst';

@Component({
  selector: 'app-view-administrators',
  templateUrl: './view-administrators.component.html',
  styleUrls: ['./view-administrators.component.scss']
})
export class ViewAdministratorsComponent implements OnInit {

  @Input() modalData;
  modalDataStatus : any = USER_MGMT_CNST.MODAL_STATUS;


  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

}
