export const countryCodes = [
  {
    "label": "AFG-93",
    "value": "93"
  },
  {
    "label": "ALB-355",
    "value": "355"
  },
  {
    "label": "DZA-213",
    "value": "213"
  },
  {
    "label": "ASM-1-684",
    "value": "1-684"
  },
  {
    "label": "AND-376",
    "value": "376"
  },
  {
    "label": "AGO-244",
    "value": "244"
  },
  {
    "label": "AIA-1-264",
    "value": "264"
  },
  {
    "label": "ATA-672",
    "value": "672"
  },
  {
    "label": "ATG-1-268",
    "value": "1-268"
  },
  {
    "label": "ARG-54",
    "value": "54"
  },
  {
    "label": "ARM-374",
    "value": "374"
  },
  {
    "label": "ABW-297",
    "value": "297"
  },
  {
    "label": "AUS-61",
    "value": "61"
  },
  {
    "label": "AUT-43",
    "value": "43"
  },
  {
    "label": "AZE-994",
    "value": "994"
  },
  {
    "label": "BHS-1-242",
    "value": "1-242"
  },
  {
    "label": "BHR-973",
    "value": "973"
  },
  {
    "label": "BGD-880",
    "value": "880"
  },
  {
    "label": "BRB-1-246",
    "value": "246"
  },
  {
    "label": "BLR-375",
    "value": "375"
  },
  {
    "label": "BEL-32",
    "value": "32"
  },
  {
    "label": "BLZ-501",
    "value": "501"
  },
  {
    "label": "BEN-229",
    "value": "229"
  },
  {
    "label": "BMU-1-441",
    "value": "1-441"
  },
  {
    "label": "BTN-975",
    "value": "975"
  },
  {
    "label": "BOL-591",
    "value": "591"
  },
  {
    "label": "BIH-387",
    "value": "387"
  },
  {
    "label": "BWA-267",
    "value": "267"
  },
  {
    "label": "BRA-55",
    "value": "55"
  },
  {
    "label": "IOT-246",
    "value": "246"
  },
  {
    "label": "VGB-1-284",
    "value": "1-284"
  },
  {
    "label": "BRN-673",
    "value": "673"
  },
  {
    "label": "BGR-359",
    "value": "359"
  },
  {
    "label": "BFA-226",
    "value": "226"
  },
  {
    "label": "BDI-257",
    "value": "257"
  },
  {
    "label": "KHM-855",
    "value": "855"
  },
  {
    "label": "CMR-237",
    "value": "237"
  },
  {
    "label": "CAN-1",
    "value": "1"
  },
  {
    "label": "CPV-238",
    "value": "238"
  },
  {
    "label": "CYM-1-345",
    "value": "1-345"
  },
  {
    "label": "CAF-236",
    "value": "236"
  },
  {
    "label": "TCD-235",
    "value": "235"
  },
  {
    "label": "CHL-56",
    "value": "56"
  },
  {
    "label": "CHN-86",
    "value": "86"
  },
  {
    "label": "CXR-61",
    "value": "61"
  },
  {
    "label": "CCK-61",
    "value": "61"
  },
  {
    "label": "COL-57",
    "value": "57"
  },
  {
    "label": "COM-269",
    "value": "269"
  },{
    "label": "COK-682",
    "value": "682"
  },
  {
    "label": "CRI-506",
    "value": "506"
  },
  {
    "label": "HRV-385",
    "value": "385"
  },
  {
    "label": "CUB-53",
    "value": "53"
  },
  {
    "label": "CUW-599",
    "value": "599"
  },
  {
    "label": "CYP-357",
    "value": "357"
  },
  {
    "label": "CZE-420",
    "value": "420"
  },
  {
    "label": "COD-243",
    "value": "243"
  },
  {
    "label": "DNK-45",
    "value": "45"
  },
  {
    "label": "DJI-253",
    "value": "253"
  },
  {
    "label": "DMA-1-767",
    "value": "1-767"
  },
  {
    "label": "DOM_1-1-809",
    "value": "1-809"
  },
  {
    "label": "DOM_2-1-829",
    "value": "1-829"
  },
  {
    "label": "DOM_3-1-849",
    "value": "1-849"
  },
  {
    "label": "TLS-670",
    "value": "670"
  },
  {
    "label": "ECU-593",
    "value": "593"
  },{
    "label": "EGY-20",
    "value": "20"
  },
  {
    "label": "SLV-503",
    "value": "503"
  },
  {
    "label": "GNQ-240",
    "value": "240"
  },
  {
    "label": "ERI-291",
    "value": "291"
  },
  {
    "label": "EST-372",
    "value": "372"
  },
  {
    "label": "ETH-251",
    "value": "251"
  },
  {
    "label": "FLK-500",
    "value": "500"
  },
  {
    "label": "FRO-298",
    "value": "298"
  },
  {
    "label": "FJI-679",
    "value": "679"
  },
  {
    "label": "FIN-358",
    "value": "358"
  },
  {
    "label": "FRA-33",
    "value": "33"
  },
  {
    "label": "PYF-689",
    "value": "689"
  },
  {
    "label": "GAB-241",
    "value": "241"
  },
  {
    "label": "GMB-220",
    "value": "220"
  },
  {
    "label": "GEO-995",
    "value": "995"
  },
  {
    "label": "DEU-49",
    "value": "49"
  },
  {
    "label": "GHA-233",
    "value": "233"
  },
  {
    "label": "GIB-350",
    "value": "350"
  },
  {
    "label": "GRC-30",
    "value": "30"
  },
  {
    "label": "GRL-299",
    "value": "299"
  },
  {
    "label": "GRD-1-473",
    "value": "1-473"
  },
  {
    "label": "GUM-1-671",
    "value": "1-671"
  },
  {
    "label": "GTM-502",
    "value": "502"
  },
  {
    "label": "GGY-44-1481",
    "value": "44-1481"
  },
  {
    "label": "GIN-224",
    "value": "224"
  },
  {
    "label": "GNB-245",
    "value": "245"
  },
  {
    "label": "GUY-592",
    "value": "592"
  },
  {
    "label": "HTI-509",
    "value": "509"
  },
  {
    "label": "HND-504",
    "value": "504"
  },
  {
    "label": "HKG-852",
    "value": "852"
  },
  {
    "label": "HUN-36",
    "value": "36"
  },
  {
    "label": "ISL-354",
    "value": "354"
  },
  {
    "label": "IND-91",
    "value": "91"
  },
  {
    "label": "IDN-62",
    "value": "62"
  },
  {
    "label": "IRN-98",
    "value": "98"
  },
  {
    "label": "IRQ-964",
    "value": "964"
  },
  {
    "label": "IRL-353",
    "value": "353"
  },
  {
    "label": "IMN-44-1624",
    "value": "44-1624"
  },
  {
    "label": "ISR-972",
    "value": "972"
  },
  {
    "label": "ITA-39",
    "value": "39"
  },
  {
    "label": "CIV-225",
    "value": "225"
  },
  {
    "label": "JAM-1-876",
    "value": "1-876"
  },
  {
    "label": "JPN-81",
    "value": "81"
  },
  {
    "label": "JEY-44-1534",
    "value": "44-1534"
  },
  {
    "label": "JOR-962",
    "value": "962"
  },
  {
    "label": "KAZ-7",
    "value": "7"
  },
  {
    "label": "KEN-254",
    "value": "254"
  },
  {
    "label": "KIR-686",
    "value": "686"
  },
  {
    "label": "XKX-383",
    "value": "383"
  },
  {
    "label": "KWT-965",
    "value": "965"
  },
  {
    "label": "KGZ-996",
    "value": "996"
  },
  {
    "label": "LAO-856",
    "value": "856"
  },
  {
    "label": "LVA-371",
    "value": "371"
  },
  {
    "label": "LBN-961",
    "value": "961"
  },
  {
    "label": "LSO-266",
    "value": "266"
  },
  {
    "label": "LBR-231",
    "value": "231"
  },
  {
    "label": "LBY-218",
    "value": "218"
  },
  {
    "label": "LIE-423",
    "value": "423"
  },
  {
    "label": "LTU-370",
    "value": "370"
  },
  {
    "label": "LUX-352",
    "value": "352"
  },
  {
    "label": "MAC-853",
    "value": "853"
  },
  {
    "label": "MKD-389",
    "value": "389"
  },
  {
    "label": "MDG-261",
    "value": "261"
  },
  {
    "label": "MWI-265",
    "value": "265"
  },
  {
    "label": "MYS-60",
    "value": "60"
  },
  {
    "label": "MDV-960",
    "value": "960"
  },
  {
    "label": "MLI-223",
    "value": "223"
  },
  {
    "label": "MLT-356",
    "value": "356"
  },
  {
    "label": "MHL-692",
    "value": "692"
  },
  {
    "label": "MRT-222",
    "value": "222"
  },
  {
    "label": "MUS-230",
    "value": "230"
  },
  {
    "label": "MYT-262",
    "value": "262"
  },
  {
    "label": "MEX-52",
    "value": "52"
  },
  {
    "label": "FSM-691",
    "value": "691"
  },
  {
    "label": "MDA-373",
    "value": "373"
  },
  {
    "label": "MCO-377",
    "value": "377"
  },
  {
    "label": "MNG-976",
    "value": "976"
  },
  {
    "label": "MNE-382",
    "value": "382"
  },
  {
    "label": "MSR-1-664",
    "value": "1-664"
  },
  {
    "label": "MAR-212",
    "value": "212"
  },
  {
    "label": "MOZ-258",
    "value": "258"
  },
  {
    "label": "MMR-95",
    "value": "95"
  },
  {
    "label": "NAM-264",
    "value": "264"
  },
  {
    "label": "NAM-264",
    "value": "264"
  },
  {
    "label": "NRU-674",
    "value": "674"
  },
  {
    "label": "NPL-977",
    "value": "977"
  },
  {
    "label": "NLD-31",
    "value": "31"
  },
  {
    "label": "ANT-599",
    "value": "599"
  },
  {
    "label": "NCL-687",
    "value": "687"
  },
  {
    "label": "NZL-64",
    "value": "64"
  },
  {
    "label": "NIC-505",
    "value": "505"
  },
  {
    "label": "NER-227",
    "value": "227"
  },
  {
    "label": "NGA-234",
    "value": "234"
  },
  {
    "label": "NIU-683",
    "value": "683"
  },
  {
    "label": "PRK-850",
    "value": "850"
  },
  {
    "label": "MNP-1-670",
    "value": "1-670"
  },
  {
    "label": "NOR-47",
    "value": "47"
  },
  {
    "label": "OMN-968",
    "value": "968"
  },
  {
    "label": "PAK-92",
    "value": "92"
  },
  {
    "label": "PLW-680",
    "value": "680"
  },
  {
    "label": "PSE-970",
    "value": "970"
  },
  {
    "label": "PAN-507",
    "value": "507"
  },
  {
    "label": "PNG-675",
    "value": "675"
  },
  {
    "label": "PRY-595",
    "value": "595"
  },
  {
    "label": "PER-51",
    "value": "51"
  },
  {
    "label": "PHL-63",
    "value": "63"
  },
  {
    "label": "PCN-64",
    "value": "64"
  },
  {
    "label": "POL-48",
    "value": "48"
  },
  {
    "label": "PRT-351",
    "value": "351"
  },
  {
    "label": "PRI_1-1-787",
    "value": "1-787"
  },
  {
    "label": "PRI_2-1-939",
    "value": "1-939"
  },
  {
    "label": "QAT-974",
    "value": "974"
  },
  {
    "label": "COG-242",
    "value": "242"
  },
  {
    "label": "REU-262",
    "value": "262"
  },
  {
    "label": "ROU-40",
    "value": "40"
  },
  {
    "label": "RUS-7",
    "value": "7"
  },
  {
    "label": "RWA-250",
    "value": "250"
  },
  {
    "label": "BLM-590",
    "value": "590"
  },
  {
    "label": "SHN-290",
    "value": "290"
  },
  {
    "label": "KNA-1-869",
    "value": "1-869"
  },
  {
    "label": "LCA-1-758",
    "value": "1-758"
  },
  {
    "label": "MAF-590",
    "value": "590"
  },
  {
    "label": "SPM-508",
    "value": "508"
  },
  {
    "label": "VCT-1-784",
    "value": "1-784"
  },
  {
    "label": "WSM-685",
    "value": "685"
  },
  {
    "label": "SMR-378",
    "value": "378"
  },
  {
    "label": "STP-239",
    "value": "239"
  },
  {
    "label": "SAU-966",
    "value": "966"
  },
  {
    "label": "SEN-221",
    "value": "221"
  },
  {
    "label": "SRB-381",
    "value": "381"
  },
  {
    "label": "SYC-248",
    "value": "248"
  },
  {
    "label": "SLE-232",
    "value": "232"
  },
  {
    "label": "SGP-65",
    "value": "65"
  },
  {
    "label": "SXM-1-721",
    "value": "1-721"
  },
  {
    "label": "SVK-421",
    "value": "421"
  },
  {
    "label": "SVN-386",
    "value": "386"
  },
  {
    "label": "SLB-677",
    "value": "677"
  },
  {
    "label": "SOM-252",
    "value": "252"
  },
  {
    "label": "ZAF-27",
    "value": "27"
  },
  {
    "label": "KOR-82",
    "value": "82"
  },
  {
    "label": "SSD-211",
    "value": "211"
  },
  {
    "label": "ESP-34",
    "value": "34"
  },
  {
    "label": "LKA-94",
    "value": "94"
  },
  {
    "label": "SDN-249",
    "value": "249"
  },
  {
    "label": "SUR-597",
    "value": "597"
  },
  {
    "label": "SJM-47",
    "value": "47"
  },
  {
    "label": "SWZ-268",
    "value": "268"
  },
  {
    "label": "SWE-46",
    "value": "46"
  },
  {
    "label": "CHE-41",
    "value": "41"
  },
  {
    "label": "SYR-963",
    "value": "963"
  },
  {
    "label": "TWN-886",
    "value": "886"
  },
  {
    "label": "TJK-992",
    "value": "992"
  },
  {
    "label": "TZA-255",
    "value": "255"
  },
  {
    "label": "THA-66",
    "value": "66"
  },
  {
    "label": "TGO-228",
    "value": "228"
  },
  {
    "label": "TKL-690",
    "value": "690"
  },
  {
    "label": "TON-676",
    "value": "676"
  },
  {
    "label": "TTO-1-868",
    "value": "1-868"
  },

  {
    "label": "TUN-216",
    "value": "216"
  },
  {
    "label": "TKM-993",
    "value": "993"
  },
  {
    "label": "TCA-1-649",
    "value": "1-649"
  },
  {
    "label": "TUV-688",
    "value": "688"
  },
  {
    "label": "VIR-1-340",
    "value": "1-340"
  },

  {
    "label": "UGA-256",
    "value": "256"
  },
  {
    "label": "UKR-380",
    "value": "380"
  },

  {
    "label": "ARE-971",
    "value": "971"
  },
  {
    "label": "GBR-44",
    "value": "44"
  },

  {
    "label": "USA-1",
    "value": "1"
  },
  {
    "label": "URY-598",
    "value": "598"
  },
  {
    "label": "UZB-998",
    "value": "998"
  },
  {
    "label": "VUT-678",
    "value": "678"
  },
  {
    "label": "VAT-379",
    "value": "379"
  },
  {
    "label": "VEN-58",
    "value": "58"
  },
  {
    "label": "VNM-84",
    "value": "84"
  },
  {
    "label": "WLF-681",
    "value": "681"
  },
  {
    "label": "ESH-212",
    "value": "212"
  },
  {
    "label": "YEM-967",
    "value": "967"
  },
  {
    "label": "ZMB-260",
    "value": "260"
  },
  {
    "label": "ZWE-263",
    "value": "263"
  }
]