export enum Path {
    LOGIN_CONFIG_PATH = 'config/login.config.json',
    SIDEBAR_CONFIG_PATH = 'config/side-bar.config.json',
    LOGO_CONFIG_PATH = 'config/logo.config.json',

    ADD_USER_CONFIG_PATH = 'config/add-user.config.json',
    DELETE_USER_CONFIG_PATH = 'config/delete-user.config.json',
    VIEW_USER_CONFIG_PATH = 'config/view-user.config.json',
    EDIT_USER_CONFIG_PATH  = 'config/edit-user.config.json',

    CHANGE_PASSWD_CONFIG_PATH   = 'config/change-password.config.json',
    OTP_CHANGE_PASSWD_CONFIG_PATH = 'config/otpChangePswd.config.json',
    OTP_FORGOT_PASSWD_CONFIG_PATH = 'config/otpForgotPswd.config.json',
    FORGOT_PASSWD_CONFIG_PATH= 'config/forgot-password.config.json',

    LIST_USERS_CONFIG_PATH = 'config/list-users.config.json',
    LIST_APPLICATIONS_CONFIG_PATH = 'config/list-application.config.json',
    LIST_RESOURCES_CONFIG_PATH = 'config/list-resources.config.json',
    LIST_ROLES_CONFIG_PATH = 'config/list-roles.config.json',

    ERROR_MESSAGE_CONFIG_PATH = 'config/error-message.config.json'
}
