import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeleteComponent } from './components/delete/delete.component';
import { ScopeComponent } from './components/scope/scope.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { SpinnerComponent } from './spinner/spinner.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { TimePipe } from 'src/app/lib/table/common/utils/time.pipe';
import { TimerComponent } from './timer/timer.component';

@NgModule({
  declarations: [DeleteComponent, ScopeComponent,SpinnerComponent,TimePipe, TimerComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgxUiLoaderModule.forRoot({fgsType:'cube-grid', overlayColor: 'rgba(255,255,255,0.5)', fgsSize: 40, pbThickness: 2, pbColor: "#0000FF"}),

  ],
  exports: [DeleteComponent, ScopeComponent,SpinnerComponent,NgxUiLoaderModule,TimerComponent],
  entryComponents: [DeleteComponent],
  providers:[TimePipe],

})
export class SharedModule { }
