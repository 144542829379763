import { Component, OnInit, Input, OnChanges, OnDestroy, SimpleChanges, TemplateRef, ViewChild, EventEmitter, Output } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { Subscription } from 'rxjs';

import { TableDefaultConstants } from '../common/constants/table.constant';
import { tableHeaderFixerDirective } from '../common/directives/table-header-fixer.directive';
import { DataTableService } from '../common/services/data-table.service';
import { DataTableEventsService } from '../common/services/datatable.events.service';
import { AppStateService } from 'src/app/services/app-state.service';
import { USER_MGMT_CNST } from 'src/app/constants';
@Component({
  selector: 'lib-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.css']
})
export class DataTableComponent implements OnInit, OnChanges, OnDestroy {

  @Input() tableConfig: any;
  @Input() data: object;
  @Input() tableNameForDownload: string;
  @Input() loader: boolean;
  @Input() tableType: string;
  @Input() tableId: string;
  @Input() customTemplates: TemplateRef<any>;
  @Input() metaData: object;
  @Input() clickableDataTypes: any;

  @Output() onRowClick = new EventEmitter<Object>();
  @Output() onCheckBoxAdd = new EventEmitter<Object>();
  @Output() optionsClick = new EventEmitter<Object>();
  @Output() watchlistClick = new EventEmitter<Object>();

  // For Angular 7
  @ViewChild(tableHeaderFixerDirective, { static: false}) tablefixer: tableHeaderFixerDirective;
  // For Angular 8
  // @ViewChild(tableHeaderFixerDirective, { static: false }) tablefixer: tableHeaderFixerDirective;


  sortOptions = TableDefaultConstants.sortOptions;
  tableData: any = {};
  masterRecordData: any;
  masterTotalData: any;
  noData: boolean = false;
  paginatorOptions: any;
  headerFixerOptions: any;
  headerCons: object[];
  indexCons: object;
  breadcrumbCons: object;
  downloadOptions: object;
  columnWiseData: any[] = [];
  subscriptions: Subscription[] = [];

  //*Infinite scrolling related variables
  scrollDistance: number;
  throttle: number;
  isScrollOnWindow: boolean;
  isInfiniteScrollDisabled: boolean;

  //*If the table config needs infinite scroll or not
  hasInfiniteScroll: boolean;

  private recordDataSub: Subscription;
  private totalDataSub: Subscription;
  private dataSub: Subscription;


  constructor(
    private tableEventsHandler: DataTableEventsService,
    private tableService: DataTableService,
    private appStateSrv: AppStateService) { }

  ngOnInit() {

    this.initializeInfiniteScrollVariables();
    this.subscribeEvents();

    if (this.tableConfig == undefined || this.tableData == undefined) {
      console.log("Please define proper table configuration and data to render the table data.")
      return;
    }

    this.updateStateData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.tableConfig == undefined || this.tableData == undefined) {
      console.log("Please define proper table configuration and data to render the table data.")
      return;
    }
    this.updateStateData();
  }

  ngOnDestroy() {
    this.unsubscribeEvents();
  }

  subscribeEvents() {
    this.recordDataSub = this.tableEventsHandler.recordData.subscribe(data => {
      if (data.length == 0 || isNullOrUndefined(data)) {
        this.noData = true;
      } else {
        this.noData = false;
      }
      this.tableData.recordData = data;
    })
    this.totalDataSub = this.tableEventsHandler.totalData.subscribe(data => {
      this.tableData.totalData = data;
    })
    this.dataSub = this.tableEventsHandler.data.subscribe(data => {
      this.data = data;
      this.tableData.recordData = data;
      this.formatTable();
    })

    const allRecordsFetchedRef = this.appStateSrv.allRecordsFetchedRef.subscribe((value: boolean) => {
      this.isInfiniteScrollDisabled = value;
    })

    // const optionsDataRef = this.tableEventsHandler.optionsDataRef.subscribe((value) => {
    //   if (value != UM_TABLE_CNST.ES_OPTIONS.ASSIGN) {
    //     if (this.tableData.headers[0][0]['name'] == 'check') {
    //       this.tableData.headers[0].shift();
    //     }
    //   }
    // })

    this.subscriptions.push(this.recordDataSub);
    this.subscriptions.push(this.totalDataSub);
    this.subscriptions.push(this.dataSub);
    this.subscriptions.push(allRecordsFetchedRef);
    // this.subscriptions.push(optionsDataRef);
  }

  unsubscribeEvents() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    })
  }

  initializeInfiniteScrollVariables() {
    let { SCROLL_DISTANCE, THROTTLE, SCROLL_ON_WINDOW, INFINITE_SCROLL_DISABLED } = USER_MGMT_CNST.INFINITE_SCROLL_OPTIONS;

    this.scrollDistance = SCROLL_DISTANCE;
    this.throttle = THROTTLE;
    this.isScrollOnWindow = SCROLL_ON_WINDOW;
    this.isInfiniteScrollDisabled = INFINITE_SCROLL_DISABLED;
  }

  takeBackup = () => {
    this.masterRecordData = this.tableData.recordData ? JSON.parse(JSON.stringify(this.tableData.recordData)) : [];
    this.masterTotalData = this.tableData.totalData ? JSON.parse(JSON.stringify(this.tableData.totalData)) : [];
    this.tableEventsHandler.setMasterRecordData(this.masterRecordData);
  }

  updateStateData() {
    this.paginatorOptions = this.tableConfig.paginatorOptions;
    this.headerFixerOptions = this.tableConfig.tableHeaderFixerInfo;
    this.headerCons = this.tableConfig.columns;
    this.indexCons = this.tableConfig.indexCons;
    this.breadcrumbCons = this.tableConfig.breadcrumbCons;
    this.downloadOptions = this.tableConfig.downloadOptions
    if (!isNullOrUndefined(this.tableConfig) && !isNullOrUndefined(this.tableConfig.sortOptions)) {
      this.sortOptions = this.tableConfig.sortOptions;
    }
    this.formatTable();

    this.tableEventsHandler.togglePage.subscribe(() => {
      setTimeout(() => {
        this.tablefixer.process();
      }, 10);
    })

    this.tableEventsHandler.sortOptions.subscribe(() => {
      setTimeout(() => {
        this.tablefixer.process();
      }, 10);
    })

    if(this.tableId === USER_MGMT_CNST.LIST_NAME_MAPPING.ROLE_LIST || this.tableId === USER_MGMT_CNST.LIST_NAME_MAPPING.CUSTOMER_LIST
      || this.tableId === USER_MGMT_CNST.LIST_NAME_MAPPING.USER_LIST){
       this.sortOptions = {sortIndex: 1};
    }
    // if(!isNullOrUndefined(this.cons) && !isNullOrUndefined(this.cons.defaultSort)){
    //   this.sortOptions = {  sortOrder: this.cons.defaultSort };
    // }
  }

  formatTable() {
    this.hasInfiniteScroll = this.tableConfig.hasInfiniteScroll ? true : false;
    switch (this.tableType) {
      case 'dataTable':
        this.tableData = this.tableService.formatTable(this.tableConfig, this.data);
        this.columnWiseData = this.tableService.prepareColumnWiseData(this.tableData.recordData);
        this.tableEventsHandler.setColumnWiseRecords(this.columnWiseData);
        // console.log("this data :", this.tableData);

        break;
      case 'treeTable':
        break;
      case 'crudTable':
        break;
      default:
        console.warn('Table type not found ! = ', this.tableType);
        break;
    }
    this.takeBackup();
  }

  click(element: any): void {
    this.onRowClick.emit(element);
  }

  handleOptionClick(e) {
    // rows
    let val = e.val;
    let i = e.index;
    let tableData = this.tableEventsHandler.getMasterRecordData();
    let result = tableData.findIndex(object => object[0]['rowIndex'] === i);
    let selectedOrder = tableData.filter((obj) => obj[0]['rowIndex'] === i)[0];
    this.optionsClick.emit({ data: selectedOrder, key: result, val : val });
    // if(val == UM_TABLE_CNST.ES_OPTIONS.VIEW){
    // }

    
    // if (val == UM_TABLE_CNST.ES_OPTIONS.ASSIGN) {
    //   tableData.forEach((ele, index) => {
    //     if (ele[0].dataType[0] == "CHECKBOX") {
    //       ele.shift();
    //     }
    //     ele.unshift({
    //       class: "tc-bg-01",
    //       dataType: ["CHECKBOX"],
    //       sortIndex: 0,
    //       sortOrderList: null,
    //       storeData: undefined,
    //       template: null,
    //       rowIndex: index,
    //       values: (index == i ? [true] : [false]),
    //     });
    //   });
    // } else {
    //   tableData.forEach((ele) => {
    //     if (ele[0].dataType[0] == "CHECKBOX") {
    //       ele.shift();
    //     }
    //   });
    // }
    //headers
    // if (val == UM_TABLE_CNST.ES_OPTIONS.ASSIGN) {
    //   if (this.tableData.headers[0][0]['name'] == 'check') {
    //     this.tableData.headers[0].shift();
    //   }
    //   this.tableData.headers[0].unshift({
    //     class: "fixed-header-1",
    //     cols: 1,
    //     filter: false,
    //     hasMultiColumnFilter: false,
    //     level: 1,
    //     name: "check",
    //     rows: 1,
    //     showPopOver: false,
    //     sortIndex: 0,
    //     sortOrder: "asc",
    //     sortable: true
    //   });
    // } else {
    //   if (this.tableData.headers[0][0]['name'] == 'check') {
    //     this.tableData.headers[0].shift();
    //   }
    // }

    // this.tableEventsHandler.recordData.next(tableData);
    // this.tableEventsHandler.setOptionsData(val);
    // if (val == UM_TABLE_CNST.ES_OPTIONS.CANCEL) {
    //   let result = tableData.findIndex(object => object[0]['rowIndex'] === i);
    //   let selectedOrder = tableData.filter((obj) => obj[0]['rowIndex'] === i)[0];
    //   this.optionsClick.emit({ data: selectedOrder, key: result });
    // }
    // if (val == UM_TABLE_CNST.ES_OPTIONS.REPEAT) {
    //   this.router.navigate(['', 'orders', 'new-order', 'replicated-order']);
    // }
  }

  handleWatchlistClick(e) {
    // let val = e.val;
    let i = e.rowIndex;
    let tableData = this.tableEventsHandler.getMasterRecordData();
    let result = tableData.findIndex(object => object[0]['rowIndex'] === i);
    let selectedOrder = tableData.filter((obj) => obj[0]['rowIndex'] === i)[0];
    let flag = e['values'][0];
    this.watchlistClick.emit({ data: selectedOrder, key: result, flag: flag });
  }

  onScrollDown() {
    this.appStateSrv.wasPageScrolledRef.next(true);
  }
}
