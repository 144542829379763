import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { USER_MGMT_CNST } from "src/app/constants";

@Injectable({
  "providedIn": "root"
})

export class ApiResponseInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {

        if (!req.url.includes('json') && !req.url.includes(USER_MGMT_CNST.API_MAPPING.GET_CSRF_TOKEN)) {
          localStorage.setItem('csrfToken', event.headers.get('csrfToken'));
        }

      }
      return event;
    }))
  }

}