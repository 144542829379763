import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-user-unauthorized',
  templateUrl: './user-unauthorized.component.html',
  styleUrls: ['./user-unauthorized.component.scss']
})
export class UserUnauthorizedComponent implements OnInit {

  constructor(public activeModal : NgbActiveModal) { }

  ngOnInit() {
  }

  closeModal() {
    this.activeModal.close(true);
  }

}
