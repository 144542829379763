import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { USER_MGMT_DEFAULT_CONFIG_CNST } from 'src/app/constants';
import { UtilsService } from 'src/app/services';
import { TimerComponent } from '../../shared/timer/timer.component';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-otp-validation-change-password',
  templateUrl: './otp-validation-change-password.component.html',
  styleUrls: ['./otp-validation-change-password.component.scss']
})
export class OtpValidationChangePasswordComponent implements OnInit {
  @ViewChild(TimerComponent, { static: true }) timer: TimerComponent;
  @Input() newpassword;
  @Input() otpConfig
  submitted: boolean = false;
  displayTime;
  interval;
  displayErrorMessage = "";
  activespiner;
  constant : any = {}

  otpForm: FormGroup = this.fb.group({
    'otp': ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6), Validators.pattern("^[0-9]*$")]],
  })
  triggerOpt: boolean;

  constructor(
    private fb: FormBuilder, 
    private utilsService: UtilsService, 
    public activeModal: NgbActiveModal, 
    private authSrv: AuthenticationService) { }

  ngOnInit() {
    this.constant = USER_MGMT_DEFAULT_CONFIG_CNST.OTP_CHANGE_PASSWORD_CNST
    this.timer.startTimer();
  }
  ngOnDestroy() {
    clearInterval(this.interval);
  }

  //On click Resend button
  get formControl() {
    return this.otpForm.controls;
  }


  receiveMessage($event) {
    this.otpForm.disable()
    this.otpForm.reset();
    this.triggerOpt = true;
    this.displayErrorMessage = $event
  }

  getPostParams() {
    const postData = {
      'username': this.authSrv.getUserName()
    }
    return postData
  }

  //Calling API for Sending OTP
  async otpSendAPI() {
    this.timer.clearInterval(this.interval);
    this.displayErrorMessage = '';
    this.activespiner = true
    const postData = this.getPostParams();
    let response = await this.utilsService.makeOtpSendApiCall('OTP_SEND', postData)
    this.activespiner = false;
    if (!response) {
      this.displayErrorMessage = "Failed ..."
      return
    }
    this.otpForm.enable()
    this.triggerOpt = false;
    this.timer.startTimer();
  }
  getValidatePostParams() {
    const postData = {
      username: this.authSrv.getUserName(),
      otp: this.otpForm.value['otp'],
      newPassword: this.newpassword
    }
    return postData
  }
  //Submitting Data
  async handleValidation() {
    this.submitted = true;
    if (this.triggerOpt) {
      return;
    }
    this.displayErrorMessage = ""
    if (this.otpForm.invalid) {
      this.displayErrorMessage = this.triggerOpt ? 'You expired time , please resend Verification code again' : 'Please fill the fields'
      return
    }
    if (this.otpForm.valid && this.triggerOpt) {
      this.displayErrorMessage = 'You expired time , please resend Verification code again';
      return
    }
    let postdata = this.getValidatePostParams()
    let response = await this.utilsService.makeOtpValidateApiCall('RESET_PASSWORD', postdata)
    this.activespiner = false;
    if (!response) {
      this.displayErrorMessage = "Invalid Verification code"
      return
    }
    this.activeModal.close();
    // this.router.navigate( ['','authentication','login'])
  }
}
