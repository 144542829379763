import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { BehaviorSubject } from 'rxjs';
import { isNullOrUndefined } from 'util';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  userInfo: any;
  loggedIn: boolean = false;
  token: string;
  authState = new BehaviorSubject<boolean>(this.loggedIn);

  constructor(private keycloakSrv : KeycloakService) { 
    const userInfoStr = localStorage.getItem('userInfo');
    const token = localStorage.getItem('token');
    const userInfo = userInfoStr ? JSON.parse(userInfoStr) : null;
    this.setUserInfo(userInfo, token);
  }

  setUserInfo(userInfo: any, token: string) {
    this.userInfo = userInfo;
    this.token = token;

    localStorage.setItem('userInfo', JSON.stringify(userInfo));
    localStorage.setItem('token', token);
    
    this.loggedIn = !!userInfo && !!userInfo.userId && !!this.token;
    console.log("LoggedIn: ",this.loggedIn);
    this.authState.next(this.loggedIn);

    let isRefreshTokenPresent = localStorage.getItem('refreshToken');

    if (this.loggedIn && isNullOrUndefined(isRefreshTokenPresent)) {
      let refreshToken = this.keycloakSrv.getKeycloakInstance().refreshToken;      
      localStorage.setItem('refreshToken', refreshToken);
    } 
  }

  isLoggedIn(): boolean {
    return !!this.loggedIn;
  }

  isPromptToChangePswd ():boolean{
    return this.loggedIn ? this.userInfo.promptForChangePassword : false
  }

  getUserId(): string | null {
    return this.loggedIn ? this.userInfo.userId : null;
  }

  getToken(): string | null {
    return this.loggedIn ? localStorage.getItem('token') : null;
  }

  getRefreshToken() : string | null {
    return this.loggedIn ? localStorage.getItem('refreshToken') : null;
  }

  getUserName(){
    return this.loggedIn ? this.userInfo.username : null;
  }

  handleError(error){
    if(error.status == 0){
      return 'Server Unreachable';
    }
    else if(error.status == 401){
      return 'Incorrect login credentials i.e. user or password!'
    }
    else if(error. status == 403){
      return 'Forbidden'
    }
    else if(error.status == 404){
      return 'Not Found'
    }
    else if(error.status == 500){
      return 'Internal Server Error'
    } 
  }
}

