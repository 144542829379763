import { NgSelectModule } from '@ng-select/ng-select';
import { ApplicationsComponent } from './applications.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddApplicationComponent } from './components/add-application/add-application.component';
import { RouterModule } from '@angular/router';
import { ApplicationsListComponent } from './components/applications-list/applications-list.component';
import { ViewApplicationComponent } from './components/view-application/view-application.component';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TableModule } from 'src/app/lib';
import { FormsModule } from '@angular/forms';
import { AuthorizationModule } from '../../modules/authorization/authorization.module';
import { AssignResourceComponent } from './components/assign-resource/assign-resource.component';

const routes = [
  { path: '', pathMatch: "full", component: ApplicationsComponent },
  { path: 'assignResource', pathMatch: "full", component: AssignResourceComponent },
]


@NgModule({
  declarations: [ApplicationsComponent,
   AddApplicationComponent,
   ApplicationsListComponent,
  ViewApplicationComponent,
  AssignResourceComponent],
  imports: [
    CommonModule,
    NgbModalModule,
    FormsModule,
    RouterModule.forChild(routes),
    TableModule,
    NgSelectModule,
    AuthorizationModule,
    NgbModule
  ],
  exports:[
    ViewApplicationComponent
  ],
  entryComponents:[
    ViewApplicationComponent
  ]
})
export class ApplicationsModule { }
