import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { USER_MGMT_CNST } from 'src/app/constants/proj.cnst';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-add-application',
  templateUrl: './add-application.component.html',
  styleUrls: ['./add-application.component.scss']
})
export class AddApplicationComponent implements OnInit {
  URLList :any = []
  isEditApplication: boolean = false;
  @Input() modalData :any;
  actionType: any = 'Add New';
  constructor(public activeModal: NgbActiveModal
    ) { }

  ngOnInit() {
    this.isEditApplication = this.modalData && this.modalData.editAction ?  this.modalData.editAction : false;
    this.URLList = USER_MGMT_CNST.LIST_OF_URLS;
    if(this.isEditApplication){
      this.actionType = USER_MGMT_CNST.OPTIONS.EDIT;
    }
    if(!this.isEditApplication){
      this.modalData = [];
    }
  }


  onlyAlphabet(event: any) {
    const pattern = /[a-zA-Z ]/;

    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  onFormSubmit(form: NgForm) {
  }

}
