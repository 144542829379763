import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { USER_MGMT_CNST } from 'src/app/constants';
import { AppStateService, HttpService, UtilsService } from 'src/app/services';
import { EnvService } from 'src/app/services/env.service';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-assign-update-customer-applications',
  templateUrl: './assign-update-customer-applications.component.html',
  styleUrls: ['./assign-update-customer-applications.component.scss']
})
export class AssignUpdateCustomerApplicationsComponent implements OnInit {


  @Input() operationType : string;

  //* Map tracking whether each application is selected / unselected [true / false]
  applicationSelectedStatus: any = {};
  applicationSelectedStatusCopy : any = {};

  //* Map of application names to application id's
  applicationNameIdMap : any = {};

  //* Map of application names to resource action id's
  applicationNameResourceActionIdMap : any = {};

  applicationsList : any[] = [];
  applicationNames : string[] = [];
  applicationIds : string[] = [];

  isButtonEnabled : boolean = false;
  customerId : string = null;

  modalTitle : string = null;
  buttonText : string = null;

  constructor(
    public activeModal : NgbActiveModal,
    private httpSrv : HttpService,
    private utilSrv : UtilsService,
    private appStateSrv : AppStateService,
    private envService : EnvService) { }

  ngOnInit() {

    let { ALLOCATE_APPLICATIONS } = USER_MGMT_CNST.OPERATION_TYPES;

    this.modalTitle = this.operationType === ALLOCATE_APPLICATIONS.UPDATE_APPLICATIONS 
      ? ALLOCATE_APPLICATIONS.UPDATE_APPLICATIONS
      : ALLOCATE_APPLICATIONS.ASSIGN_APPLICATIONS;

    this.buttonText = this.modalTitle === ALLOCATE_APPLICATIONS.ASSIGN_APPLICATIONS ? 'Assign' : 'Update';

    this.customerId = this.appStateSrv.getCurrentCustomerId();

    this.fetchAllActiveApplications();
  }

  fetchAllActiveApplications() {

    let options = {
      'uriParams': {
        customerId : this.customerId
      }
    }

    if (!isNullOrUndefined(this.customerId)) {
      this.httpSrv
        .makeGetApiCall('GET_ALL_ACTIVE_APPLICATIONS', this.envService.baseUrl, options)
        .subscribe((applicationList: any) => {
          let { response } = applicationList;

          if (!isNullOrUndefined(response) && response.length > 0) {
            this.applicationsList = response;
          }

          if (this.applicationsList.length > 0) {
            this.applicationNames = this.applicationsList.map((item: any) => item.applicationName);
            this.applicationIds = this.applicationsList.map((item: any) => item.applicationId);
            this.initializeApplicationMaps();
          }

        }, err => {
          console.log("Error in fetching applications list ", err);
          this.utilSrv.showToastMessage("Error in fetching applications list", "err");
        })
    }
  }

  // * Set all application selected status to false initially
  // * Build a map of application names to application ids
  initializeApplicationMaps() {

    this.applicationNames.forEach((name : string, index: number) => {

      let applicationObj = this.applicationsList.filter((item : any) => item.applicationName === name)[0];

      if (!isNullOrUndefined(applicationObj)) {

        let { isApplicationAssigned, resourceActionIds } = applicationObj;

        this.applicationSelectedStatus[name] = isApplicationAssigned;

        this.applicationNameIdMap[name] = this.applicationNameIdMap[name]
          ? this.applicationNameIdMap[name]
          : this.applicationIds[index];

        this.applicationNameResourceActionIdMap[name] = this.applicationNameResourceActionIdMap[name] 
          ? this.applicationNameResourceActionIdMap[name]
          : resourceActionIds;
      }
    })

    this.applicationSelectedStatusCopy = {...this.applicationSelectedStatus};
  }

  toggleApplicationSelectedStatus(applicationName : string) {
    this.applicationSelectedStatus[applicationName] = !this.applicationSelectedStatus[applicationName];
    this.isButtonEnabled = this.enableOrDisableButton();
  }

  enableOrDisableButton(): boolean {

    for (let applicationName in this.applicationSelectedStatusCopy) {

      let existingStatus = this.applicationSelectedStatusCopy[applicationName];
      let currentStatus = this.applicationSelectedStatus[applicationName];

      if ((existingStatus === true && currentStatus === false) ||
        (existingStatus === false && currentStatus === true)) {
        return true;
      }
    }

    return false;
  }

  assignOrUpdateApplications() {
    let customerId = this.appStateSrv.getCurrentCustomerId();

    let { ALLOCATE_APPLICATIONS } = USER_MGMT_CNST.OPERATION_TYPES;

    let postData = {
      "customerId" : null,
      "assignedApplications" : [],
      "deletedApplications": [],
      "resourceActionIds": []
    }

    //* If assign applications was clicked to open the modal
    if (this.operationType === ALLOCATE_APPLICATIONS.ASSIGN_APPLICATIONS) {
      for (let applicationName in this.applicationSelectedStatus) {
        let applicationStatus = this.applicationSelectedStatus[applicationName];

        if (applicationStatus) {
          let applicationId = this.applicationNameIdMap[applicationName];
          postData.assignedApplications.push(applicationId);
        }
      }
    }
    //* Existing applications are updated
    else {
      for (let applicationName in this.applicationSelectedStatusCopy) {

        let existingStatus = this.applicationSelectedStatusCopy[applicationName];
        let currentStatus = this.applicationSelectedStatus[applicationName];

        let applicationId = this.applicationNameIdMap[applicationName];

        // Deleted Applications Array
        if (existingStatus === true && currentStatus === false) {
          postData.deletedApplications.push(applicationId);

          let resourceIdsForUnAssignedApp = this.applicationNameResourceActionIdMap[applicationName];
          postData.resourceActionIds.push(...resourceIdsForUnAssignedApp);

        } else if (existingStatus === false && currentStatus === true) {
          postData.assignedApplications.push(applicationId);
        }
      }
    }

    if (!isNullOrUndefined(customerId)) {
      postData.customerId = customerId;
    }

    // console.log("Post Data ", postData);

    this.httpSrv
      .makePostApiCall('ASSIGN_UPDATE_CUSTOMER_APPLICATIONS', postData, this.envService.baseUrl)
      .subscribe((response : any) => {
        // console.log('Response ', response);
        this.utilSrv.showToastMessage("Applications assigned/updated successfully ", "success");
        this.closeModal({status: true, customerId});
      }, err => {
        console.log("Error in assigning/updating applications ", err);
        this.utilSrv.showToastMessage("Error in assigning/updating applications", "error");
      })
  }

  closeModal(info : any = {}) {
    this.activeModal.close(info);
  }

}
