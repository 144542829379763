import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from '../enums';
import { isNullOrUndefined } from 'util';
import { IConfig } from '../interfaces/config';
import { IConfigFormat } from '../interfaces/config.format';


@Injectable()
export class InitService {

  private config: IConfig = {};
  count  = 0

  constructor(
    private http: HttpClient,
  ) { }

  getConfig(type: Config) {
    // return JSON.parse(JSON.stringify(this.config[type]));
    return this.deepClone(this.config[type]);
  }

  loadConfig(args: IConfigFormat) {
    return new Promise((resolve, reject) => {
      if (!isNullOrUndefined(args.path) && !isNullOrUndefined(args.configType)) {
        this.http.get(args.path).subscribe((res: any) => {
          this.config[args.configType] = res;
          resolve(res);
        }, (err) => {
          reject(err);
        });
      } else {
        throw new Error('Could not load Configuration');
      }
    });
  }
  isEmptyField(value: any) {
    if (isNullOrUndefined(value) || value === '') {
      return true;
    }
    return false;
  }
  deepClone(object): any {

    if (this.isEmptyField(object)) {
      return;
    }
    if (Array.isArray(object)) {
      return object.slice();
    }
    const cloneObj = (object.constructor() as any);
    const attributes = Object.keys(object);
    for (const attribute of attributes) {
      const property = object[attribute];

      if (typeof property === 'object') {
        cloneObj[attribute] = this.deepClone(property);
      } else {
        cloneObj[attribute] = property;
      }
    }
    return cloneObj;
  }
}


  // updateExistingLayers(layers) {
  //   this.existingLayers.next(layers);
  // }
