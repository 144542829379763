import { AppStateService } from 'src/app/services/app-state.service';
import { Component, OnInit, EventEmitter, Output, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { USER_MGMT_CNST, USER_MGMT_DEFAULT_CONFIG_CNST } from 'src/app/constants';
import { AuthenticationService } from '../../authentication/services/authentication.service';
import { Config, Path } from 'src/app/enums';
import { CommonService } from 'src/app/services/common.service';
import { Subscription } from 'rxjs';
import { UtilsService } from 'src/app/services';
import { isNullOrUndefined } from 'util';
import { EnvService } from 'src/app/services/env.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  @Input() path: any;
  @Output() onLogin = new EventEmitter<any>();

  loggedIn: boolean = false;
  userInfo: any = {};
  logoConfig: any;
  config: any;
  constant = {}
  sidebarState: boolean;

  redirectToOtherAppsOptions : any = USER_MGMT_CNST.REDIRECT_TO_OTHER_APPS;

  subscriptions : Subscription[] = [];

  constructor(
    private router: Router,
    private appState: AppStateService,
    private authSrv: AuthenticationService,
    private envService : EnvService,
    private cmnSrv: CommonService,
    private utilSrv : UtilsService) { }


  ngOnInit() {

    this.constant = USER_MGMT_DEFAULT_CONFIG_CNST.LOGO_DEFAULT_CNST;

    this.getConfig();
    this.fetchUrlDetails();

    const urlDetailsRef = this.appState.urlDetailsRef.subscribe(
      (state) => {
        this.fetchUrlDetails();
      })

    this.loggedIn = this.authSrv.isLoggedIn();
    this.userInfo = this.authSrv.userInfo;

    const authStateRef = this.authSrv.authState.subscribe(state => {
      console.log("Callback authState:: ", state);
      this.loggedIn = state;
      this.userInfo = this.authSrv.userInfo;
    });

    this.sidebarState = this.appState.getSidebarState();
    // console.log("sidebarState:: ",this.sidebarState);

    const sideBarStateRef = this.appState.showSidebarRef.subscribe(res => {
      this.sidebarState = res;
    })

    this.subscriptions.push(sideBarStateRef);

    this.subscriptions.push(urlDetailsRef);
    this.subscriptions.push(authStateRef);
    if(!this.loggedIn){
      this.login();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  getConfig() {
    this.logoConfig = this.cmnSrv.loadConfig({ path: Path.LOGO_CONFIG_PATH, configType: Config.LOGO_CONFIG })
  }

  login() {
    this.onLogin.emit();
  }

  logout() {
    this.utilSrv.logout();
  }

  fetchUrlDetails() {
    let value = this.appState.getUrlDetails();
    this.path = value.path;
    this.loggedIn = value.loginStatus;
  }

  changePassword() {
    this.router.navigate(['', 'authentication', 'change-password'])
  }

  toggleSidebar() {
    this.appState.setSidebarState(!this.sidebarState);
  }

  navigateToHome() {
    this.router.navigate(['', 'users']);
  }

  redirectToApp() {

    let redirectUrl = this.envService.appRedirectUrl;

    if (!isNullOrUndefined(redirectUrl)) {
      this.router.navigate([redirectUrl, { externalUrl: redirectUrl }])
    }
  }
}
