import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { HttpService, AppStateService, UtilsService } from 'src/app/services';
import { AddCustomerComponent } from './components/add-customer/add-customer.component';
import { USER_MGMT_CNST } from '../../constants/proj.cnst';
import { CommonService } from 'src/app/services/common.service';
import { UM_TABLE_CNST } from 'src/app/constants/table.cnst';
import { DataService } from 'src/app/services/data.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { EnvService } from 'src/app/services/env.service';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent implements OnInit, OnDestroy {

  subscriptions : Subscription[] = [];

  tableConfig: any = {}
  tableData: any = [];
  tableDataCopy : any = [];
  isEditPermission: boolean;

  customerNamesWithIds : any[] = [];
  searchResultResponse : any = {};
  selectedCustomers : string[] = [];
  isSearchResultLoading : boolean = false;

  constructor(
    private modal: NgbModal,
    private httpSrv: HttpService,
    private dataSrv: DataService,
    private utilSrv: UtilsService,
    private router: Router,
    private commonService: CommonService,
    private appStateSrv: AppStateService,
    private envService : EnvService,
    private datePipe: DatePipe) { }

  ngOnInit() {
    let permissions = this.appStateSrv.getUserPermissions();
    if(permissions && permissions['User Management'] && permissions['User Management']['resources'] && permissions['User Management']['resources']['ROLE'] && permissions['User Management']['resources']['ROLE']['actions']){
      this.isEditPermission = permissions['User Management']['resources']['ROLE']['actions'].indexOf('EDIT') !==-1 ? true: false;
    }
    this.setTableConfig();
    this.setTableData();

    const operationRef = this.appStateSrv.operationRef.subscribe((value : any) => {
      let { operation, postData } = value;

      operation === USER_MGMT_CNST.OPERATION_TYPES.CUSTOMER.DELETE_CUSTOMER 
        ? this.deleteCustomer(postData) 
        : this.updateCustomer(postData); 
    })

    this.subscriptions.push(operationRef);
  }

  addCustomerPage(){
    let { CUSTOMER_MODULE_OPERATIONS } = USER_MGMT_CNST;

    this.appStateSrv.setCustomerData({ operation: CUSTOMER_MODULE_OPERATIONS.ADD_CUSTOMER, info: null });

    this.router.navigate(['', 'customers', 'addCustomer'], {
      queryParams: {
        operation: CUSTOMER_MODULE_OPERATIONS.ADD_CUSTOMER
      }
    });
  }

  openAddCustomerModal() {
    const modalRef = this.modal.open(AddCustomerComponent,USER_MGMT_CNST.MODAL_OPTIONS);

    modalRef.result
    .then((result : any) => {
      let { operation, postData } = result;
      
      if (operation === USER_MGMT_CNST.OPERATION_TYPES.CUSTOMER.ADD_CUSTOMER) {
        this.addCustomer(postData);
      }
    })
    .catch((reason : any) => {
      console.log("Reason ", reason);
    })
  }

  addCustomer(customerInfo: any) {
    let { name, purchasedPlan } = customerInfo;

    let postData = { 
      meta : {
        customerName : name,
        purchasedPlan : purchasedPlan
      } 
    };

    this.httpSrv
     .makePostApiCall('ADD_CUSTOMER', postData, this.envService.baseUrl)
     .subscribe((customerResponse : any) => {
       if(customerResponse.result){
        this.utilSrv.showToastMessage("Added customer successfully ","success");
        this.appStateSrv.setCurrentCustomerId(customerResponse.response);
       }
     }, err => {
       console.log("Error while adding customer ", err);
     })
  }

  updateCustomer(customerInfo: any) {

    let { customerId } = customerInfo;
    let { CUSTOMER_MODULE_OPERATIONS } = USER_MGMT_CNST;

    this.appStateSrv.setCustomerData({
      operation: CUSTOMER_MODULE_OPERATIONS.UPDATE_CUSTOMER,
      info: customerInfo
    });

    if (!isNullOrUndefined(customerId)) {

      this.router.navigate(['', 'customers', 'addCustomer'], {
        queryParams: {
          operation: CUSTOMER_MODULE_OPERATIONS.UPDATE_CUSTOMER,
          customerId
        }
      });
    }
  }

  deleteCustomer(customerInfo: any) {
    let { customerId } = customerInfo;

    let options = { 
      'uriParams': { customerId }
    };

    this.httpSrv
      .makeDeleteApiCall('DELETE_CUSTOMER', this.envService.baseUrl, options)
      .subscribe((response: any) => {
        this.utilSrv.showToastMessage("Customer deleted successfully", "success");
        this.setTableData();
      }, err => {
        console.log("Error while deleting customer ", err);
        this.utilSrv.showToastMessage("Error while deleting customer", "error");
      })
  }

  setTableConfig() {
    this.tableConfig = this.commonService.setTableConfig(USER_MGMT_CNST.LIST_NAME_MAPPING.CUSTOMER_LIST);
  }

  setTableData() {
    this.httpSrv
      .makeGetApiCall('GET_CUSTOMERS_LIST', this.envService.baseUrl)
      .subscribe((response: any) => {
        let customerList = this.formatTableData(response);
        this.tableData = this.dataSrv.addOptionsToTableData(customerList, UM_TABLE_CNST.RESOURCES_LIST.customers);
        this.tableDataCopy = [...this.tableData];
      }, error => {
        this.utilSrv.showToastMessage("Failed to fetch customers list", "error");
      })
  }

  formatTableData(data){
     let customerKeys = Object.keys(data);
     let customerList = customerKeys.map(cust =>  {
       let customer = data[cust];
       customer['addedDate'] = this.datePipe.transform(new Date(customer['addedDate']), "dd MMM yyyy");
       return customer;
     })
     return customerList;
  }

  onCustomerNameEntered(event : any) {
    if (event.target.value.length > 3 && this.selectedCustomers.length === 0) {
      this.loadSearchResultsByCustomerName(event.target.value);
    }
  }

  loadSearchResultsByCustomerName(searchQuery : string) {

    let options = {
      queryParams : {
        query : searchQuery
      }
    }

    this.isSearchResultLoading = true;

    this.httpSrv
      .makeGetApiCall('SEARCH_CUSTOMERS_BY_NAME', this.envService.baseUrl, options)
      .subscribe((searchResults : any) => {
        this.isSearchResultLoading = false;
        
        if (!isNullOrUndefined(searchResults.response)) {
          this.searchResultResponse = searchResults.response;
          this.searchResultResponse = this.formatTableData(searchResults.response);
          this.customerNamesWithIds = this.dataSrv.formatCustomerNameSearchResponse(searchResults.response);
        }
      }, err => {
        this.isSearchResultLoading = false;
        this.utilSrv.showToastMessage("Error in fetching search results", "error");
        console.log("Error in fetching search results ", err);
      })
  }

  onCustomerSelected() {

    //* If clear icon is clicked or backspace is pressed to clear the current selection
    //* then reset the already fetched items for the search query
    if (this.selectedCustomers.length === 0) {
      this.customerNamesWithIds = [];
      this.tableData = [...this.tableDataCopy];
    }
    //* Filter table records according to the selected customer
    else {
      let filteredTableRecords = this.dataSrv
        .filterCustomerTableRecordsForCustomerName(this.searchResultResponse, this.selectedCustomers);

      filteredTableRecords = this.dataSrv.addOptionsToTableData(filteredTableRecords, UM_TABLE_CNST.RESOURCES_LIST.customers);
      this.tableData = filteredTableRecords;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription : Subscription) => subscription.unsubscribe());
  }

}
