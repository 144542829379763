import {UM_TABLE_CNST} from 'src/app/constants/table.cnst'

const LIST_DATA = {
    'userlist' : {
        'firstName' : 'John',
        'lastName' : 'Smith',
        'email' : 'John.Smith@gapac.com',
        'title':'Title',
        'activationdate':'26 Oct 2020',
        'lastlogin':'08 Mar 2021 9:30:58 AM',
        'status' : 'Active',
        'options' : Object.values(UM_TABLE_CNST.ES_OPTIONS)
    },
    'rolelist' : { 
        'rolename' : 'Role 1',
        'roledesc' : 'Description 1',
        'status' : 'Active',
        'options' : Object.values(UM_TABLE_CNST.ES_OPTIONS)
      },
      'profilelist' : { 
        'profilename' : 'Profile 1',
        'profiledesc' : 'Description 1',
        'status' : 'Active',
        'options' : Object.values(UM_TABLE_CNST.ES_OPTIONS)
      },
      'adminlist' : { 
        'administratorName' : 'Administrator 1',
        'administratorDesc' : 'Description 1',
        'status' : 'Active',
        'options' : Object.values(UM_TABLE_CNST.ES_OPTIONS)
      },
    'applicationlist' : {
        'name' : 'Application 1',
        'desc' : 'Description 1',
        'url' : 'URL 1',
        'status' : 'Active',
        'options' : Object.values(UM_TABLE_CNST.ES_OPTIONS)
      },
      'customerlist' : {
        'customername' : 'Customer 1',
        'desc' : 'Description 1',
        'status' : 'Active',
        'options' : Object.values(UM_TABLE_CNST.ES_OPTIONS)
      },
      'resourcelist': {
        'application' : 'Application 1',
        'resource' : 'Resource',
        'resourceurl' : 'Resource 1',
        'desc' : 'Description 1',
        'status' : 'Active',
        'options' : Object.values(UM_TABLE_CNST.ES_OPTIONS)
      },
      'actionlist' : {
        'action' : 'Action 1',
        'desc' : 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry',
        'status' : 'Active',
        'options' : Object.values(UM_TABLE_CNST.ES_OPTIONS)
      }

    
}


export const DATA_CNST = {
    LIST_DATA
};
