import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { isNullOrUndefined } from 'util';
import { ToastrService } from 'ngx-toastr';
import { USER_MGMT_CNST } from '../constants/proj.cnst';
import { Config } from '../enums';
import { InitService } from '../parsers/init.service';
import { KeycloakService } from 'keycloak-angular';
import { Router } from '@angular/router';
import { AppStateService } from '../services/app-state.service';
import { AuthenticationService } from '../modules/authentication/services/authentication.service';
import { EnvService } from './env.service';
// import { CookieService } from 'ngx-cookie-service';

/**
 * NOTE: . 
 * Use this service to write some common formatters which can be shared across projects..
 * Don't write chart/table formatters here.
 * 
 * EXAMPLE:
 *  - Generating a UUID.
 *  - Date Utils 
 *  etc..
 */
@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(
    private httpSrv: HttpService,
    private toastr: ToastrService,
    private intitSrv: InitService,
    private keyCloakSrv: KeycloakService,
    private appStateSrv: AppStateService,
    private authSrv: AuthenticationService,
    private envService : EnvService,
    // private cookie : CookieService,
    private router: Router
  ) { }

  async checkUser(userId: string): Promise<boolean> {
    let promise: Promise<boolean> = new Promise((resolve) => {

      if (!userId) resolve(false);

      this.httpSrv
        .makePostApiCall('CHECK_IF_DANA_USER', { userId }, this.envService.baseUrl)
        .subscribe(() => {
          resolve(true);
        }, err => {
          this.showToastMessage(err, 'error');

          // if (USER_MGMT_CNST.ACCESS_TYPE === USER_MGMT_CNST.ACCESS_TYPE_LIST.PROD) {
          //   this.oauthSrv.logOut();
          // }

          resolve(false);
        })
    })
    return promise;
  }

  showToastMessage(message: string, type: string, options?: any): void {
    let toastOptions: any = USER_MGMT_CNST.TOASTR_CNST;
    if (!isNullOrUndefined(options)) {
      toastOptions = { ...USER_MGMT_CNST.TOASTR_CNST, ...options.options };
    }
    this.toastr.clear();
    this.toastr[type](message, toastOptions.title, toastOptions);
  }


  handleSuccess(res) {
    let config = this.intitSrv.getConfig(Config.FORGOT_PASSWD_CONFIG)
    let message = res.message ? res.message : config.toasterMessages.otp.success;
    this.showToastMessage(message, 'success');
    return true
  }

  makeOtpSendApiCall(api, postParams) {
    return new Promise((resolve, reject) => {
      this.httpSrv.makePostApiCall(api, postParams, this.envService.baseUrl)
        .subscribe(res => {
          if (res.result) {
            return resolve(this.handleSuccess(res))
          }
          else {
            return resolve(false)
          }
        }
          , error => {
            return resolve(false);
          })
    })
  }

  handleValidateSuccess(res) {
    let config = this.intitSrv.getConfig(Config.FORGOT_PASSWD_CONFIG)
    let message = res.message ? res.message : config.toasterMessages.changePassword.success;
    this.showToastMessage(message, 'success');
    return true
  }

  makeOtpValidateApiCall(api, postParams) {
    return new Promise((resolve, reject) => {
      this.httpSrv.makePostApiCall(api, postParams, this.envService.baseUrl)
        .subscribe(res => {
          if (res.result) {
            return resolve(this.handleValidateSuccess(res))
          }
          else {
            return resolve(false)
          }
        }
          , error => {
            return resolve(false);
          })
    })
  }
  
  formatViewUsersData(data) {
    let sorted = [];

    if (data && data['viewUser']) {
      //* order of each attribute of user
      let listOrder = USER_MGMT_CNST.ORDER_FOR_VIEW_USERS;
      //* add order and key to each object
      Object.keys(listOrder).forEach(item => {

        data['viewUser'][item]['order'] = listOrder[item];
        data['viewUser'][item]['key'] = item;
        //* change class for status alone
        if (item === 'status') {
          data['viewUser'][item]['class'] = 'col-12 mb-3';
        }

      })
      //* convert object into array of objects
      const arrayOfObj = Object.entries(data['viewUser']).map((e) => (e[1]));
      //* sort array based on order
      sorted = arrayOfObj.sort((a, b) => {
        return (a['order'] - b['order']);
      })

    }

    //* return formatted data

    return sorted;

  }

  setUserPermissionsInLocalStorage(userPermissions: any, userMetaInfo : any) {
    // if (this.envService.headerAppName === 'KredosAI') {
    if (this.envService.headerAppName === 'Portal'||this.envService.headerAppName === 'One Portal') {

      let permissionInLocalStorage = localStorage.getItem('userPermissions');
      let metaInfoInLocalStorage = localStorage.getItem('metaInfo');

      if (this.isObjectNotNullAndEmpty(userPermissions) && isNullOrUndefined(permissionInLocalStorage)) {
        localStorage.setItem('userPermissions', JSON.stringify(userPermissions));

        let userPermissionsCopy = localStorage.getItem("userPermissions");
        // if (JSON.parse(userPermissions)) {
        //   // this.userPermissionsList.push(JSON.parse(this.userPermissions).permissions.TNWRIMS.roleName);
        // }

        // let userId = JSON.parse(userPermissions).meta.userId;
        console.log("userPermissionsCopy", userPermissionsCopy);
        console.log("userMetaInfo", userMetaInfo);
        console.log("userPermissionsCopy", typeof userMetaInfo, typeof userPermissionsCopy);
        console.log("JSON.parse(userPermissionsCopy)", JSON.parse(userPermissionsCopy));
        console.log("JSON.parse(userPermissionsCopy).meta.userId:::", JSON.parse(userPermissionsCopy).meta.userId);

        let userDetails = {};
        userDetails['loggedUserData'] = {
          "userProfile": {
            "userId": userMetaInfo.userId ? userMetaInfo.userId : null,
            "userName": userMetaInfo.username ? userMetaInfo.username : null
          }
        };

        console.log("userDetails:::", userDetails);
        // this.cookie.set('userdetails', JSON.stringify(userDetails));

        // Setting the cookie with additional security options
        const dateNow = new Date();
        dateNow.setDate(dateNow.getDate() + 365); // Set expiration to 365 days
        localStorage.setItem("userdetails",JSON.stringify(userDetails))
        // Setting the cookie with secure and sameSite options

        // Setting the cookie with secure and sameSite options
        // this.cookie.set(
        //     'userdetails',            // Cookie name
        //     JSON.stringify(userDetails), // Cookie value
        //     dateNow,                  // Expiration date
        //     '/',                      // Path
        //     undefined,                // Domain (optional, can be omitted)
        //     true,                     // Secure flag
        //     'Strict'                  // SameSite attribute
        // );
      }

      if (this.isObjectNotNullAndEmpty(userMetaInfo) && isNullOrUndefined(metaInfoInLocalStorage)) {
        localStorage.setItem('metaInfo', JSON.stringify(userMetaInfo));
      }
    }
  }

  logout() {
    this.keyCloakSrv.logout(this.envService.keyCloakUrls.logoutUrl);
    this.authSrv.setUserInfo(null, null);
    this.appStateSrv.setIsPredefinedResource(false);

    localStorage.clear();

    this.appStateSrv.setPermissionsLoaded(false);
    this.appStateSrv.setInProcess(false);
    this.appStateSrv.setUserMetaInfo({});
    this.appStateSrv.setUserPermissionsRef({});

    this.router.navigate(USER_MGMT_CNST.COMMON_ROUTE_MAPPING.LOGIN);
  }

  isEmailValid(emailPattern: any, inputStr: string): boolean {
    if (!isNullOrUndefined(inputStr)) {
      return emailPattern.test(inputStr) ? true : false;
    } else {
      return false;
    }
  }

  isObjectNotNullAndEmpty(inputObj : any) : boolean {
    return !isNullOrUndefined(inputObj) && Object.keys(inputObj).length > 0;
  }

  buildMobileNumberWithCountryCode(countryCode: string, mobileNo : string) {
    if (!isNullOrUndefined(countryCode) && !isNullOrUndefined(mobileNo)) {
      return `${countryCode}-${mobileNo}`;
    }
  }

  splitByDelimiter(value : string, delimiter : string) {
    if (!isNullOrUndefined(value)) {
      return value.split(delimiter);
    }
  }
}
