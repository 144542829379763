import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Config } from '../enums/config.enum';
import { Path } from '../enums/path.enum';

/**
 * Keep all the hardcoded values here.
 * Don't keep the constants here which will change depends on environment use environment files for that.
 */
export const ptypeCtye={
  "REGION":"CIRCLE",
   "CIRCLE":"DIVISION",
   'DIVISION':'SUB DIVISION',
   "SUB DIVISION":'SECTION',
   "STATE":"DISTRICT",
   "DISTRICT":"BLOCK"


} 
export const UsersMapping={
  SUPER_ADMIN: "No level",
  ADMIN : "no level",
  CE :['region'],
  SE : ['region','circle'],
  EE :['region','circle','division'],
  AEE : ['region','circle','division','subDivision'],
  AE : ['region','circle','division','subDivision','section'],
  IWS:['state'],
  Enforcement:['state','district'],
  Collector:['state','district'],
  Revenue_officer:['state','district','block'],
  WRD_officer :['state','district','block']

}
export const UsersMappingArea={
  SUPER_ADMIN: "No level",
  ADMIN : "no level",
  CE :"REGION",
  SE : "CIRCLE",
  EE :"DIVISION",
  AEE : "SUB DIVISION",
  AE : "SECTION",
  IWS:"STATE",
  Enforcement:"DISTRICT",
  Collector:"DISTRICT",
  Revenue_officer:"BLOCK",
  WRD_officer :"BLOCK",

}
// api endpoints mapping.
const apiMapping = {
  GET_USER_LIST: 'users',
  SEARCH_USERS: 'users/search',
  ADD_USER: 'add-user',
  ADD_CUSTOMER: 'add-customer',
  UPDATE_CUSTOMER: 'update-customer',
  DASHBOARDS:'api/locations/allLocationList/STATE',
  LIST_OF_PROFILES: 'roles',
  LIST_OF_PROFILES_FOR_USER: 'roles/user',
  ADD_PROFILE: 'role',
  DELETE_PROFILES: 'role',
  GET_RESOURCES_LIST: 'resources',
  GET_APPLICATIONS_LIST: 'applications',
  GET_CUSTOMERS_LIST: 'customers',
  DELETE_APPLICATION: "application/REPLACE_APP_ID",
  APP_LIST: 'applications',
  ROLE_RSC_LIST: 'role/REPLACE_ROLE_ID/resources',
  APP_RSC_LIST: 'application/REPLACE_APP_ID/permissions',
  GET_USER_PERMISSIONS: 'user-permissions',
  UPDATE_PROFILE: 'role/REPLACE_ROLE_ID',
  DELETE_USER: 'user',
  LOGIN: 'login',
  GET_SCOPE_TYPES: '/types',
  GET_SCOPE_NAMES_UNDER_TYPE: '/types/REPLACE_SCOPE_TYPE/names',
  GET_SCOPE_VALUES_OF_TYPE: '/types/REPLACE_SCOPE_TYPE/names/REPLACE_SCOPE_NAME/values',
  UPDATE_USER: 'update-user',
  CHANGE_PASSWORD: 'change-password',
  OTP_SEND: 'otp/send',
  RESET_PASSWORD: 'reset-password',
  KEYCLOAK_RESET_PASSWORD : 'reset-user-password',
  GET_CSRF_TOKEN : 'generate-csrf-token',
  KEYCLOAK_DELETE_USER : 'delete-user',

  FETCH_USER_INFO_BY_ID : 'userInfo',
  GET_ROLE_INFO_BY_ID : 'role-details',

  GET_ALL_ACTIVE_APPLICATIONS : 'applicationsList',
  ASSIGN_UPDATE_CUSTOMER_APPLICATIONS : 'assign-update-applications',

  FETCH_ALL_ASSIGNED_RESOURCES_FOR_ASSIGNED_APPLICATIONS : 'resourceTypes/assignedResources/assignedApps',
  FETCH_ALL_RESOURCES_FOR_ASSIGNED_APPLICATIONS : 'resourceTypes/resources/assignedApps',
  FETCH_ALL_RESOURCES_UNDER_RESOURCE_TYPE : 'resourceTypes/resources',
  FETCH_RESOURCES_BY_APPLICATION_ID : 'resources/resourceTypes',
  FETCH_ALL_RESOURCES_BY_RESOURCE_TYPE_UNDER_APPLICATION : 'resourceTypes/resources',
  FETCH_ASSIGNED_RESOURCES_FOR_CUSTOMER : 'resourceTypes/resources/assignedResources',

  FETCH_ASSIGNED_APPLICATION_LIST_FOR_CUSTOMER : 'assignedApplications',
  FETCH_CUSTOMER_ADMIN_INFO_BY_CUSTOMER_ID : 'customerAdminInfo',
  FETCH_ASSIGNED_APPLICATION_FOR_CUSTOMER : 'applications',

  ASSIGN_OR_UPDATE_RESOURCES : 'resourceTypes/resources/assignOrUpdate',
  ASSIGN_RESOURCE_TO_CUSTOMERS: "customers/assign-resource",
  ASSIGN_RESOURCE_TO_ROLES : "roles/assign-resource",
  UNASSIGN_APPLICATION_FOR_CUSTOMER : "unassign-application",

  DELETE_CUSTOMER: "delete-customer",
  SEARCH_CUSTOMERS_BY_NAME : "customers/name-search",
  FETCH_CUSTOMER_ATTRIBUTES : "get-customer"
}

const navigationMapping = {
  FORGOT_PASSWORD: 'forgot-password',
  LOGIN: 'login',
  CHANGE_PASSWORD: 'change-password'
}

const preDefinedResources = ["USER", "APPLICATION", "RESOURCE", "ACTION", "ROLE", "CUSTOMER", "LOGIN"];

const keycloakErrorMessages = {
  incomingMobileNoError : 'Duplicate entry',
  displayedmobileNoErrorMsg : 'The Mobile Number already exists'
}

const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

const operationTypes = {
  CUSTOMER: {
    ADD_CUSTOMER: 'add_customer',
    EDIT_CUSTOMER: 'edit_customer',
    DELETE_CUSTOMER: 'delete_customer'
  },
  PROFILE: {
    ADD_PROFILE: 'add_profile',
    EDIT_PROFILE: 'edit_profile',
    DELETE_PROFILE: 'delete_profile'
  },
  USER: {
    ADD_USER: 'add_user',
    EDIT_USER: 'edit_user',
    DELETE_USER: 'delete_user',
  },
  ALLOCATE_APPLICATIONS : {
    ASSIGN_APPLICATIONS : 'Assign Applications',
    UPDATE_APPLICATIONS : 'Update Applications'
  },
  ALLOCATE_RESOURCES : {
    ASSIGN_RESOURCES : 'Assign Resources',
    UPDATE_RESOURCES : 'Update Resources',
    ASSIGN_RESOURCES_TITLE : 'Assign Resources For Applications',
    UPDATE_RESOURCES_TITLE : 'Update Resources For Applications'
  }
}

const infiniteScrollOptions = {
  SCROLL_LIMIT : 2,
  SCROLL_DISTANCE: 2,
  THROTTLE: 50,
  SCROLL_ON_WINDOW: false,
  INFINITE_SCROLL_DISABLED: false,
  INFINITE_SCROLL_LIMIT : 20
}

const redirectToOtherAppsOptions = {
  appName : 'Kredos AI',
  appUrl : 'kredos-ai'
}

const OPTIONS = {
  VIEW: 'View',
  EDIT: 'Edit',
  DELETE: 'Delete'
};

const CONTENT_OPTIONS = {
  'customers': 'Customer',
  'roles': 'Role',
  'profiles': 'Role',
  'administrators': 'Administrator',
  'users': 'User',
  'applications': 'Application',
  'resources': 'Resource',
  'actions': 'Action'
}

const listOfUrls = [
  'URL1',
  'URL2',
  'URL3',
  'URL4',
  'URL5',
]

const resourceTypes = ["APIs", "URLs", "Dashboards", "Widgets"];

const resourceTypesMap = {
  "API": "APIs",
  "URL": "URLs",
  "DASHBOARD": "Dashboards",
  "WIDGET": "Widgets"
}

const applicationURLS = [
  { label: 'Application1', value: 'Application1', isChecked: false },
  { label: 'Application2', value: 'Application2', isChecked: false },
  { label: 'Application3', value: 'Application3', isChecked: false },
  { label: 'Application4', value: 'Application4', isChecked: false },
  { label: 'Application5', value: 'Application5', isChecked: false }
]
const CUSTOMERS = [
  { label: 'Customer1', value: 'customer1', isChecked: false },
  { label: 'Customer2', value: 'customer2', isChecked: false },
  { label: 'Customer3', value: 'customer3', isChecked: false },
  { label: 'Customer4', value: 'customer4', isChecked: false },
  { label: 'Customer5', value: 'customer5', isChecked: false },
  { label: 'Customer6', value: 'customer6', isChecked: false },
  { label: 'Customer7', value: 'customer7', isChecked: false },
  { label: 'Customer8', value: 'customer8', isChecked: false }

];

const PROFILES = [
  { label: 'Profile1', value: 'profile1', isChecked: false },
  { label: 'Profile2', value: 'profile2', isChecked: false },
  { label: 'Profile3', value: 'profile3', isChecked: false },
  { label: 'Profile4', value: 'profile4', isChecked: false },
  { label: 'Profile5', value: 'profile5', isChecked: false },
  { label: 'Profile6', value: 'profile6', isChecked: false },
  { label: 'Profile7', value: 'profile7', isChecked: false },
  { label: 'Profile8', value: 'profile8', isChecked: false }

]

const resourceURLS = [
  'Resource1',
  'Resource2',
  'Resource3',
  'Resource4',
  'Resource5',
]

const modalStatus = {
  ACTIVE: 'Active'
}

const ROLES = [{ label: 'Read', value: 'Read', isChecked: false },
{ label: 'Write', value: 'Write', isChecked: false },
{ label: 'Approve', value: 'Approve', isChecked: false }];

const modalOptions: NgbModalOptions = {
  windowClass: 'modal-form',
  centered: true,
  size: 'md'
}

const modalOptionsSmall: NgbModalOptions = {
  windowClass: 'modal-form',
  centered: true,
  size: 'sm'
}

const editModalOptions: NgbModalOptions = {
  windowClass: 'modal-form',
  centered: true,
  size: 'lg'
}

const otpModalOptions: NgbModalOptions = {
  backdrop: 'static',
  windowClass: 'backgroundTransparent my-class'
}
const deletePopupOptions: NgbModalOptions = {
  windowClass: 'alert-popup',
  centered: true,
  size: 'lg'
}

const ACCESS_TYPE_LIST = {
  LOCAL: "local",
  PROD: "prod"
}

const ROLES_CNST = {
  SU: 'SuperUser',
  SM: 'SeniorManager',
}

const COMMON_ROUTE_MAPPING = {
  LOGIN: ["", "authentication", 'login'],
  SIGNIN: ["", "authentication", "signIn"],
  USERPAGE: ["", "users"]
}

const DEFAULT_USER = {
  firstName: 'Madhu',
  userPrincipalName: 'madhu123@gpac.com'
}


const TOASTR_CNST = {
  closeButton: true,
  timeOut: 3000,
  extendedTimeOut: 2000
};

const LANDING_PAGE = {
  'SuperUser': "users",
}

const SOME_COMMON_LABELS = {
  LOGIN_PATH: "/authentication/login",
}

const LIST_NAME_MAPPING = {
  USER_LIST: 'userlist',
  ROLE_LIST: 'rolelist',
  PROFILE_LIST: 'profilelist',
  ADMIN_LIST: 'adminlist',
  CUSTOMER_LIST: 'customerlist',
  RESOURCE_LIST: ' resourcelist',
  ACTION_LIST: 'actionlist',
  PROFILE_CUSTOMER_LIST: 'profilelistcustomer',
  USER_CUSTOMER_LIST: 'usercustomerlist'

}

const STATUS_TYPE_LIST = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive'
}

const customerAdminRoleName = 'Customer Administrator';
const customerServiceExecutive = 'Customer Service Executive';

const configLabelNames = {
  EDIT_USER : 'editUser'
}

const KEYCLOAK_BASE_URL = 'http://localhost:8080/auth';
const KEYCLOAK_REDIRECT_URL = 'http://localhost:4200/users';
const KEYCLOAK_LOGOUT_URL = 'http://localhost:4200/authentication/login';

const keyCloakOptions = {
  CLIENT_ID : 'tnswipClient',
  REALM: ' tnswipRealm',
  URL : KEYCLOAK_BASE_URL,
  REDIRECT_URL : KEYCLOAK_REDIRECT_URL,
  LOGOUT_URL : KEYCLOAK_LOGOUT_URL
}

const popOverOptions = {
  HEADER_THRESHOLD : 9,
  STANDARD_SIZE : 4
}

export const ALL_CONFIGS = [
  {
    path: Path.LOGIN_CONFIG_PATH,
    configType: Config.LOGIN_CONFIG
  },
  {
    path: Path.LOGO_CONFIG_PATH,
    configType: Config.LOGO_CONFIG
  },
  {
    path: Path.ADD_USER_CONFIG_PATH,
    configType: Config.ADD_USER_CONFIG
  },
  {
    path: Path.DELETE_USER_CONFIG_PATH,
    configType: Config.DELETE_USER_CONFIG
  },
  {
    path: Path.SIDEBAR_CONFIG_PATH,
    configType: Config.SIDEBAR_CONFIG
  },
  {
    path: Path.VIEW_USER_CONFIG_PATH,
    configType: Config.VIEW_USER_CONFIG
  },
  {
    path: Path.EDIT_USER_CONFIG_PATH,
    configType: Config.EDIT_USER_CONFIG
  },
  {
    path: Path.CHANGE_PASSWD_CONFIG_PATH,
    configType: Config.CHANGE_PASSWD_CONFIG
  },
  {
    path: Path.OTP_CHANGE_PASSWD_CONFIG_PATH,
    configType: Config.OTP_CHANGE_PASSWD_CONFIG
  },
  {
    path: Path.OTP_FORGOT_PASSWD_CONFIG_PATH,
    configType: Config.OTP_FORGOT_PASSWD_CONFIG
  },
  {
    path: Path.FORGOT_PASSWD_CONFIG_PATH,
    configType: Config.FORGOT_PASSWD_CONFIG
  },
  {
    path: Path.LIST_USERS_CONFIG_PATH,
    configType: Config.LIST_USERS_CONFIG
  },
  {
    path: Path.LIST_APPLICATIONS_CONFIG_PATH,
    configType: Config.LIST_APPLICATIONS_CONFIG
  },
  {
    path: Path.LIST_RESOURCES_CONFIG_PATH,
    configType: Config.LIST_RESOURCES_CONFIG
  },
  {
    path: Path.LIST_ROLES_CONFIG_PATH,
    configType: Config.LIST_ROLES_CONFIG
  }

]

const validationTimer = 20

const currentApplication = 'User Management';

const superAdmin = 'Super Administrator';

const domainsRequiringUserId = ['get_user_permissions'];

const ORDER_FOR_VIEW_USERS = {
  "activationDate" : 5,
  "email" : 3,
  "firstName" : 1,
  "lastName" : 2,
  "lastLogin" : 6,
  "status" : 7,
  "username" : 8,
  "mobileNumber" : 9,
  "title" : 4
}

const EDIT_USER_ADD_ROLE= {
  "d9168e72-9bc7-4627-854e-dec9e6952fb1": {
      "scope": [
          {
              "scopeType": "NON_HIERARCHY",
              "scopeName": "Application",
              "scopeValue": []
          }
      ]
  }
}
const EE_USER_ADD_ROLE= {
  "cf3e7422-24af-4c7f-91dc-80088f7d573e": {
      "scope": [
          {
              "scopeType": "NON_HIERARCHY",
              "scopeName": "Application",
              "scopeValue": []
          }
      ]
  }
}

const errorMessages = {
  'CUST_ADMIN_GENERAL' : 'Error occured while creating customer admin'
}

const customerModuleOperations = {
  'ADD_CUSTOMER' : 'new',
  'UPDATE_CUSTOMER': 'edit',
  'VIEW_CUSTOMER': 'view'
}

const conflictString = "Conflict:";

export const USER_MGMT_CNST = {
  BASE_URL: "http://localhost:8081/",
  REDIRECT_URI: "https://tnswip.vassarlabs.com/",
  PRODUCTION_URL:'https://tnswip.vassarlabs.com/',
  EMAIL_PATTERN: "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$",
  API_MAPPING: apiMapping,
  NAVIGATION_MAPPING: navigationMapping,
  INFINITE_SCROLL_OPTIONS: infiniteScrollOptions,
  OPTIONS: OPTIONS,
  MODAL_OPTIONS: modalOptions,
  SMALL_MODAL_OPTIONS: modalOptionsSmall,
  LIST_OF_URLS: listOfUrls,
  APPLICATION_URLS: applicationURLS,
  RESOURCE_URLS: resourceURLS,
  MODAL_STATUS: modalStatus,
  ACCESS_TYPE: ACCESS_TYPE_LIST.LOCAL,//(local or prod)
  ACCESS_ROLE: ROLES_CNST.SU,
  EDIT_MODAL_OPTIONS: editModalOptions,
  DELETE_POPUP_OPTIONS: deletePopupOptions,
  ACCESS_TYPE_LIST,
  COMMON_ROUTE_MAPPING,
  DEFAULT_USER,
  TOASTR_CNST,
  LANDING_PAGE,
  SOME_COMMON_LABELS,
  ROLES_CNST,
  ROLES,
  CUSTOMERS,
  CONTENT_OPTIONS,
  LIST_NAME_MAPPING,
  OPERATION_TYPES: operationTypes,
  PROFILES,
  STATUS_TYPE_LIST,
  CURRENT_APPLICATION: currentApplication,
  SCOPE_BASE_URL: 'http://localhost:5000/api/v1/scope/',
  KEYCLOAK_ERROR_MSGS : keycloakErrorMessages,
  DOMAINS_NEEDING_USER_ID: domainsRequiringUserId,
  VALIDATION_CONSTANT: validationTimer,
  OTP_MODAL_OPTIONS: otpModalOptions,
  KEYCLOAK_OPTIONS : keyCloakOptions,
  ORDER_FOR_VIEW_USERS,
  SUPER_ADMIN: superAdmin,
  CUSTOMER_ADMIN_ROLE : customerAdminRoleName,
  CUSTOMER_SERVICE_EXECUTIVE : customerServiceExecutive,
  CONFIG_LABELS : configLabelNames,
  EDIT_USER_ADD_ROLE,
  EE_USER_ADD_ROLE,
  PREDEFINED_RESOURCES : preDefinedResources,
  RESOURCE_TYPES : resourceTypes,
  RESOURCE_TYPES_MAP : resourceTypesMap,
  ERROR_MESSAGES : errorMessages,
  CONFLICT_STRING : conflictString,
  POPOVER_OPTIONS : popOverOptions,
  REDIRECT_TO_OTHER_APPS : redirectToOtherAppsOptions,
  MONTH_NAMES : monthNames,
  CUSTOMER_MODULE_OPERATIONS : customerModuleOperations,
};

const loginDefaultConfig = {
  title: "LOGIN",
  fieldsLabel: {
    usrname: 'UserName',
    pswd: 'Password'
  },
  validationMsgs: {
    username: 'Username is Required',
    pswd: 'Password is Required'
  },
  placeHolders: {
    userName: "Enter Username",
    password: "Enter Password"
  },
  forgotPswd: "Forgot Password?",
  reset: {
    label: "Reset",
    show: true
  },
  submit: {
    label: "Login"
  }
}

const logoDefaultConfig = {
  image: {
    src:"assets/imgs/new.png"
  },
  loginText: {
    text: "Login"
  }
}
const forgotPswdDefaultConfig = {
  title: "Forgot Password",
  label: {
    userName: "User Name"
  },
  cancelBtn: {
    label: "Cancel"
  },
  submitBtn: {
    label: "Request Verification code"
  },
  validationMessages: {
    userName: "Username is required"
  },
  placeHolder: {
    username: "Enter UserName"
  }

}
const otpForgotPswdDefaultConfig = {
  title: "Verification code Validation",
  otpValidationForm: {
    otp: "Verification Code",
    newPassword: "Create New Password",
    confirmPassword: "Confirm New Password"
  },
  validatingMsgs: {
    otp: "Please enter valid Verification code",
    newPswd: "Password is required",
    numberOnly: "Number Only",
    confirmNewPswd: "Confirm Password is required",
    mustMatch: "Password Must be match",
    mustValid: "Password must be at least 6 characters"
  },
  placeHolders: {
    otp: "Enter Verification Code",
    newPswd: "Enter New Password",
    confirmPswd: "Confirm New Password"
  },
  backBtn: {
    label: "Back"
  },
  submitBtn: {
    label: "Submit"
  },
  resendBtn: {
    label: "Resend?"
  }
}

export const changePswdDefaultConfig = {
  title: "Change Password",
  changePassword: {
    oldPassword: "Old Password",
    newPassword: "New Password",
    confirmPassword: "Confirm New Password"
  },

  validatingMsgs: {
    oldPswd: "Password is required",
    newPswd: "Password is required",
    mustValid: "Password must be at least 6 characters",
    confirmNewPswd: "Confirm Password is required",
    mustMatch: "Password Must be match"
  },
  placeHolders: {
    oldPswd: "Enter Your Old Password",
    newPswd: "Enter New Password",
    confirmNewPswd: "Confirm Password"
  },
  resetBtn: {
    label: "Reset"
  },
  submitBtn: {
    label: "Submit"
  }
}

export const otpChangePswdDefaultConfig = {
  title: "Verification code Validation",
  otpValForChangePswd: {
    label: "Enter OTP"
  },
  validateMsg: {
    otp: "Please enter valid Verification Code",
    numberOnly: "Number Only"
  },
  placeHolder: {
    otp: "Enter OTP"
  },
  resend: {
    label: "Resend?"
  },
  backBtn: {
    label: "Back"
  },
  submitBtn: {
    label: "Submit"
  }
}

const addUserDefaultConfig = {

  title: "Add New User",
  addUser: {
    firstName: {
      title: "First Name",
      class: "col-12 col-md-6 form-group",
      inputType: "text"

    },
    lastName: {
      title: "Last Name",
      class: "col-12 col-md-6 form-group",
      inputType: "text"

    },
    username: {
      title: "User Name",
      class: "col-12 col-md-12 form-group",
      inputType: "text"

    },
    email: {
      title: "Email",
      class: "col-12 form-group",
      inputType: "email"

    },
    mobileNo: {
      title: "Mobile Number",
      class: "col-12 form-group",
      inputType: "text"
    },
    cancelBtn: {
      title: "Cancel"
    },
    addBtn: {
      title: "Add"
    }
  },
  placeHolder: {
    firstName: {
      label: "Enter First Name"
    },
    lastName: {
      label: "Enter Last Name"
    },
    userName: {
      label: "Enter User Name"
    },
    email: {
      label: "Email"
    },
    mobileNo: {
      label: "Enter mobile No"
    }
  }
}

export const USER_MGMT_DEFAULT_CONFIG_CNST = {
  LOGIN_DEFAULT_CNST: loginDefaultConfig,
  LOGO_DEFAULT_CNST: logoDefaultConfig,
  FORGOT_PSWD_CONST: forgotPswdDefaultConfig,
  OTP_FORGOT_PSWD_CONST: otpForgotPswdDefaultConfig,
  CHANGE_PSWD_CONST: changePswdDefaultConfig,
  OTP_CHANGE_PASSWORD_CNST: otpChangePswdDefaultConfig,
  ADD_USER_CONST: addUserDefaultConfig
}
export const LOCATION_WISE_DESIGNATION_LIST={
  "Region":[{"key":"CE","value":"CE"},
    {"key":"SE","value":"SE"},
    {"key":"EE","value":"EE"},
    {"key":"AEE","value":"AEE"},
    {"key":"AE","value":"AE"},],
  "State":[{"key":"IWS","value":"IWS"},
    {"key":"Enforcement","value":"Enforcement"},
    {"key":"Collector","value":"Collector"},
    {"key":"Revenue officer","value":"Revenue officer"},
    {"key":"WRD officer","value":"WRD officer"},],


}
