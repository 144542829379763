import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { USER_MGMT_CNST } from 'src/app/constants';

@Component({
  selector: 'app-add-customer',
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.scss']
})
export class AddCustomerComponent implements OnInit {
  @Input() modalData: any;

  isEditAction : boolean = false;
  actionType : any = 'Add New';
  emailPattern = USER_MGMT_CNST.EMAIL_PATTERN;

  constructor(
    public activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.isEditAction = this.modalData && this.modalData.editAction ?  this.modalData.editAction : false;

    if(this.isEditAction){
      this.actionType = USER_MGMT_CNST.OPTIONS.EDIT;
    }

    if(!this.isEditAction){
      this.modalData = [];
    }
  }


  //TODO: Check for other ways of validating fields which can have
  //only alphabets, this is not "Angular" way of doing
  onlyAlphabet(event: any) {
    const pattern = /[a-zA-Z0-9\s]/;

    let inputChar = String.fromCharCode(event.charCode);
    
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  onFormSubmit(form: NgForm) {
    let { OPERATION_TYPES } = USER_MGMT_CNST;
    let details = {
      operation: this.isEditAction 
        ? OPERATION_TYPES.CUSTOMER.EDIT_CUSTOMER 
        : OPERATION_TYPES.CUSTOMER.ADD_CUSTOMER , 
      postData : form.value};

      if(this.isEditAction){
         details.postData['customerId'] = this.modalData['customerId'];
      }

    this.activeModal.close(details);

    form.reset();
  }
}
