import { Component, OnDestroy, OnInit } from '@angular/core';
import { USER_MGMT_CNST } from 'src/app/constants';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddCustomerAdminComponent } from '../add-customer-admin/add-customer-admin.component';
import { Subscription } from 'rxjs';
import { HttpService, UtilsService, AppStateService } from 'src/app/services';
import { isNullOrUndefined } from 'util';
import { AssignUpdateCustomerApplicationsComponent } from 'src/app/pages/popups/assign-update-customer-applications/assign-update-customer-applications.component';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DeleteApplicationWithResourcesComponent } from 'src/app/pages/popups/delete-application-with-resources/delete-application-with-resources.component';
import { EnvService } from 'src/app/services/env.service';
import { DropDownConfig } from 'src/app/interfaces/dropdown.config';
import { DataService } from 'src/app/services/data.service';
import { countryCodes } from 'src/app/constants/country-codes';
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input';
import { Country } from 'ngx-intl-tel-input/lib/model/country.model';

@Component({
  selector: 'app-add-or-edit-customer',
  templateUrl: './add-or-edit-customer.component.html',
  styleUrls: ['./add-or-edit-customer.component.scss']
})
export class AddOrEditCustomerComponent implements OnInit {
  
  emailPattern: any = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
  isEmailValid : boolean = true;

  isCustomerDropdownRequired : boolean;
  customerDropDownValues : DropDownConfig[] = [];
  selectedTypeValues : any = {};
  customerAttributes : any[] = [];
  areCustomerAttributesFilled : boolean = false;

  isApplicationTableDataLoaded : boolean = false;
  // showDropDown : boolean = true;

  isPrimaryMobileNoValid : boolean = true;
  isSecondaryMobileNoValid : boolean = true;
  currentCountryCode : string = "in";

  customerDetails: any = {
    customerName: null,
    email: null,
    primaryMobileNo: null,
    secondaryMobileNo: null,
    address: null,
    billingAddress: null,
    editPrimaryMobileNo: null
  };

  formDataCopy : any = {
    billing_address : null,
    com_address : null,
    email : null,
    name: null,
    primary_contact : null,
    secondary_contact : null
  }

  billingAddressToggleFlag: boolean = false;
  isEdit: boolean = true;
  customerAdmin: any = null;
  customerId: any = null;
  subscriptions: Subscription[] = [];
  operation: any;

  assignedResources: any = {};
  
  applicationsResourcesInfo : any = {};
  isApplicationsAssigned : boolean = false;
  hasAssignedResources: boolean = false;
  showCustomerAdmin : boolean = false;
  customerAdminEmail : string = null;

  countryCodeList : any[] = [...countryCodes];

  secondaryContactValid : boolean = true;

  SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;

  primaryMobileForm = new FormGroup({
		primaryMobileNo: new FormControl(undefined, [Validators.required]),
	});

  secondaryMobileForm = new FormGroup({
		secondaryMobileNo: new FormControl(undefined, [Validators.required]),
	});

  constructor(
    private modal: NgbModal,
    private httpSrv: HttpService,
    private utilSrv: UtilsService,
    private appState: AppStateService,
    private dataSrv : DataService,
    private envService : EnvService,
    private router : Router,
    private route : ActivatedRoute
  ) { }

  ngOnInit() {
    
    let customerData = this.appState.getCustomerData();
    // console.log("Customer Data ", customerData);

    this.isCustomerDropdownRequired = this.envService.isCustomerDropdownRequired;
    this.customerDropDownValues = this.envService.customerDropDownValues;
    this.areCustomerAttributesFilled = this.isCustomerDropdownRequired ? false : true;

    if (customerData) {
      this.setInitialCustomerInfo();
    }

    let dropDownLabels = this.customerDropDownValues.map((item : any) => item.label);

    for (let label of dropDownLabels) {
      this.selectedTypeValues[label] = '';
    }

    const deletedAppInfoRef = this.appState.deletedAppInfoRef.subscribe((appInfo : any) => {
      const modalRef = this.modal.open(DeleteApplicationWithResourcesComponent, USER_MGMT_CNST.MODAL_OPTIONS);

      modalRef.componentInstance.selectedAppInfo = appInfo;
      modalRef.componentInstance.tableData = this.applicationsResourcesInfo;
      modalRef.componentInstance.customerId = this.customerId;

      modalRef.result.then((postData : any) => {
        if (Object.keys(postData).length > 0) {
          this.removeApplicationForCustomer(postData);
        }
      })
    })

    const queryParamsRef = this.route.queryParams.subscribe(async (params : Params) => {
      let { CUSTOMER_MODULE_OPERATIONS } = USER_MGMT_CNST;
      
      let operation = params['operation'];
      let customerId = params['customerId'];

      this.operation = operation;
      
      this.isEdit = this.operation === CUSTOMER_MODULE_OPERATIONS.UPDATE_CUSTOMER ? true : false;

      this.areCustomerAttributesFilled = this.isEdit ? true : false;

      this.isApplicationTableDataLoaded = this.operation === CUSTOMER_MODULE_OPERATIONS.ADD_CUSTOMER ? true : false;

      if (!isNullOrUndefined(customerId)) {
        this.appState.setCurrentCustomerId(customerId);
        this.customerId = customerId;
      }

      if (operation === CUSTOMER_MODULE_OPERATIONS.VIEW_CUSTOMER
        || operation === CUSTOMER_MODULE_OPERATIONS.UPDATE_CUSTOMER) {

        await this.handleViewOrEditCustomerOnRefresh(customerId);


        // if (isNullOrUndefined(customerData)) {
        //   await this.handleViewOrEditCustomerOnRefresh(customerId);
        // } else {
        //   console.log('Customer Data ', customerData);

        //   let { info } = customerData;

        //   if (this.utilSrv.isObjectNotNullAndEmpty(info)) {
        //     this.primaryMobileForm.controls.primaryMobileNo.setValue(info.primaryContact);
        //     this.secondaryMobileForm.controls.secondaryMobileNo.setValue(info.secondaryContact);
        //   }
 
        //   this.isApplicationTableDataLoaded = true;
        // }
      }
    })

    this.subscriptions.push(deletedAppInfoRef);
    this.subscriptions.push(queryParamsRef);
  }

  async handleViewOrEditCustomerOnRefresh(customerId: any) {
  
    //* Get all assigned resources for all assigned apps
    //* for a customer Api Call
    this.fetchAllAssignedResourcesUnderAssignedAppsForCustomer(customerId);

    //* API call to fetch customer type dropdown values
    await this.fetchCustomerAttributes();

    let options = {
      "uriParams": {
        customerId
      }
    };

    //* Get customer info by customer id api call
    let customerInfo = await this.dataSrv.getCustomerInfoByCustomerId(options);

    //* Get customer admin info by customer id api call
    let customerAdminInfo = await this.dataSrv.getCustomerAdminInfoByCustomerId(options);

    let { response: customerAdminResponse } = customerAdminInfo;

    if (this.utilSrv.isObjectNotNullAndEmpty(customerInfo)
      && this.utilSrv.isObjectNotNullAndEmpty(customerAdminInfo)) {
      let { response } = customerInfo;
      console.log("Response ", response);

      response['customerAdminEmail'] = customerAdminResponse.length > 0
        ? customerAdminResponse[0].email_id
        : null;

      this.showCustomerAdmin = !isNullOrUndefined(response['customerAdminEmail']) ? false : true;

      this.setCustomerDetails(response);
    }
  }

  validateEmail(event: any) {
    let inputStr = event.target.value;
    this.isEmailValid = this.utilSrv.isEmailValid(this.emailPattern, inputStr);
  }

  setInitialCustomerInfo() {
    let { info, operation } = this.appState.getCustomerData();

    this.operation = operation;
    this.isApplicationTableDataLoaded = this.operation === 'new' ? true : false;

    if (!isNullOrUndefined(info)) {
      let { customerAdminEmail } = info;
      this.customerAdminEmail = customerAdminEmail;
      this.showCustomerAdmin = !isNullOrUndefined(customerAdminEmail) || operation === 'new' ? false : true;
    }

    if (operation === 'edit' || operation === 'view') {
      this.appState.setCurrentCustomerId(info.customerId);
      this.setCustomerDetails(info);
      this.customerId = info.customerId;

      if (operation === 'view') {
        this.isEdit = false;
      }

      if ((this.isEdit || operation === 'view') && this.isCustomerDropdownRequired) {
        this.fetchCustomerAttributes();
      }

      if (this.customerDetails.address && this.customerDetails.billingAddress && this.customerDetails.address === this.customerDetails.billingAddress) {
        this.billingAddressToggleFlag = true;
      }

      this.fetchAllAssignedResourcesUnderAssignedAppsForCustomer();
    }
  }

  openAssignApplicationsModal(operationType : string) {
    const modalRef = this.modal.open(
      AssignUpdateCustomerApplicationsComponent,
      USER_MGMT_CNST.MODAL_OPTIONS);

    modalRef.componentInstance.operationType = operationType;

    modalRef.result.then((info: any) => {

      if (Object.keys(info).length > 0) {
        let { customerId, status } = info;

        if (status) {
          // TODO : Fetch assigned applications for the customer
          this.fetchAllAssignedResourcesUnderAssignedAppsForCustomer();
        }
      }
    })  
  }

  setCustomerDetails(postData: any) {
    
    let primaryContact = postData['primaryContact'] ? postData['primaryContact'] : null;
    let secondaryContact = postData['secondaryContact'] ? postData['secondaryContact'] : null;
    let editPrimaryContact = primaryContact!== null ? primaryContact.substring(primaryContact.length-10,primaryContact.length):null
    let editSecondaryContact = secondaryContact!== null ? secondaryContact.substring(secondaryContact.length-10,secondaryContact.length):null
    // console.log('Primary contact parts ', primaryContactParts);
    // console.log(postData);

    this.customerDetails = {
      customerName: postData['customerName'] ? postData['customerName'] : null,
      email: postData['email'] ? postData['email'] : null,
      primaryMobileNo: primaryContact,
      secondaryMobileNo: secondaryContact,
      address: postData['address'] ? postData['address'] : null,
      billingAddress: postData['billingAddress'] ? postData['billingAddress'] : null,
      customerAdminEmail : !isNullOrUndefined(this.customerAdminEmail) 
        ? this.customerAdminEmail : postData['customerAdminEmail'],
    }

    this.primaryMobileForm.controls.primaryMobileNo.setValue(editPrimaryContact);

    this.secondaryMobileForm.controls.secondaryMobileNo.setValue(editSecondaryContact);

    if (!isNullOrUndefined(this.customerDetails['customerAdminEmail'])) {
      this.customerAdminEmail = this.customerDetails['customerAdminEmail'];
    }

    this.formDataCopy = {
      name : this.customerDetails['customerName'],
      email : this.customerDetails['email'],
      primary_contact : this.customerDetails['primaryMobileNo'],
      secondary_contact : this.customerDetails['secondaryMobileNo'],
      com_address : this.customerDetails['address'],
      billing_address : this.customerDetails['billingAddress'],
    }

  }

  //TODO: Check for other ways of validating fields which can have
  //only alphabets, this is not "Angular" way of doing
  onlyAlphabet(event: any) {
    const pattern = /[a-zA-Z0-9\s]/;

    let inputChar = String.fromCharCode(event.charCode);
    console.log("matched : ",pattern.test(inputChar));
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  onPaste(event: ClipboardEvent, key: any) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text');
    let trimmedText = pastedText.replace(/[^a-zA-Z]/g, '');
    this.customerDetails[key] = '';
    setTimeout(() => {
      trimmedText = trimmedText.substr(0,25);
      this.customerDetails[key] = trimmedText;
    }, 1);
}

  onlyDigit(key,id) {
    var input = document.getElementById(id);
    var pattern = /^[1-9][0-9]{0,9}$/;
    var value = input['value'];
    !pattern.test(value) && (input['value'] = value = '');
    input.addEventListener('input',()=> {
        var currentValue = this.customerDetails[key];
        if(currentValue && !pattern.test(currentValue)) this.customerDetails[key] = value;
        else value = currentValue;
    });
  }

  /**
   * 1. If checkbox is clicked then copy comm_addr to billing addr
   * 2. If checkbox is unselected then the fields are independent
   * 3. If checkbox is selected and billing_addr is modified then update comm_addr
   */

  billingAddressToggle() {
    this.billingAddressToggleFlag = !this.billingAddressToggleFlag;

    this.onCommAddressChanged();
    this.onBillingAddressChanged();
  }

  onCommAddressChanged(event : any = null) {
   this.customerDetails.billingAddress = this.billingAddressToggleFlag 
    ? this.customerDetails.address : this.customerDetails.billingAddress;
  }

  onBillingAddressChanged(event : any = null) {
    this.customerDetails.address = this.billingAddressToggleFlag 
    ? this.customerDetails.billingAddress : this.customerDetails.address;
  }

  onEditClick() {
    this.isEdit = true;
  }

  navigateToCustomerList() {
    this.router.navigate(['', 'customers']);
  }

  navigateToEditView() {

    let { CUSTOMER_MODULE_OPERATIONS } = USER_MGMT_CNST;

    if (!isNullOrUndefined(this.customerId)) {
      this.router.navigate(['', 'customers', 'addCustomer'], {
        queryParams: {
          operation: CUSTOMER_MODULE_OPERATIONS.UPDATE_CUSTOMER,
          customerId: this.customerId
        }
      });
    }
  }

  checkLength(event: any, type: string) {
    let value = event.target.value;

    if (value.length > 0) {

      if (type === 'primary' ) {
        this.isPrimaryMobileNoValid = this.primaryMobileForm.invalid ? false : true;
      } else if (type === 'secondary') {
        this.isSecondaryMobileNoValid = this.secondaryMobileForm.invalid ? false : true ;
      }
    } else {

      if (type === 'primary') {
        this.isPrimaryMobileNoValid = true;
      } else if (type === 'secondary') {
        this.isSecondaryMobileNoValid = true;
      }
    }
  }

  onFormSubmit(form: NgForm) {
    let data = form.value;
    if (this.customerId) {
      this.updateCustomer(data);
    } else {
      this.addCustomer(data);
    }
    // form.reset();
  }

  addCustomerAdmin() {
    const modalRef = this.modal.open(AddCustomerAdminComponent, USER_MGMT_CNST.MODAL_OPTIONS);

    modalRef.componentInstance.customerId = this.customerId;
    modalRef.componentInstance.customerName = this.customerDetails.customerName;

    modalRef.result.then((data) => {
      this.customerAdmin = data;
      this.showCustomerAdmin = Object.keys(this.customerAdmin).length > 0 ? false : true;
    });
  }

  resetField(event : Country, type: string) {
    if (type === 'primary') {
      this.primaryMobileForm.controls.primaryMobileNo.setValue(null);
      this.isPrimaryMobileNoValid = true;
    } else {
      this.secondaryMobileForm.controls.secondaryMobileNo.setValue(null);
      this.isSecondaryMobileNoValid = true;
    }

    this.currentCountryCode = event.iso2;
  }
  addCustomer(data: any) {
    let { CUSTOMER_MODULE_OPERATIONS } = USER_MGMT_CNST;

    let primaryContactControl = this.primaryMobileForm.controls['primaryMobileNo'].value;
    let secondaryContactControl = this.secondaryMobileForm.controls['secondaryMobileNo'].value;

    let postData = {
      meta: {
        customerName: data.name,
        purchasedPlan: null,
        primaryContact: primaryContactControl.e164Number,
        secondaryContact: !isNullOrUndefined(secondaryContactControl) ? secondaryContactControl.e164Number : secondaryContactControl,
        email: data.email,
        address: data.com_address,
        billingAddress: data.billing_address,
        customerConfig : {
          dropDownConfig : this.areCustomerAttributesFilled ? this.customerAttributes : null
        }
      }
    };

    // console.log("Post data for add customer ", postData);

    this.httpSrv
      .makePostApiCall('ADD_CUSTOMER', postData, this.envService.baseUrl)
      .subscribe((response: any) => {
        if (response.result) {
          this.isEdit = true;
          this.operation = CUSTOMER_MODULE_OPERATIONS.UPDATE_CUSTOMER;
          this.customerId = response.response;

          this.showCustomerAdmin = !isNullOrUndefined(this.customerId) ? true : false;

          this.customerDetails.primaryMobileNo = postData.meta.primaryContact;
          this.customerDetails.secondaryMobileNo = postData.meta.secondaryContact;

          if (!isNullOrUndefined(this.customerId)) {
            this.router.navigate(['', 'customers', 'addCustomer'], {
              queryParams: {
                operation: CUSTOMER_MODULE_OPERATIONS.UPDATE_CUSTOMER,
                customerId: this.customerId
              }
            });
          }

          this.appState.setCurrentCustomerId(this.customerId);
          this.utilSrv.showToastMessage("Added customer successfully ", "success");
        }
      }, err => {

        let { error } = err;

        if (this.utilSrv.isObjectNotNullAndEmpty(error)) {
          let { message } = error;

          if (!isNullOrUndefined(message)) {
            this.utilSrv.showToastMessage(message, "error");
          } else {
            this.utilSrv.showToastMessage("Error while adding customer", "error");
          }
        } else {
          this.utilSrv.showToastMessage("Error while adding customer", "error");
        }
      })
  }

  updateCustomer(data: any) {
    // console.log("Form Data Copy ", this.formDataCopy);
    // console.log("Form Data ", data);

    let primaryContactControl = this.primaryMobileForm.controls['primaryMobileNo'].value;
    let secondaryContactControl = this.secondaryMobileForm.controls['secondaryMobileNo'].value;

    let postData = {
      meta: {
        customerName: data.name,
        purchasedPlan: null,
        primaryContact: primaryContactControl.e164Number,
        secondaryContact: !isNullOrUndefined(secondaryContactControl) ? secondaryContactControl.e164Number : secondaryContactControl,
        email: data.email,
        address: data.com_address,
        billingAddress: data.billing_address,
        customerId: this.customerId,
        customerConfig : {
          dropDownConfig : this.areCustomerAttributesFilled 
            ? this.dataSrv.buildCustomerAttributeValues(this.selectedTypeValues) 
            : null
        }
      }
    };

    console.log("Post data in update customer", postData);

    let options = {
      uriParams: {
        "id": this.customerId
      }
    }

    let isFormValuesModified = this.dataSrv.areEditCustomerFormValuesModified(this.formDataCopy, data);

    if (isFormValuesModified) {

      this.httpSrv
        .makePostApiCall('UPDATE_CUSTOMER', postData, this.envService.baseUrl, options)
        .subscribe((response: any) => {
          if (response.result) {
            this.isEdit = false;

            let customerData = this.appState.getCustomerData();

            // console.log("Customer Data ", customerData);

            if (this.utilSrv.isObjectNotNullAndEmpty(customerData)) {
              customerData['operation'] = USER_MGMT_CNST.CUSTOMER_MODULE_OPERATIONS.VIEW_CUSTOMER,
              this.appState.setCustomerData(customerData);
            }

            this.customerDetails.primaryMobileNo = postData.meta.primaryContact;
            this.customerDetails.secondaryMobileNo = postData.meta.secondaryContact;
        
            this.router.navigate(['', 'customers', 'addCustomer'], {
              queryParams: {
                operation : USER_MGMT_CNST.CUSTOMER_MODULE_OPERATIONS.VIEW_CUSTOMER,
                customerId : this.customerId
              },
            })

            this.utilSrv.showToastMessage("Updated customer successfully ", "success");
          }
        }, err => {
          if (err['code'] && err.code === 400) {
            this.utilSrv.showToastMessage(err.message, "error");
          } else {
            console.log("Error while updating customer ", err);
            this.utilSrv.showToastMessage(err['error']['message'],"error");
          }
        })
    } else {
      this.utilSrv.showToastMessage("No field is edited for updating", "error");
    }
  }

  assignOrUpdateResources(operationType: string) {
    this.appState.setCurrentResourceOperation(operationType);
    this.appState.setCurrentCustomerInfo(this.customerDetails);

    let customerId = this.appState.getCurrentCustomerId();

    if (!isNullOrUndefined(customerId)) {

      this.router.navigate(['', 'customers', 'assignUpdateResources'], {
        queryParams: {
          customerId,
          action : operationType
        },
        queryParamsHandling : 'merge'
      });

    } else {
      console.log("Customer Id ", customerId);
      this.utilSrv.showToastMessage("Customer id cannot be empty", "error");
    }
  }

  async fetchAllAssignedResourcesUnderAssignedAppsForCustomer(customerId : string = null) {

    // Api Parameters
    let options = {
      uriParams: {
        customerId: !isNullOrUndefined(customerId) ? customerId : this.appState.getCurrentCustomerId()
      }
    }

    let applicationsListResponse = await this.dataSrv.fetchAllResourcesForAllAssignedAppsByCustomerId(options);
    
    this.isApplicationTableDataLoaded = true;
  
    let { response } = applicationsListResponse;

    if (!isNullOrUndefined(response)) {
      this.applicationsResourcesInfo = response;

      let applicationKeys = Object.keys(this.applicationsResourcesInfo);
      this.isApplicationsAssigned = applicationKeys.length > 0 ? true : false;

      if (this.isApplicationsAssigned) {

        //* Check if atleast one application has any resources assigned
        //* If so make hasAssignedResources true and break the loop
        for (let i = 0; i < applicationKeys.length; i++) {

          let applicationName = applicationKeys[i];

          let hasResources = this.applicationsResourcesInfo[applicationName];
          let resourceKeys = Object.keys(hasResources);

          if (!isNullOrUndefined(hasResources) && resourceKeys.length > 0) {
            this.hasAssignedResources = true;
            break;
          }
        }
      }
    }
  }

  async fetchCustomerAttributes() {

    let customerId = !isNullOrUndefined(this.customerId)
      ? this.customerId
      : this.appState.getCurrentCustomerId();

    let options = {
      uriParams: {
        customerId
      }
    }

    if (!isNullOrUndefined(customerId)) {
      let customerAttributes = await this.dataSrv.getCustomerInfoByCustomerId(options);

      if (this.utilSrv.isObjectNotNullAndEmpty(customerAttributes)) {
        let { response } = customerAttributes;
        let { customerConfig } = response;

        if (!isNullOrUndefined(customerConfig)) {
          let { dropDownConfig } = customerConfig;

          if (!isNullOrUndefined(dropDownConfig)) {
            dropDownConfig.forEach((config: any) => {
              let { label, values } = config;
              this.selectedTypeValues[label] = values[0];
            })
          }
        }

        // console.log(this.selectedTypeValues);

        this.areCustomerAttributesFilled = Object.values(this.selectedTypeValues).some(value => value !== '');
      }
      // this.showDropDown = Object.values(this.selectedTypeValues).some(value => value !== '');
    }
  }

  removeApplicationForCustomer(postData: any) {
    this.httpSrv
      .makePostApiCall('UNASSIGN_APPLICATION_FOR_CUSTOMER', postData, this.envService.baseUrl)
      .subscribe(() => {
        this.utilSrv.showToastMessage("Deleted application successfully", "success");
        this.fetchAllAssignedResourcesUnderAssignedAppsForCustomer();
      }, err => {
        console.log("Error in deleting application ", err);
        this.utilSrv.showToastMessage("Error in deleting application", "error");
      })
  }

  onTypeChanged() {
    this.customerAttributes = this.dataSrv
      .buildCustomerAttributeValues(this.selectedTypeValues);

    this.areCustomerAttributesFilled = this.isCustomerAttributeValid();
  }

  isSecondaryContactValid() {
    let secondaryMobileNo = this.secondaryMobileForm.controls['secondaryMobileNo'].value;
    let isSecondaryMobileNullOrEmpty = isNullOrUndefined(secondaryMobileNo) || secondaryMobileNo === '';

    this.secondaryContactValid = this.secondaryMobileForm.valid || isSecondaryMobileNullOrEmpty;
  }

  isCustomerAttributeValid() : boolean {
    if (!this.isCustomerDropdownRequired) return this.areCustomerAttributesFilled;

    this.areCustomerAttributesFilled = this.customerAttributes.length > 0 && 
      this.dataSrv.checkIfAllAttributesAreFilled(this.customerAttributes);

    return this.areCustomerAttributesFilled;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription : Subscription) => subscription.unsubscribe());
    this.modal.dismissAll();
  }
}
