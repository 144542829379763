import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { USER_MGMT_CNST } from 'src/app/constants';

@Component({
  selector: 'app-edit-administrator',
  templateUrl: './edit-administrator.component.html',
  styleUrls: ['./edit-administrator.component.scss']
})
export class EditAdministratorComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) { }
  selectedApplications: any = [];
  selectedPremissions: any;
  modalData: any = {} ;

  

  roles = USER_MGMT_CNST.APPLICATION_URLS; 
  ngOnInit() {
  }

  

  onFormSubmit(form: NgForm) {
  }
  selectAll() {
     this.selectedApplications = [];
    this.roles.forEach((item: any) => {
      this.selectedApplications.push(item['value'])
      if(!item.isChecked) {
        item.isChecked = true;
      }
    })
  }
  unselectAll() {
    this.selectedApplications = [];
    this.roles.forEach((item: any) => {  
      if(item.isChecked){
        item.isChecked = false;
      }
    })
  }
//todo need to add in common service
  selectAdmin(event){
    this.roles.forEach(role2 =>{
      role2.isChecked = false;
    })
    event.forEach(role=> {
      this.roles.forEach(role2 =>{
        if(role.value == role2.value){
          role2.isChecked = true;
          role.isChecked = true;
        }
      })
    })

  }

}
