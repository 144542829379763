import { Component, OnInit } from "@angular/core";

import { USER_MGMT_CNST } from "src/app/constants/proj.cnst";

import { CommonService } from "src/app/services/common.service";
import { DataService } from "src/app/services/data.service";
import { HttpService } from "src/app/services/http.service";
import { AppStateService } from "src/app/services/app-state.service";
import { Subscription } from "rxjs";
import { UtilsService } from "src/app/services";
import { UM_TABLE_CNST } from "src/app/constants/table.cnst";
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { isNullOrUndefined } from 'util';
import { EnvService } from "src/app/services/env.service";

@Component({
  selector: "app-profiles",
  templateUrl: "./profiles.component.html",
  styleUrls: ["./profiles.component.scss"],
})
export class ProfilesComponent implements OnInit {
  tableConfig: any = {};
  tableData: any = [];
  subscriptions: Subscription[] = [];
  isEditPermission: boolean;
  isCustomer: any;
  customerListData: any[];

  loadMoreRecordsOnScroll: boolean = true;
  currentPage: number = 0;
  currentOffset: number = 0;

  //*Infinite scrolling related variables
  scrollDistance: number;
  throttle: number;
  isScrollOnWindow: boolean;

  wasPageScrolled: boolean;
  loggedInUserFlag: boolean = false;
  currentResponseLength: number;
  customerSelected: any;

  loading: boolean = false;
  currentOperation : string = null;
  isCustomerListFetched : boolean = false;

  totalRecordCount : number = 0;
  isCustomSizePresent : boolean = false;
  tableName : string = null;

  constructor(
    private router: Router,
    private dataSrv: DataService,
    private commonSrv: CommonService,
    private httpSrv: HttpService,
    private appStateSrv: AppStateService,
    private utilsSrv: UtilsService,
    private envService : EnvService,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    let permissions = this.appStateSrv.getUserPermissions();

    if (permissions && permissions['User Management'] && permissions['User Management']['resources'] && permissions['User Management']['resources']['ROLE'] && permissions['User Management']['resources']['ROLE']['actions']) {
      this.isEditPermission = permissions['User Management']['resources']['ROLE']['actions'].indexOf('EDIT') !== -1 ? true : false;
    }

    let metaInfo = this.appStateSrv.getUserMetaInfo();
    this.isCustomer = metaInfo && (metaInfo['customerId'] || metaInfo['customerName']) ? true : false;

    const operationRef = this.appStateSrv.operationRef.subscribe(
      (value: any) => {
        let { operation, postData } = value;

        this.currentOperation = operation;

        operation === USER_MGMT_CNST.OPERATION_TYPES.PROFILE.DELETE_PROFILE
          ? this.deleteProfile(postData)
          : this.updateProfile(postData);
      }
    );

    this.wasPageScrolled = this.appStateSrv.getWasPageScrolled();

    const pageScrolledRef = this.appStateSrv.wasPageScrolledRef.subscribe((value: boolean) => {
      this.wasPageScrolled = value;

      //* If page was scrolled update current page number and offset values
      if (this.wasPageScrolled) {
        this.commonTableDataFormater();
      }

      //* If current operation is not null and offset is not zero then 
      //* add/edit/delete was performed on any one of the loaded records
      //* hence reset offset to 0 to load all records upto that point
      if (this.loadMoreRecordsOnScroll || !isNullOrUndefined(this.currentOperation)) {
        if (this.customerSelected) {
          this.customerBasedRoleList(this.customerSelected['customerId']);
        } else {

          let size : number = null;

          if (this.currentOffset !== 0 && !isNullOrUndefined(this.currentOperation)) {
            size = this.totalRecordCount + 1;
            this.isCustomSizePresent = true;
            this.currentOffset = 0;
          }

          this.setTableData(size);
        }
      }
    });

    this.subscriptions.push(operationRef);
    this.subscriptions.push(pageScrolledRef);

    this.setTableConfig();
    this.setTableData();
  }

  setTableConfig() {
    this.tableConfig = this.commonSrv.setTableConfig(!this.isCustomer ?
      USER_MGMT_CNST.LIST_NAME_MAPPING.PROFILE_LIST : USER_MGMT_CNST.LIST_NAME_MAPPING.PROFILE_CUSTOMER_LIST
    );
    this.tableConfig.hasInfiniteScroll = true;

    this.tableName = !this.isCustomer 
      ? USER_MGMT_CNST.LIST_NAME_MAPPING.PROFILE_LIST 
      : USER_MGMT_CNST.LIST_NAME_MAPPING.PROFILE_CUSTOMER_LIST;
  }

  /**
   * Note : 
   * If current offset is 0 it means the edit/add/delete was performed
   * on any one of the existing records hence no need to load the 
   * next set of records
   *
   * If offset is not 0 and add/edit/delete was performed, since
   * we don't know on which record it was performed we need to load all
   * the records from offset 0 to current offset. 
  */
  async setTableData(size : number = null) {
    this.loading = true;

    let options = {
      'queryParams': {
        size:  isNullOrUndefined(size) ? USER_MGMT_CNST.INFINITE_SCROLL_OPTIONS.INFINITE_SCROLL_LIMIT : size,
        offset: this.currentOffset
      }
    }

    let profilesListApiResponse = await this.commonSrv.getProfilesList(options);
    let responseKeys = Object.keys(profilesListApiResponse);

    this.loading = false;

    if (!this.isCustomerListFetched) {
      this.setCustomerListData();
    }

    if (responseKeys.length > 0) {
      let tableData = this.formatTableData(profilesListApiResponse);

      // * If page was scrolled and edit/add/delete was not performed
      if (this.wasPageScrolled && isNullOrUndefined(this.currentOperation)) {
        this.tableData = [...this.tableData, ...tableData];
      } else {

        // * If page was not scrolled but add/edit was performed
        if (this.currentOffset === 0) {
          this.tableData = tableData;
        }
      }

      this.totalRecordCount = this.currentOffset === 0 
        ? tableData.length 
        : this.totalRecordCount + tableData.length;

      this.currentOperation = null;

      if (!isNullOrUndefined(size)) {
        this.currentOffset = this.totalRecordCount + 1;
      }
    }
  }

  openAddProfileModal() {
    this.appStateSrv.setProfileData({ operation: 'new', info: null });
    this.router.navigate(['', 'roles', 'role']);
  }

  addProfile(profileInfo: any, customerDetails) {
    let { name, description, application } = profileInfo;

    let profileData = this.appStateSrv.getProfileData();

    let postData = {
      "meta": {
        roleName: name,
        description,
        applicationId: application,
        customerId: customerDetails['customerId']
      }
      // roleDetailsJson: ""
    };

    this.httpSrv
      .makePostApiCall("ADD_PROFILE", postData, this.envService.baseUrl)
      .subscribe(
        (response: any) => {
          if (response.statusCode === 201) {
            this.appStateSrv.setWasPageScrolled(false);
            this.currentOperation = profileData.operation;
            this.utilsSrv.showToastMessage(response.message, "success");
          }
        },
        (error) => {
          console.log(error);
          this.utilsSrv.showToastMessage(
            "Error occured while creating role",
            "error"
          );
        }
      );
  }

  updateProfile(profileInfo: any) {
    let { postData, options } = this.setPostAndOptions(profileInfo);

    if (postData) {
      this.httpSrv
        .makePutApiCall(
          "UPDATE_PROFILE",
          postData,
          this.envService.baseUrl,
          options
        )
        .subscribe(
          (response: any) => {
            this.appStateSrv.setWasPageScrolled(false);
            this.utilsSrv.showToastMessage(response.message, "success");
          },
          (err) => {
            this.utilsSrv.showToastMessage(
              "Error occured while updating role",
              "error"
            );
          }
        );
    } else {
      this.utilsSrv.showToastMessage(
        "No field is edited for updating",
        "error"
      );
    }
  }

  deleteProfile(profileInfo: any) {
    let options = {
      uriParams: {
        roleId: profileInfo.role_id
      }
    };
    // console.log("Delete:: ",options);
    this.httpSrv
      .makeDeleteApiCall("DELETE_PROFILES", this.envService.baseUrl, options)
      .subscribe(
        (response: any) => {
          this.appStateSrv.setWasPageScrolled(false);

          this.tableData = this.tableData.filter(
            (value) => value.roleId != profileInfo.role_id
          );
          this.utilsSrv.showToastMessage(response.message, "success");
        },
        (error) => {
          this.utilsSrv.showToastMessage(
            "Error occured while Deleting role",
            "error"
          );
        }
      );
  }

  setPostAndOptions(profileInfo: any) {

    if (!profileInfo) {
      return { postData: null, options: null };
    }

    const { addResourceActions, deleteResourceActions, meta, roleDetailsJson } = profileInfo;
    
    if (!addResourceActions.length && !deleteResourceActions.length && !meta && !roleDetailsJson) {
      return { postData: null, options: null };
    }

    let postData: any = {
      addResourceActions,
      deleteResourceActions
    }

    if (profileInfo.meta) {
      postData.meta = profileInfo.meta;
    }

    if (roleDetailsJson) {
      postData.roleDetailsJson = roleDetailsJson
    }

    return {
      postData,
      options: {
        replaceParams: {
          "REPLACE_ROLE_ID": profileInfo.role_id
        }
      }
    }
  }

  setCustomerListData() {

    let userMetaInfo = this.appStateSrv.getUserMetaInfo();

    if (Object.keys(userMetaInfo).length > 0) {

      let { customerId } = userMetaInfo;

      // Fetch customer list only for super admin login
      if (isNullOrUndefined(customerId)) {
        this.httpSrv
          .makeGetApiCall('GET_CUSTOMERS_LIST', this.envService.baseUrl)
          .subscribe((response: any) => {
            let customerListData = this.transformDateAddedField(response, 'addedDate', 'addedDate');
            this.customerListData = this.dataSrv.addOptionsToTableData(customerListData, UM_TABLE_CNST.RESOURCES_LIST.customers, this.tableName);
            this.isCustomerListFetched = true;
          }, error => {
            this.utilsSrv.showToastMessage("Failed to fetch customers list", "error");
          })
      }
    }
  }

  transformDateAddedField(response: any, keyName: string, newPropertyName: string) {

    if (!Array.isArray(response)) {
      let keys = Object.keys(response);

      let formattedList = keys.map((key: string) => {
        let info = response[key];

        info[newPropertyName] = this.datePipe.transform(
          new Date(info[keyName]), "dd MMM yyyy");

        return info;
      })

      return formattedList;
    } else {

      let updatedList = response.map(item => {
        item[newPropertyName] = this.datePipe.transform(new Date(item[keyName]), "dd MMM yyyy");
        return item;
      });

      return updatedList
    }
  }

  onCustomerSelect(e) {
    if (!isNullOrUndefined(e)) {
      this.resetVariables();
      this.customerBasedRoleList(e.customerId)
    }
  }

  clearCustomerOptions() {
    this.resetVariables();
    this.setTableData();
  }

  resetVariables() {
    this.loadMoreRecordsOnScroll = true;
    this.currentPage = 0;
    this.currentOffset = 0;
    this.loggedInUserFlag = false;
    this.tableData = [];
  }

  async customerBasedRoleList(customerId) {

    let options = {
      'uriParams': {
        customerId: customerId
      },
      'queryParams': {
        size: USER_MGMT_CNST.INFINITE_SCROLL_OPTIONS.INFINITE_SCROLL_LIMIT,
        offset: this.currentOffset
      }
    }

    this.loading = true;

    let profilesListApiResponse = await this.commonSrv.getProfilesList(options);
    let responseKeys = Object.keys(profilesListApiResponse);

    this.loading = false;

    if (responseKeys.length > 0) {
      let tableData = this.formatTableData(profilesListApiResponse);
      this.tableData = [...this.tableData, ...tableData];
      this.commonTableDataFormater();
    }
  }

  formatTableData(profilesListApiResponse: any) : any[] {
    let list = profilesListApiResponse['response'];

    let updatedList = this.transformDateAddedField(list, 'addedDate', 'addedDate');

    let tableData = this.dataSrv.addOptionsToTableData(
      updatedList,
      UM_TABLE_CNST.RESOURCES_LIST.profile,
      this.tableName
    );

    if (tableData.length === 0) {
      this.loadMoreRecordsOnScroll = false;
    }

    this.currentResponseLength = tableData.length;

    return tableData;
  }

  commonTableDataFormater() {
    this.currentPage++;

    if (!this.isCustomSizePresent) {
      this.currentOffset = this.currentOffset +
        USER_MGMT_CNST.INFINITE_SCROLL_OPTIONS.INFINITE_SCROLL_LIMIT + 1;
    } else {
      this.isCustomSizePresent = false;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}