import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";

import { NgSelectModule } from '@ng-select/ng-select';
import { AssignOrUpdateCustomerResourcesComponent } from "./assign-or-update-customer-resources/assign-or-update-customer-resources.component";
import { AssignUpdateCustomerApplicationsComponent } from './assign-update-customer-applications/assign-update-customer-applications.component';
import { DeleteApplicationWithResourcesComponent } from './delete-application-with-resources/delete-application-with-resources.component';
import { UserUnauthorizedComponent } from './user-unauthorized/user-unauthorized.component';

@NgModule({
  declarations: [
    AssignOrUpdateCustomerResourcesComponent,
    AssignUpdateCustomerApplicationsComponent,
    DeleteApplicationWithResourcesComponent,
    UserUnauthorizedComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule
  ],
  entryComponents: [
    AssignUpdateCustomerApplicationsComponent,
    DeleteApplicationWithResourcesComponent,
    UserUnauthorizedComponent
  ]
})
export class PopupModule {}