import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TableModule } from 'src/app/lib/table/table.module';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { AdministratorsComponent } from './administrators.component';
import { AddAdministratorComponent } from './components/add-administrator/add-administrator.component';
import { EditAdministratorComponent } from './components/edit-administrator/edit-administrator.component';
import { ViewAdministratorsComponent } from './components/view-administrators/view-administrators.component';
import { AdministratorsListComponent } from './components/administrators-list/administrators-list.component';

const routes = [
  { path: '', redirectTo: "list", pathMatch: "full" },
  { path: 'list', component: AdministratorsListComponent },
  { path: 'add', component: AddAdministratorComponent }
]


@NgModule({
  declarations: [
    AdministratorsComponent,
    AddAdministratorComponent,
    EditAdministratorComponent,
    ViewAdministratorsComponent, 
    AdministratorsListComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    TableModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    RouterModule.forChild(routes),
  ],
  entryComponents:[ AddAdministratorComponent,
    EditAdministratorComponent,
    ViewAdministratorsComponent, ]

})
export class AdministratorsModule { }
