import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { USER_MGMT_CNST } from 'src/app/constants';
import { AppStateService, HttpService, UtilsService } from 'src/app/services';
import { EnvService } from 'src/app/services/env.service';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-assign-or-update-customer-resources',
  templateUrl: './assign-or-update-customer-resources.component.html',
  styleUrls: ['./assign-or-update-customer-resources.component.scss']
})
export class AssignOrUpdateCustomerResourcesComponent implements OnInit {

  allResourceTypes: string[] = [];

  applicationsResourcesInfo: any = {};
  isApplicationsAssigned: boolean = false;

  //* Map tracking whether each resource is selected / unselected [true / false]
  resourcesByAppsAndType: any = {};
  resourcesByAppsAndTypeCopy: any = {};

  //* Map of resource names to resource action id's
  resourceNameActionIdMap: any = {};
  resourceNameActionIdMapByTypeAndApp: any = {};

  //* Other variables
  isUpdateButtonEnabled: boolean = false;

  pageTitle: string = null;
  buttonText: string = null;
  operationType: string = null;

  customerId : string = null;
  queryParamsCustomerId : string = null;
  operation : string = null;
  customerDetails : any = {};

  subscriptions : Subscription[] = [];

  constructor(
    private httpSrv: HttpService,
    private utilSrv: UtilsService,
    private appStateSrv: AppStateService,
    private envService : EnvService,
    private router: Router,
    private route : ActivatedRoute) { }

  ngOnInit() {
    this.allResourceTypes = USER_MGMT_CNST.RESOURCE_TYPES;

    let { ALLOCATE_RESOURCES } = USER_MGMT_CNST.OPERATION_TYPES;

    const queryParamsRef = this.route.queryParams.subscribe((params : Params) => {
      this.queryParamsCustomerId = params['customerId'];
      this.operation = params['operation'];
      this.operationType = params['action'];

      if (!isNullOrUndefined(this.queryParamsCustomerId) && this.queryParamsCustomerId !== '') {
        this.fetchAllResourcesForAssignedApplications();
      }
    })

    this.operationType = isNullOrUndefined(this.operationType) 
      ? this.appStateSrv.getCurrentResourceOperation()
      : this.operationType;

    this.customerDetails = this.appStateSrv.getCurrentCustomerInfo();
    this.customerId = this.appStateSrv.getCurrentCustomerId();

    this.pageTitle = !isNullOrUndefined(this.operationType) &&
      this.operationType === ALLOCATE_RESOURCES.UPDATE_RESOURCES
      ? ALLOCATE_RESOURCES.UPDATE_RESOURCES_TITLE
      : ALLOCATE_RESOURCES.ASSIGN_RESOURCES_TITLE;

    this.buttonText = this.pageTitle === ALLOCATE_RESOURCES.UPDATE_RESOURCES_TITLE ? 'Update' : 'Assign';

    this.subscriptions.push(queryParamsRef);

    // this.fetchAllResourcesForAssignedApplications();
  }

  fetchAllResourcesForAssignedApplications() {

    let customerId = this.appStateSrv.getCurrentCustomerId() 
      ? this.appStateSrv.getCurrentCustomerId() 
      : this.queryParamsCustomerId;

    if (!isNullOrUndefined(customerId)) {
      let options = {
        uriParams: {
          "customerId": customerId
        }
      }

      this.httpSrv
        .makeGetApiCall('FETCH_ALL_RESOURCES_FOR_ASSIGNED_APPLICATIONS', this.envService.baseUrl, options)
        .subscribe((resourceListForAssignedApps: any) => {

          let { response } = resourceListForAssignedApps;

          if (!isNullOrUndefined(response)) {
            this.applicationsResourcesInfo = response;
            this.isApplicationsAssigned = Object.keys(this.applicationsResourcesInfo).length > 0 ? true : false;
            this.initializeResourceSelectedStatus();
          }

        }, err => {
          console.log("Error in fetching resources by resource type and application", err);
          this.utilSrv.showToastMessage("Error in fetching resources by resource type and application", "error");
        })
    }
  }

  //* To track whether each resource under
  //* a resource type under an application
  //* is selected / unselected
  initializeResourceSelectedStatus() {

    // console.log(this.applicationsResourcesInfo);

    let applications = Object.keys(this.applicationsResourcesInfo);

    applications.forEach((application: string) => {
      let resourceTypes = this.applicationsResourcesInfo[application];
      let resourceTypeKeys = Object.keys(resourceTypes);

      let resourceByResourceType = {};
      let resourceNameActionIdMapByType = {};

      resourceTypeKeys.forEach((resourceType: string) => {
        let resourceSelectedStatus = {};
        let resourceNameActionIdMap = {};

        let resourceListForResourceType = this.applicationsResourcesInfo[application][resourceType];

        resourceListForResourceType.forEach((resourceInfo: any) => {
          let { resourceName, resourceActionId, isResourceAssigned } = resourceInfo;
          resourceSelectedStatus[resourceName] = isResourceAssigned;
          resourceNameActionIdMap[resourceName] = resourceActionId;
        })

        resourceByResourceType[resourceType] = resourceSelectedStatus;
        resourceNameActionIdMapByType[resourceType] = resourceNameActionIdMap;
      })

      this.resourcesByAppsAndType[application] = resourceByResourceType;
      this.resourceNameActionIdMapByTypeAndApp[application] = resourceNameActionIdMapByType;
    })

    // console.log("Resources By Apps and Resource Type ", this.resourcesByAppsAndType);
    // console.log("Resource Action Id Map by Apps and Resource Type ", this.resourceNameActionIdMapByTypeAndApp);

    this.resourcesByAppsAndTypeCopy = JSON.parse(JSON.stringify(this.resourcesByAppsAndType));
  }

  toggleResourceSelectedStatus(applicationName: string, resourceType: string, resourceName: string) {

    if (!isNullOrUndefined(applicationName) &&
      !isNullOrUndefined(resourceType) &&
      !isNullOrUndefined(resourceName)) {

      this.resourcesByAppsAndType[applicationName][resourceType][resourceName] =
        !this.resourcesByAppsAndType[applicationName][resourceType][resourceName]

      // this.isUpdateButtonEnabled = this.enableOrDisableUpdateButton();
    }
  }

  assignOrUpdateResources() {

    let customerId = this.appStateSrv.getCurrentCustomerId();
    let { ALLOCATE_RESOURCES } = USER_MGMT_CNST.OPERATION_TYPES;

    let postData = {
      "customerId": null,
      "assignResources": [],
      "deleteResources": [],
      "customerDetailsJson": "{}"
    }

    let applicationNames = Object.keys(this.resourcesByAppsAndType);

    applicationNames.forEach((applicationName: string) => {
      let resourceTypesUnderApp = this.resourcesByAppsAndType[applicationName];

      if (!isNullOrUndefined(resourceTypesUnderApp)) {
        let resourceTypeKeys = Object.keys(resourceTypesUnderApp);

        resourceTypeKeys.forEach((resourceType: string) => {
          let resourcesUnderResourceType = this.resourcesByAppsAndType[applicationName][resourceType];

          if (!isNullOrUndefined(resourcesUnderResourceType)) {
            let resourceNames = Object.keys(resourcesUnderResourceType);

            resourceNames.forEach((resourceName: string) => {

              let existingStatus = this.resourcesByAppsAndTypeCopy[applicationName][resourceType][resourceName];
              let currentStatus = this.resourcesByAppsAndType[applicationName][resourceType][resourceName];

              let resourceActionId = this.resourceNameActionIdMapByTypeAndApp[applicationName][resourceType][resourceName];

              if (this.operationType === ALLOCATE_RESOURCES.ASSIGN_RESOURCES) {
                if (currentStatus) {
                  postData.assignResources.push(resourceActionId);
                }
              } else if (this.operationType === ALLOCATE_RESOURCES.UPDATE_RESOURCES) {
                // Deleted Resources Array
                if (existingStatus === true && currentStatus === false) {
                  postData.deleteResources.push(resourceActionId);
                } else if (existingStatus === false && currentStatus === true) {
                  postData.assignResources.push(resourceActionId);
                }
              }
            })
          }
        })
      }
    })

    postData.customerId = customerId;

    let customerInfo = this.buildCustomerInfo();

    if (postData.assignResources.length === 0 && postData.deleteResources.length === 0) {
      this.utilSrv.showToastMessage("No resources selected to assign / update", "error");
      return;
    } else {

      this.httpSrv
        .makePostApiCall('ASSIGN_OR_UPDATE_RESOURCES', postData, this.envService.baseUrl)
        .subscribe((response: any) => {
          this.utilSrv.showToastMessage("Resources updated successfully ", "success");

          this.appStateSrv.setCustomerData(({ operation: this.operation, info: customerInfo }))

          setTimeout(() => {
            this.router.navigate(['', 'customers', 'addCustomer'], {
              queryParams: {
                action: null
              },
              queryParamsHandling: 'merge'
            }
            );
          }, 300);

        }, err => {
          console.log("Error in assigning/updating resources ", err);
          this.utilSrv.showToastMessage("Error in assigning/updating resources", "error");
        })
    }
  }

  navigateToCustomerView() {
    this.router.navigate(['', 'customers', 'addCustomer'], {
      queryParams: {
        action: null
      },
      queryParamsHandling: 'merge'
    });
  }

  buildCustomerInfo() {
    return {
      ...this.customerDetails,
      customerId: this.customerId,
      primaryContact: this.customerDetails.primaryMobileNo,
      secondaryContact: this.customerDetails['secondaryMobileNo']
        ? this.customerDetails['secondaryMobileNo'] : null,
      customerAdminEmail : this.customerDetails.customerAdminEmail 
        ? this.customerDetails.customerAdminEmail : null
    };
  }
}
