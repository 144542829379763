import { CompileNgModuleMetadata } from '@angular/compiler';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { USER_MGMT_CNST } from 'src/app/constants';

@Component({
  selector: 'app-view-resource',
  templateUrl: './view-resource.component.html',
  styleUrls: ['./view-resource.component.scss']
})
export class ViewResourceComponent implements OnInit {
  @Input() modalData:any;
  modalDataStatus : any = USER_MGMT_CNST.MODAL_STATUS;
  constructor(
    public activeModal: NgbActiveModal
  ) { }


  ngOnInit() {
    // this.setData();
  }

  // setData(){
  //   this.dummyData = [
  //     {label:'Resource',value:'Resource1'},
  //     {label:'Description',value:'Some Content......'}
  // ]
  // }

}
