import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableHeaderRowComponent } from './header-row/header-row.component';
import { TableRowsComponent } from './rows/rows.component';
import { TableCellComponent } from './rows/row/table-cell/table-cell.component';
import { TableRowComponent } from './rows/row/row.component';
import { TableTotalRowComponent } from './rows/total-row/total-row.component';
import { TableToolbarComponent } from './toolbar/toolbar.component';
import { TableFooterComponent } from './footer/footer.component';
import { TableBreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { FormsModule } from '@angular/forms';
// import { ExportAsModule } from 'ngx-export-as';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPaginationModule } from 'ngx-pagination';
import { ExcelService } from '../services/excel.service';

@NgModule({
  declarations: [
    TableFooterComponent,
    TableHeaderRowComponent,
    TableRowsComponent,
    TableRowComponent,
    TableCellComponent,
    TableTotalRowComponent,
    TableToolbarComponent,
    TableBreadcrumbComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    // ExportAsModule,
    NgbModule,
    NgxPaginationModule
  ],
  providers: [ExcelService], //DataService, LocationService, DataTableService, 
  exports: [
    TableFooterComponent,
    TableHeaderRowComponent,
    TableRowsComponent,
    TableRowComponent,
    TableCellComponent,
    TableTotalRowComponent,
    TableToolbarComponent,
    TableBreadcrumbComponent
  ]
})
export class TablecomponentsModule { }
