export enum Config {
    LOGIN_CONFIG = 'loginConfig',
    SIDEBAR_CONFIG = 'sidebarConfig',
    LOGO_CONFIG = 'logoConfig',

    ADD_USER_CONFIG = 'adduserConfig',
    DELETE_USER_CONFIG = 'deletuserConfig',
    VIEW_USER_CONFIG = 'viewuserConfig',
    EDIT_USER_CONFIG  = 'editUserConfig',

    CHANGE_PASSWD_CONFIG = 'changePasswordConfig',
    OTP_CHANGE_PASSWD_CONFIG = 'otpChangePswdConfig',
    OTP_FORGOT_PASSWD_CONFIG = 'forgotPswdConfig',
    FORGOT_PASSWD_CONFIG= 'forgotPswdConfig',

    LIST_USERS_CONFIG = 'listUserConfig',
    LIST_APPLICATIONS_CONFIG = 'listApplicationConfig',
    LIST_RESOURCES_CONFIG = 'listResourcesConfig',
    LIST_ROLES_CONFIG = 'listRolesConfig',

    ERROR_MESSAGE_CONFIG = 'errorMessageConfig'


    

  
}
