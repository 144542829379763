import { Component, OnInit, Input, SimpleChanges, TemplateRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { TablePipe } from '../../utils/table.pipe';
import { DataTableEventsService } from '../../services/datatable.events.service';
import { Router } from '@angular/router';
import { UM_TABLE_CNST } from 'src/app/constants/table.cnst';

@Component({
  selector: '[lib-rows]',
  templateUrl: './rows.component.html',
  styleUrls: ['./rows.component.css']
})
export class TableRowsComponent implements OnInit, OnDestroy {

  @Input() recordData: any;
  @Input() totalData: any;
  @Input() defaultSortOptions: any;
  @Input() dataTypes: any;
  @Input() totalCols: number;
  @Input() noData:boolean;
  @Input() customTemplates: TemplateRef<any>;
  @Input() headers: any;
  @Input() paginatorOptions: any;
  @Input() indexCons: object;
  @Output() onRowClick = new EventEmitter<Object>();
  @Output() onOptionClick = new EventEmitter<any>();
  @Output() onWatchlistClick = new EventEmitter<any>();
  @Input() clickableDataTypes: any;


  
  config: any;
  subscriptions: Subscription[] = [];
  isTotalRowPresent: boolean;
  totalRowStatus: boolean;
  offset: number;

  sortOptions: any;
  filteredData: any = [];
  p: number;

  multiColMultiFilterOptions: any;
  rowPopOverDetails : any;
  columnWiseRecords: any[] = [];
  selectedFilterItems: any;
  masterRecordData: any[] = [];

  constructor(
    private tableEventsHandler: DataTableEventsService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.filteredData = this.recordData;
    let itemsInAPage = this.paginatorOptions.limit;
    if(!this.paginatorOptions.visible){
      itemsInAPage = this.filteredData.length;
    }
    if(this)
    this.config = {
      itemsPerPage: itemsInAPage,
      currentPage: this.p
    };
    this.sortOptions = this.defaultSortOptions;
    this.isTotalRowPresent = this.totalData.length === 0 ? false : true;
    this.offset = 0;
    this.getTotalRowStatus();
    this.updateRecordData();
    this.subscribeEvents();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateRecordData();
  }
  
  ngOnDestroy(): void {
    this.unsubscribeEvents();
  }

  click(event){
    this.onRowClick.emit(event);
  }

  updateRecordData(){    
    let tableFilter = new TablePipe();
    if(!isNullOrUndefined(this.sortOptions)){
      this.filteredData = tableFilter.transform(this.recordData, 'vassarTableSorting', [this.sortOptions]);
    }
  }

  subscribeEvents() {
    const sortOptionsSub = this.tableEventsHandler.sortOptions.subscribe((options) => {
      this.sortOptions = options;
      if(!isNullOrUndefined(this.sortOptions)){
        this.updateRecordData();
      }
    })

    const togglePageSub = this.tableEventsHandler.togglePage.subscribe((options) => {
      this.config = options;
      this.offset = this.config.itemsPerPage * (this.config.currentPage - 1);
      this.getTotalRowStatus();
    });

    this.columnWiseRecords = this.tableEventsHandler.getColumnWiseRecords();

    const columnWiseRecordsSub = this.tableEventsHandler.columnWiseRecordsRef.subscribe((records: any[]) => {
      this.columnWiseRecords = records;
    })

    this.selectedFilterItems = this.tableEventsHandler.getSelectedFilterItems();

    const selectedFilterItemsRef = this.tableEventsHandler.selectedFilterItemsRef.subscribe((values: any[]) => {
      this.selectedFilterItems = values;
      this.filterTableRows();
    })


    const checkStatusRef = this.tableEventsHandler.checkStatusDataRef.subscribe((values: any) => {
      this.checkboxStatusChange(values);
    })

    this.masterRecordData = this.tableEventsHandler.getMasterRecordData();

    const masterRecordDataRef = this.tableEventsHandler.masterRecordDataRef.subscribe((values: any) => {
      this.masterRecordData = [...values];
    })

    this.multiColMultiFilterOptions = this.tableEventsHandler.getMultiColMultiFilterOptions();

    const multiColMultiFilterRef = this.tableEventsHandler.multiColMultiFilterOptionRef.subscribe((value: object) => {
      this.multiColMultiFilterOptions = {...value};
      let columnValues;

      if(Object.keys(this.multiColMultiFilterOptions).length > 0){
        columnValues = this.getAllValuesForColumn(this.multiColMultiFilterOptions);      
        this.tableEventsHandler.setColumnValues(columnValues);
      }
    })

    this.rowPopOverDetails = this.tableEventsHandler.getRowPopOverDetails();

    const rowPopOverDetailsRef = this.tableEventsHandler.rowPopOverDetailsRef.subscribe((value: any) => {
      this.rowPopOverDetails = value;
      let rowInfo = this.getRowDataForRowIndex(this.rowPopOverDetails.rowIndex);
      this.tableEventsHandler.setSelectedRowInfo(rowInfo);

      if (this.rowPopOverDetails.value === UM_TABLE_CNST.PREVIOUS_ORDER_OPTIONS.REPLICATE) {
        this.router.navigate(['', 'orders', 'new-order', 'replicated-order']);
      }

    })

    this.subscriptions.push(sortOptionsSub);
    this.subscriptions.push(togglePageSub);
    this.subscriptions.push(multiColMultiFilterRef);
    this.subscriptions.push(columnWiseRecordsSub);
    this.subscriptions.push(selectedFilterItemsRef);
    this.subscriptions.push(masterRecordDataRef);
    this.subscriptions.push(rowPopOverDetailsRef);
    this.subscriptions.push(checkStatusRef);
  }

  //*Method to get values for a column based on options passed
  //*Options - {sortIndex : number, hasDistinct : boolean}
  //*hasDistinct - To fetch distinct values for column / not

  getAllValuesForColumn(filterOptions: any) : any[] {
    let { sortIndex, hasDistinct = false } = filterOptions;
    let columnValues = [];

    this.columnWiseRecords.forEach((record: any) => {
      let row = record[sortIndex];   
    
      if(row.value[0] !== '-') {
        if (hasDistinct) {
          let isPresent = columnValues.findIndex(item => item.value === row.value[0]);

          if (isPresent === -1) {
            columnValues.push({ value: row.value[0], isChecked: row.isChecked, sortIndex, dataType : row.dataType});
          }
        }
        else {
          columnValues.push({ value: row.value[0], isChecked: row.isChecked, sortIndex, dataType: row.dataType });
        }
      }
    })    
    columnValues = columnValues.sort((a, b) =>{
      if(a.dataType[0] !== 'NUMBER') {
        return (a.value > b.value) ? 1 : ((a.value < b.value) ? -1 : 0)
      } else {        
        return a.value - b.value 
      }
    })
    return columnValues;
  }


  //*Filter table rows based on the multi col 
  //*multi value filter options selected
  filterTableRows() {
    let filteredRecordData = [];
    let transformedSelectedItems = this.transformSelectedItems(this.selectedFilterItems);
    this.selectedFilterItems = {...transformedSelectedItems}; 

    this.masterRecordData.forEach((record: any[]) => {
      let isValidRecord = this.isValidRecord(record);
      if(isValidRecord) {
        filteredRecordData.push(record);
      }
    }) 
    this.tableEventsHandler.recordData.next(filteredRecordData);
  }

  checkboxStatusChange(status){
    this.masterRecordData.forEach(ele => {
      ele[0]['values'][0] = status;
    });
    this.tableEventsHandler.recordData.next(this.masterRecordData);
  }

  isValidRecord(record: any[]) : boolean {    
    let transformedRecord = [];

    record.forEach((item: any) => {
      transformedRecord.push(item.values);
    })
    
    for(let key in this.selectedFilterItems) {
      if(!this.isValidColumn(key, transformedRecord[key])) {
        return false;
      }
    }
    return true;
  }

  isValidColumn(key, record) : boolean {
    let selectedValuesInColumn: any[] = this.selectedFilterItems[key];
    let result = selectedValuesInColumn.findIndex(item => record.indexOf(item) !== -1); 
    return result !== -1 ? true : false;
  }

  transformSelectedItems(selectedItems: any[]) {
    let resultant = {};
    let items = [];

    selectedItems.forEach((item: any) => {
      let { sortIndex, value } = item;
      items[`idx_${sortIndex}`] = items[`idx_${sortIndex}`] ? items[`idx_${sortIndex}`] : [];
      items[`idx_${sortIndex}`].push(value);
      resultant[sortIndex] = items[`idx_${sortIndex}`];
    })
    return resultant;
  }

  getRowDataForRowIndex(rowIndex: number) {
    let rowData = [];

    for(let i = 0; i < this.masterRecordData.length; i++){
      let currentRow = this.masterRecordData[i];
      let currentRecordRowIndex = currentRow[0].rowIndex;
      
      if(currentRecordRowIndex === rowIndex) {
        rowData = currentRow;
        break;
      }
    }
    return rowData;
  }

  unsubscribeEvents() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    })
  }

  getTotalRowStatus(){
    this.totalRowStatus = (this.config.itemsPerPage >= this.filteredData.length || this.totalData.length != 0) ? true : false;
  }

  handleOptionClick(e){
    this.onOptionClick.emit(e);
  }

  handleWatchlistClick(cellData: any) {
    // To change inwatchlist or not in watchlist
    this.onWatchlistClick.emit(cellData);
  }
}
