import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { isNullOrUndefined } from "util";
import { UtilsService } from ".";
import { EnvService } from "./env.service";

@Injectable({
  "providedIn" : "root"
})

export class CsrfTokenService {

  constructor(
    private httpSrv : HttpService,
    private utilSrv : UtilsService,
    private envService : EnvService) { }

  //* Create csrf token from keycloak access token
  generateCsrfToken(accessToken : string) : Promise<any> {
    const decodedToken: any = this.decodeJWT(accessToken);
    const userId = decodedToken.sub || decodedToken.user_id;
    let options = {
      headers: {
        'Authorization': "Bearer " + accessToken,
        'X-User-ID': userId
      }
    }

    console.log("Calling Csrf token ....");

    let promise = new Promise((resolve) => {
      this.httpSrv
        .makeGetApiCall('GET_CSRF_TOKEN', this.envService.baseUrl, options)
        .subscribe((response: any) => {
          resolve(response);
        }, err => {
          this.utilSrv.showToastMessage("Something went wrong. Please try again later", "error");
          console.log(err);
          resolve({});
        })
    })

    return promise;
  }

  doesCsrfTokenExist() : boolean {
    let existingToken = localStorage.getItem('csrfToken');
    return !isNullOrUndefined(existingToken) ? true : false;
  }

  getCsrfToken() : string | null {
    return localStorage.getItem('csrfToken');
  }
  decodeJWT(token: string): any {
    try {
      const parts = token.split('.');
      if (parts.length !== 3) {
        throw new Error('JWT token does not have 3 parts');
      }
  
      const base64Url = parts[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = atob(base64);
      
      return JSON.parse(jsonPayload);
    } catch (error) {
      console.error('Error decoding JWT:', error);
      return null;
    }
  }
  
}
