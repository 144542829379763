import { Component, OnInit } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { CommonService } from 'src/app/services/common.service';
import { HttpService } from 'src/app/services/http.service';
import { DataService } from 'src/app/services/data.service';
import { UtilsService } from 'src/app/services';

import { AddResourceComponent } from './components/add-resource/add-resource.component';
import { USER_MGMT_CNST } from 'src/app/constants/proj.cnst';
import { UM_TABLE_CNST } from 'src/app/constants/table.cnst';
import { EnvService } from 'src/app/services/env.service';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements OnInit {
  tableConfig : any = {}
  tableData : any = [];

  constructor(
    private modal: NgbModal,
    private commonSrv: CommonService,
    private httpSrv: HttpService,
    private dataSrv: DataService,
    private utilSrv: UtilsService,
    private envService : EnvService
  ) { }

  ngOnInit() {
    this.setTableConfig();
    this.setTableData();
  }

  setTableConfig(){
    this.tableConfig = this.commonSrv.setTableConfig('resourcelist');
  }

  setTableData() {
    this.httpSrv
      .makeGetApiCall('GET_RESOURCES_LIST', this.envService.baseUrl)
      .subscribe((response: any) => {
        this.tableData = this.dataSrv.addOptionsToTableData(response, UM_TABLE_CNST.RESOURCES_LIST.resource);
      }, error => {
        this.utilSrv.showToastMessage('Error in fetching resources list', 'error');
      })
  }

  openAddResourceModal() {
    this.modal.open(AddResourceComponent, USER_MGMT_CNST.MODAL_OPTIONS)
  }


}
