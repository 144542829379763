import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import * as Keycloak from 'keycloak-js';
import { KeycloakLoginOptions } from 'keycloak-js';
import { Subscription } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { USER_MGMT_CNST } from './constants';
import { AuthenticationService } from './modules/authentication/services/authentication.service';
import { UtilsService } from './services';
import { AppStateService } from './services/app-state.service';
import { EnvService } from './services/env.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {

  loginFlag: boolean;
  signInFlag: boolean;
  routePath: any;
  sidebarState: boolean = false;

  userName : string = null;
  keyCloakLoginOpts : KeycloakLoginOptions;
  keyCloakAuth : Keycloak.KeycloakInstance;

  currentResourceName : string = null;
  isOneOfPredefinedResources : boolean = false;

  subscriptions : Subscription[] = [];

  constructor(
    public router: Router,
    private appState: AppStateService,
    private keyCloakSrv: KeycloakService,
    private authSrv : AuthenticationService,
    private utilSrv : UtilsService,
    private envService : EnvService
  ) { }

  async ngOnInit() {
    let isLoggedIn = await this.isUserLoggedIn();
    this.loginFlag = isLoggedIn ? true : false;
    
    this.appState.setCurrentResourceName("LOGIN");

    this.sidebarState = this.appState.getSidebarState();
    this.currentResourceName = this.appState.getCurrentResourceName();
    this.currentResourceName = !this.loginFlag ? this.currentResourceName : "";

    this.isOneOfPredefinedResources = USER_MGMT_CNST.PREDEFINED_RESOURCES.indexOf(this.currentResourceName) !== -1;
    
    this.keyCloakLoginOpts = {
      redirectUri : this.envService.keyCloakUrls.redirectUrl
    }

    const sideBarRef = this.appState.showSidebarRef.subscribe(
      (state) => (this.sidebarState = state)
    );

    const authStateRef = this.authSrv.authState.subscribe(state => {
      this.loginFlag = state;

      if (this.loginFlag) {

        this.keyCloakAuth = this.appState.getKeyCloakInstance() 
          ? this.appState.getKeyCloakInstance() 
          :this.keyCloakSrv.getKeycloakInstance();

        if (isNullOrUndefined(this.appState.getKeyCloakInstance())) {
          this.appState.setKeyCloakInstance(this.keyCloakAuth);
        }
      }
    });

    const isPredefinedResourceRef = this.appState.isPredefinedResourceRef.subscribe((status : boolean) => {
      this.isOneOfPredefinedResources = status;
    })

    const tokensLoadedRef = this.appState.tokensLoadedRef.subscribe((status : boolean) => {

      if (this.loginFlag && status) {

        //* Get all permissions of currently logged in user
        // this.getRoleAndRedirect();
      }
    })

    const currentResourceNameRef = this.appState.currentResourceNameRef.subscribe((resourceName : string) => {
      this.currentResourceName = resourceName;
      this.isOneOfPredefinedResources = USER_MGMT_CNST.PREDEFINED_RESOURCES.indexOf(resourceName) !== -1;
      this.appState.setIsPredefinedResource(this.isOneOfPredefinedResources);
    })

    // console.log("Current Resource Name in app component ", this.currentResourceName);
    // console.log("Is one of predefined resources in app component ", this.isOneOfPredefinedResources);

    this.subscriptions.push(sideBarRef);
    this.subscriptions.push(authStateRef);
    this.subscriptions.push(tokensLoadedRef);
    this.subscriptions.push(currentResourceNameRef);
    this.subscriptions.push(isPredefinedResourceRef);
  }

  toggleSidebar() {
    this.appState.setSidebarState(!this.sidebarState);
  }

  async isUserLoggedIn() : Promise<boolean> {
    return this.keyCloakSrv.isLoggedIn();
  }

  async login() {
    await this.keyCloakSrv.login(this.keyCloakLoginOpts);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }
}
