import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  loginFlag: boolean = true;
  // authUrls: string[] = ['auth/logn']
  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    this.router.events
    .pipe(filter(ev => ev instanceof NavigationEnd))
    .subscribe((ev: any) => {
      const url = ev.url;
      if(url.indexOf('authentication') !== -1) {
        this.loginFlag = false;
      }
    })
  }

}
