import { ResourcesComponent } from './resources.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddResourceComponent } from './components/add-resource/add-resource.component';
import { RouterModule } from '@angular/router';
import { ResourcesListComponent } from './components/resources-list/resources-list.component';
import { TableModule } from 'src/app/lib/table/table.module';
import { ViewResourceComponent } from './components/view-resource/view-resource.component';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { AuthorizationModule } from '../../modules/authorization/authorization.module';

const routes = [
  { path: '', pathMatch: "full", component: ResourcesComponent }
]

@NgModule({
  declarations: [ResourcesComponent,
    AddResourceComponent, 
    ResourcesListComponent, ViewResourceComponent],
  imports: [
    CommonModule,
    TableModule,
    RouterModule.forChild(routes),
    FormsModule,
    NgSelectModule,
    AuthorizationModule
  ],
  entryComponents : [AddResourceComponent, ViewResourceComponent]
})
export class ResourcesModule { }
