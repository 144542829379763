import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataTableEventsService {

  constructor() { }

  sortOptions = new Subject();

  togglePage = new Subject();

  breadcrumbClicked = new Subject();

  popupCalled = new Subject();

  recordData = new Subject<object[]>();

  totalData = new Subject<object[]>();
  
  data = new Subject<object[]>();

  //*Multi column multi filter options
  multiColMultiFilterOptions: any = {};
  multiColMultiFilterOptionRef = new Subject<object>();

  setMultiColMultiFilterOptions(value: object) {
    this.multiColMultiFilterOptions = value;
    this.multiColMultiFilterOptionRef.next(this.multiColMultiFilterOptions);
  }

  getMultiColMultiFilterOptions() {
    return this.multiColMultiFilterOptions;
  }

  //*Column values for a specific column
  columnValues: any[] = [];
  columnValuesRef = new Subject<any[]>();

  setColumnValues(values: any[]) {
    this.columnValues = values;
    this.columnValuesRef.next(this.columnValues);
  }

  getColumnValues() {
    return this.columnValues;
  }

  //*Column Wise Data for each column
  columnWiseRecords: any[] = [];
  columnWiseRecordsRef = new Subject<any[]>();

  setColumnWiseRecords(values: any[]) {
    this.columnWiseRecords = values;
    this.columnWiseRecordsRef.next(this.columnWiseRecords);
  }

  getColumnWiseRecords() {
    return this.columnWiseRecords;
  }

  //*Selected values in multi column multi value filter
  selectedFilterItems: any[] = [];
  selectedFilterItemsRef = new Subject<any[]>();

  setSelectedFilterItems(value: any[]) {
    this.selectedFilterItems = value;
    this.selectedFilterItemsRef.next(this.selectedFilterItems);
  }

  getSelectedFilterItems() {
    return this.selectedFilterItems;
  }

  //*Set Master Record Data (Backup of original table data)
  masterRecordData: any[] = [];
  masterRecordDataRef = new Subject<any[]>();

  setMasterRecordData(value: any[]) {
    this.masterRecordData = value;
    this.masterRecordDataRef.next(this.masterRecordData);
  }

  getMasterRecordData() {
    return this.masterRecordData;
  }

  //*Set check box status
  checkStatusData: any;
  checkStatusDataRef = new Subject();

  setCheckStatusData(value: any[]) {
    this.checkStatusData = value;
    this.checkStatusDataRef.next(this.checkStatusData);
  }

  getCheckStatusData() {
    return this.checkStatusData;
  }

  //*Set order options
  optionsData: any;
  optionsDataRef = new Subject();

  setOptionsData(value: any) {
    this.optionsData = value;
    this.optionsDataRef.next(this.optionsData);
  }

  getOptionsData() {
    return this.optionsData;
  }

  //*To set which row contained the popover clicked
  rowPopOverDetails: any = {};
  rowPopOverDetailsRef = new Subject<any>();

  setRowPopOverDetails(value: any) {
    this.rowPopOverDetails = value;
    this.rowPopOverDetailsRef.next(this.rowPopOverDetails);
  }

  getRowPopOverDetails() {
    return this.rowPopOverDetails;
  }

  //*To pass the selected row info
  selectedRowInfo: any = [];
  selectedRowInfoRef = new Subject<any[]>();

  setSelectedRowInfo(value: any[]) {
    this.selectedRowInfo = value;
    this.selectedRowInfoRef.next(this.selectedRowInfo);
  }

  getSelectedRowInfo() {
    return this.selectedRowInfo;
  }

  //*Listen for scroll on table
  tableWasScrolled: boolean = false;
  tableWasScrolledRef = new Subject<boolean>();

  setTableWasScrolled(value: boolean) {
    this.tableWasScrolled = value;
    this.tableWasScrolledRef.next(this.tableWasScrolled);
  }

  getTableWasScrolled() {
    return this.tableWasScrolled;
  }

}
