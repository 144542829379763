import { EditUserComponent } from './../pages/user/components/edit-user/edit-user.component';
import { ViewUsersComponent } from './../pages/user/components/view-users/view-users.component';
import { DeleteComponent } from './../modules/shared/components/delete/delete.component';
import { Injectable } from '@angular/core';
import { UM_TABLE_CNST } from 'src/app/constants/table.cnst'
import { USER_MGMT_CNST } from '../constants/proj.cnst';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewRolesComponent } from '../pages/roles/components/view-roles/view-roles.component';
import { EditRoleComponent } from '../pages/roles/components/edit-role/edit-role.component';
import { ViewProfilesComponent } from '../pages/profiles/components/view-profiles/view-profiles.component';
import { EditProfileComponent } from '../pages/profiles/components/edit-profile/edit-profile.component';
import { EditAdministratorComponent } from '../pages/administrators/components/edit-administrator/edit-administrator.component';
import { ViewAdministratorsComponent } from '../pages/administrators/components/view-administrators/view-administrators.component';
import { Config, Path } from '../enums';
import { InitService } from '../parsers/init.service';
import { isNullOrUndefined } from 'util';
import { UtilsService } from './utils.service';
import { HttpService } from './http.service';
import { EnvService } from './env.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private modal: NgbModal, 
    private initSrv: InitService,
    private utilsSrv: UtilsService,
    private httpSrv : HttpService,
    private envService : EnvService
  ) { }

  setTableConfig(moduleName: string) {
    let tableConfig: any = {}
    tableConfig = UM_TABLE_CNST.TABLE_CONFIG;
    tableConfig['columns'] = UM_TABLE_CNST.TABLE_COLUMNS[moduleName];

    tableConfig['css'] = {
      tableClass: `table table-striped table-light esclated table-bordered`,
      theadClass: 'thead-light',
      tbodyClass: ''
    }
    return tableConfig;
  }
  initializeUserConfig(config) {
    return this.initSrv.loadConfig(
      config
    );
  }
  handleOptionClick(e: any, moduleName: string) {
    let modalData: any = []
    
    if (e.val == USER_MGMT_CNST.OPTIONS.DELETE) {
      const template = this.modal.open(DeleteComponent, USER_MGMT_CNST.DELETE_POPUP_OPTIONS)
    }

    if (e.val == USER_MGMT_CNST.OPTIONS.VIEW) {
      if (moduleName == USER_MGMT_CNST.LIST_NAME_MAPPING.USER_LIST || moduleName == USER_MGMT_CNST.LIST_NAME_MAPPING.USER_CUSTOMER_LIST) {

        const config = {
          path: Path.VIEW_USER_CONFIG_PATH,
          configType: Config.VIEW_USER_CONFIG
        }

        let userId = e['data']['userId'];

        this.getUserInfoByUserId(userId)
          .then((apiResponse: any) => {

            if (!isNullOrUndefined(apiResponse) && Object.keys(apiResponse).length > 0) {

              // console.log("Response ", apiResponse);

              let { response } = apiResponse;
              let { meta } = response;

              this.initializeUserConfig(config)
                .then(data => {
                  let sorted = [];

                  sorted = this.utilsSrv.formatViewUsersData(data);
                  const template = this.modal.open(ViewUsersComponent, USER_MGMT_CNST.MODAL_OPTIONS);

                  modalData = this.userModalDataValues(e.data, modalData);
                  modalData = this.populateViewUsersModalData(meta, modalData);

                  // console.log("Modal Data ", modalData);

                  template.componentInstance.modalData = modalData;
                  template.componentInstance.viewUserConfig = data;
                  template.componentInstance.sortedData = sorted;
                })
                .catch(err => {
                  console.log(err, 'Failed to initialize user config');
                })
            }

          }).catch(err => {
            console.log("Failed to fetch user info");
          })

      } if (moduleName == USER_MGMT_CNST.LIST_NAME_MAPPING.ROLE_LIST) {
        const template = this.modal.open(ViewRolesComponent, USER_MGMT_CNST.MODAL_OPTIONS);
        template.componentInstance.modalData = modalData;
        modalData == this.roleModalDataValues(e.data, modalData);
      } if (moduleName == USER_MGMT_CNST.LIST_NAME_MAPPING.PROFILE_LIST || moduleName == USER_MGMT_CNST.LIST_NAME_MAPPING.PROFILE_CUSTOMER_LIST) {
        const template = this.modal.open(ViewProfilesComponent, USER_MGMT_CNST.MODAL_OPTIONS);
        template.componentInstance.modalData = modalData;
        modalData == this.profileModalDataValues(e.data, modalData);
      }
      if (moduleName == USER_MGMT_CNST.LIST_NAME_MAPPING.ADMIN_LIST) {
        const template = this.modal.open(ViewAdministratorsComponent, USER_MGMT_CNST.MODAL_OPTIONS);
        template.componentInstance.modalData = modalData;
        modalData == this.adminModalDataValues(e.data, modalData);
      }
    }
    if (e.val == USER_MGMT_CNST.OPTIONS.EDIT) {
      if (moduleName == USER_MGMT_CNST.LIST_NAME_MAPPING.USER_LIST || moduleName == USER_MGMT_CNST.LIST_NAME_MAPPING.USER_CUSTOMER_LIST) {
        const config = {
          path: Path.EDIT_USER_CONFIG_PATH,
          configType: Config.EDIT_USER_CONFIG
        }

        this.initializeUserConfig(config)
          .then(data => {
            // this.addConfig = data;
            // console.log("editUserConfig  ", data);

            const template = this.modal.open(EditUserComponent, USER_MGMT_CNST.EDIT_MODAL_OPTIONS);
            modalData = this.userModalDataValues(e.data, modalData);
            modalData.editAction = true;
            template.componentInstance.modalData = modalData;
            template.componentInstance.editUserConfig = data
          })
          .catch(err => {
            console.log(err, 'waringin here');
          })


      } if (moduleName == USER_MGMT_CNST.LIST_NAME_MAPPING.ROLE_LIST) {
        const template = this.modal.open(EditRoleComponent, USER_MGMT_CNST.EDIT_MODAL_OPTIONS);
        template.componentInstance.modalData = modalData;
        modalData == this.roleModalDataValues(e.data, modalData);
        modalData.editAction = true;
      }
      if (moduleName == USER_MGMT_CNST.LIST_NAME_MAPPING.PROFILE_LIST || moduleName == USER_MGMT_CNST.LIST_NAME_MAPPING.PROFILE_CUSTOMER_LIST) {
        const template = this.modal.open(EditProfileComponent, USER_MGMT_CNST.EDIT_MODAL_OPTIONS);
        template.componentInstance.modalData = modalData;
        modalData == this.roleModalDataValues(e.data, modalData);
        modalData.editAction = true;
      }
      if (moduleName == USER_MGMT_CNST.LIST_NAME_MAPPING.ADMIN_LIST) {
        const template = this.modal.open(EditAdministratorComponent, USER_MGMT_CNST.EDIT_MODAL_OPTIONS);
        template.componentInstance.modalData = modalData;
        modalData == this.adminModalDataValues(e.data, modalData);
        modalData.editAction = true;
      }
    }
  }


  userModalDataValues(rowData, modalData) {
    return rowData;
  }

  roleModalDataValues(rowData, modalData) {
    ;
    modalData.rolename = rowData[0]['values'][0]
    modalData.roledesc = rowData[1]['values'][0]
    modalData.status = rowData[2]['values'][0]
  }

  profileModalDataValues(rowData, modalData) {
    ;
    modalData.profilename = rowData[0]['values'][0]
    modalData.profiledesc = rowData[1]['values'][0]
    modalData.status = rowData[2]['values'][0]
  }

  adminModalDataValues(rowData, modalData) {
    ;
    modalData.administratorName = rowData[0]['values'][0]
    modalData.administratorDesc = rowData[1]['values'][0]
    modalData.status = rowData[2]['values'][0]
  }

  loadConfig(config){
    let configs = this.initSrv.getConfig(config.configType)   
    if(!isNullOrUndefined(configs)){
       return configs;
    }
     return this.initSrv.loadConfig(config);
  }

  getProfilesList(options : any) : Promise<any> {

    let promise = new Promise((resolve) => {
      this.httpSrv
        .makeGetApiCall("LIST_OF_PROFILES", this.envService.baseUrl, options)
        .subscribe((response: any[]) => {
          resolve(response);
        }, err => {
          console.log("Error in fetching roles list ", err);
          this.utilsSrv.showToastMessage("Error in fetching roles list ", "error");
          resolve({});
        })
    })

    return promise;
  }

  getUserInfoByUserId(userId : string) : Promise<any> {

    let promise = new Promise((resolve) => {

      if (!isNullOrUndefined(userId)) {

        let options = {
          'uriParams': {
            userId
          }
        }

        this.httpSrv
          .makeGetApiCall('FETCH_USER_INFO_BY_ID', this.envService.baseUrl, options)
          .subscribe((response: any) => {
            resolve(response);
          }, error => {
            console.log("Error in fetching user info by user id ", error);
            this.utilsSrv.showToastMessage("Error in fetching user info", "error");
            resolve({});
          })
      }
    })
    return promise;
  }

  populateViewUsersModalData(response : any, modalData : any) : any {

    if (this.utilsSrv.isObjectNotNullAndEmpty(response)) {
      modalData['firstName'] = response.firstName;
      modalData['lastName'] = isNullOrUndefined(response.lastName) ? '-' : response.lastName;
      modalData['emailId'] = response.email;
      modalData['title'] = isNullOrUndefined(response.title) ? '-' : response.title;
      modalData['lastLoginTs'] = isNullOrUndefined(response.lastLoginTs) ? null : response.lastLoginTs;
      modalData['status'] = response.status ? "Active" : "Inactive";
      modalData['username'] = response.username;
      modalData['mobileNumber'] = response.mobileNo;
    }

    return modalData;
  }

}
