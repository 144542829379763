import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { USER_MGMT_CNST, USER_MGMT_DEFAULT_CONFIG_CNST } from 'src/app/constants';
import { TimePipe } from 'src/app/lib/table/common/utils/time.pipe';
import { HttpService, UtilsService } from 'src/app/services';
import { TimerComponent } from '../../shared/timer/timer.component';
import { MustMatch } from '../change-password/old-pwd.validators';
import { AuthenticationService } from '../services/authentication.service';


@Component({
  selector: 'app-otp-validation',
  templateUrl: './otp-validation.component.html',
  styleUrls: ['./otp-validation.component.scss']
})
export class OtpValidationComponent implements OnInit {

  @ViewChild(TimerComponent, { static: true }) timer: TimerComponent;
  @Input() userName;
  @Input() otpConfig;
  submitted: boolean = false;
  displayErrorMessage = "";
  interval
  displayTime: string;
  activespiner: boolean = false;
  constant = {}


  otpForm: FormGroup = this.fb.group({
    'otp': ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6), Validators.pattern("^[0-9]*$")]],
    'newPwd': ['', [Validators.required, Validators.minLength(6)]],
    'confirmPwd': ['', Validators.required]
  },
    {
      validator: MustMatch('newPwd', 'confirmPwd')
    })
  triggerOpt: boolean;

  constructor(private fb: FormBuilder, private httpSrv: HttpService,
    private router: Router,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal, private authSrv: AuthenticationService,
    private toastr: ToastrService, private utilsService: UtilsService
  ) { }


  ngOnInit() {    
    this.constant = USER_MGMT_DEFAULT_CONFIG_CNST.OTP_FORGOT_PSWD_CONST
    this.timer.startTimer();
  }
  get formControl() {
    return this.otpForm.controls;
  }

  receiveMessage($event) {
    this.otpForm.disable()
    this.otpForm.reset();
    this.triggerOpt = true;
    this.displayErrorMessage = $event
  }
  //on Click Back 
  goBack() {
    this.router.navigate(['', 'authentication', USER_MGMT_CNST.NAVIGATION_MAPPING['FORGOT_PASSWORD']])
  }

  getPostParams() {
    const postData = {
      'username': this.userName
    }
    return postData
  }

  //API for Sending OTP 
  async otpSendAPI() {
    this.timer.clearInterval(this.interval);
    this.submitted = false
    this.activespiner = true
    this.displayErrorMessage = '';
    const postData = this.getPostParams();
    let response = await this.utilsService.makeOtpSendApiCall('OTP_SEND', postData)
    this.activespiner = false;
    if (!response) {
      this.displayErrorMessage = "Failed ..."
      return;
    }
    this.otpForm.enable()
    this.triggerOpt = false;
    this.timer.startTimer();
  }

  getValidatePostParams() {
    const postData = {
      username: this.userName,
      otp: this.otpForm.value['otp'],
      newPassword: this.otpForm.value['newPwd']
    }
    return postData
  }
  closeModal(){
    this.activeModal.dismiss('Cross click')
  }
  //Submitting Data
  async handleValidation() {
    this.submitted = true;
    if (this.triggerOpt) {
      return;
    }
    this.displayErrorMessage = ""
    if (this.otpForm.invalid) {
      this.displayErrorMessage = this.triggerOpt ? 'You expired time , please resent Verification code again' : 'Please fill the fields'
      return
    }
    if (this.otpForm.valid && this.triggerOpt) {
      // this.displayErrorMessage = 'You expired time , please resent otp again';
      return
    }
    //Parameters for Validating OTP API
    let postData = this.getValidatePostParams()
    let response = await this.utilsService.makeOtpValidateApiCall('RESET_PASSWORD', postData)
    this.activespiner = false;
    if (!response) {
      this.displayErrorMessage = "Invalid Verification code"
      return
    }
    this.activeModal.close();
    // this.router.navigate(['', 'authentication', 'login'])
  }

}


