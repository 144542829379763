import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Config } from 'src/app/enums';
import { InitService } from 'src/app/parsers/init.service';
import { AppStateService, HttpService } from 'src/app/services';
import { Router, NavigationEnd, Event } from '@angular/router';
import { EnvService } from 'src/app/services/env.service';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {

  sidebarState: boolean;
  userPermissions : any = {};

  subscriptions: Subscription[] = [];
  sideConfig: any;
  currentPath: string;

  currentAppName : string = null;
  customerId: any;
  listOfApplications: any;

  constructor(
    private appStateSrv: AppStateService, 
    private initSrv: InitService,
    private router: Router,
    private envSrv : EnvService,
    private httpSrv: HttpService,
    ) { }

  ngOnInit() {
    this.getConfig()
    this.sidebarState = this.appStateSrv.getSidebarState();

    const sideBarStateRef = this.appStateSrv.showSidebarRef.subscribe(res => {
      this.sidebarState = res;
    })

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentPath = this.router.url 
      }
    });

    this.currentAppName = this.envSrv.headerAppName;

    this.subscriptions.push(sideBarStateRef);
  }

  getConfig() {
    const sidebarConfig = {
      configType: Config.SIDEBAR_CONFIG
    }
    this.sideConfig = this.initSrv.getConfig(sidebarConfig.configType);
  }

  toggleSidebar() {
    this.appStateSrv.setSidebarState(!this.sidebarState);
  }

  onListItemClicked(routePath : string) {
    console.log("onListItemClicked called");
    
    this.appStateSrv.setSidebarState(!this.sidebarState);
    this.router.navigateByUrl(routePath);
  }

  checkForActiveRoute(currentPath : string, routerPath : string) {
    if (!isNullOrUndefined(currentPath) && !isNullOrUndefined(routerPath)) {
      return currentPath.includes(routerPath);
    }
  }

  redirectToApp() {
    console.log("redirectToApp 888");
    // debugger
    let redirectUrl = this.envSrv.appRedirectUrl;
    let customerDetails = JSON.parse(localStorage.getItem('userInfo'));
    this.customerId = customerDetails['userId'];
    let applicationName = localStorage.getItem('applicationName');
    console.log("applicationName",applicationName);
    
    // if (!isNullOrUndefined(applicationName)) {
      document.location.href = this.envSrv.uiBaseUrl + applicationName;
    // }
    // let Options: any = {}
    // Options = {
    //   'Csrf-Token': localStorage.getItem('csrfToken'),
    //   'Authorization': "bearer " + localStorage.getItem('token'),
    //   'customerId': this.customerId
    // }
    // this.httpSrv
    //   .makeGetApiCall(
    //     'FETCH_ASSIGNED_APPLICATION_FOR_CUSTOMER',
    //     this.envSrv.baseUrl, Options)
    //   .subscribe((res: any) => {
    //     this.listOfApplications = res['response'];
    //     console.log(" this.listOfApplications", this.listOfApplications);


    //     if (this.listOfApplications) {
    //       redirectUrl = this.listOfApplications[0].applicationName.toString();
    //     }
    //     if (redirectUrl == 'TNWRIMS') {
    //       redirectUrl = 'tnwris';
    //     }
    //     else if (redirectUrl == 'TNSWIP') {
    //       redirectUrl = 'tnswip';
    //     }
    //     console.log("redirectUrl", redirectUrl);
    //     localStorage.setItem('applicationName',redirectUrl);
        
        // if (!isNullOrUndefined(redirectUrl)) {
        //   document.location.href = this.envSrv.uiBaseUrl + redirectUrl;
        // }
    //   });

  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }
}
