import { Component, OnInit, Input, TemplateRef, EventEmitter, Output } from '@angular/core';
import { DataTableService } from '../../../services/data-table.service';

@Component({
  selector: 'lib-row, [lib-row]',
  templateUrl: './row.component.html',
  styleUrls: ['./row.component.css']
})
export class TableRowComponent implements OnInit {

  @Input() datarow: any;
  @Input() totalrow: any;
  @Input() index: any;
  @Input() customTemplates: TemplateRef<any>;
  @Input() headers: any;
  @Input() indexCons: any;

  @Output() onRowClick = new EventEmitter<any>();
  @Output() onOptionClick = new EventEmitter<any>();
  @Output() onWatchlistClick = new EventEmitter<any>();
  @Input() clickableDataTypes: any;



  obj: any = {};

  constructor(private dataTableService: DataTableService) { }

  ngOnInit() {
    //!Don't add rowIndex property here - It will affect previous order functionality
  }

  click(event) {
    if (!this.clickableDataTypes.includes(event.row[event.cell]['dataType'][0])) {
      this.onRowClick.emit(event);
    }
  }
  cellInfo(rowInfo, e, i, headers) {
    const cell = this.dataTableService.getCellInfo(rowInfo, e, i, headers);
    this.click({ row: rowInfo, cell: i });
  }

  handleOptionClick(e) {
    this.onOptionClick.emit(e);
  }

  handleWatchlistClick(cellData: any) {
    // To change inwatchlist or not in watchlist
    this.onWatchlistClick.emit(cellData);
  }


}
