import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { USER_MGMT_CNST } from 'src/app/constants/proj.cnst';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-add-resource',
  templateUrl: './add-resource.component.html',
  styleUrls: ['./add-resource.component.scss']
})
export class AddResourceComponent implements OnInit {

  @Input() modalData:any;
  isEditResource : boolean = false;
  actionType : any = 'Add New';
  APPLICATION_URL_LIST : any = [];
  RESOURCE_URL_LIST : any = [];
  status : boolean = true;

  constructor(
    public activeModal: NgbActiveModal 
  ) { }

  ngOnInit() {
    this.APPLICATION_URL_LIST = USER_MGMT_CNST.APPLICATION_URLS;
    this.RESOURCE_URL_LIST = USER_MGMT_CNST.RESOURCE_URLS
    this.isEditResource = this.modalData && this.modalData.editResource ?  this.modalData.editResource : false;
    if(this.isEditResource){
      this.actionType =  USER_MGMT_CNST.OPTIONS.EDIT
      this.status = this.modalData.status == 'Active' ? true : false;
    }
    if(!this.isEditResource){
      this.modalData = [];
    }
  }

  onlyAlphabet(event: any) {
    const pattern = /[a-zA-Z ]/;

    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  onFormSubmit(form: NgForm) {
  }

}
