import { Component, OnInit } from '@angular/core';
import { AppStateService, HttpService, UtilsService } from 'src/app/services';
import { Router } from '@angular/router';
import { USER_MGMT_CNST } from 'src/app/constants/proj.cnst';
import { isNullOrUndefined } from 'util';
import { EnvService } from 'src/app/services/env.service';

@Component({
  selector: 'app-assign-resource',
  templateUrl: './assign-resource.component.html',
  styleUrls: ['./assign-resource.component.scss']
})
export class AssignResourceComponent implements OnInit {
  customerTableData: any[];
  rolesTableData: any[];

  active: any;

  isCustomerSubmitButtonEnabled: boolean = false;
  isRoleSubmitButtonEnabled: boolean = false;

  customerResourceAllocationMap: any = {};
  customerResourceAllocationMapCopy: any = {};

  roleResourceAllocationMap: any = {};
  roleResourceAllocationMapCopy: any = {};
  userPermission: any;
  isCustomer: boolean;

  isUserCustomerServiceExecutive : boolean = false;

  constructor(
    private appStateSrv: AppStateService,
    private router: Router,
    private httpSrv: HttpService,
    private utilsSrv: UtilsService,
    private envService : EnvService) { }

  applicationData: any;
  listOfCustomers: any;
  customerTableDataCopy: any[];
  rolesTableDataCopy: any[];


  ngOnInit() {

    let metaInfo = this.appStateSrv.getUserMetaInfo();
    this.isCustomer = metaInfo && (metaInfo['customerId']|| metaInfo['customerName']) ? true : false;
    this.applicationData = this.appStateSrv.getApplicationData();

    // CSE - Customer Service Executive
    // Can only add and update users on behalf of customer admin for a customer
    // Only read access on other resources
    this.isUserCustomerServiceExecutive = this.checkIfUserIsCSE();

    if (!this.applicationData) {
      this.router.navigate(['', 'applications']);
    }

    if (this.applicationData) {
      this.loadData();
    }
  }

  async loadData() {
    let customerApiStatus = await this.fetchListOfCustomers();

    if (!isNullOrUndefined(customerApiStatus)) {
      this.fetchListOfRoles();
    }
  }

  fetchListOfCustomers(): Promise<any> {

    let promise = new Promise(resolve => {
      this.httpSrv
        .makeGetApiCall('GET_CUSTOMERS_LIST', this.envService.baseUrl, { uriParams: { applicationId: this.applicationData.info.applicationId, resourceActionId: this.applicationData.info.resourceActionId } })
        .subscribe((response: any) => {
          this.customerTableData = response;
          this.customerTableDataCopy = response;
          this.initializeCustomerResourceAllocationMap();
          resolve(true);
        }, error => {
          this.utilsSrv.showToastMessage("Failed to fetch customer list", "error");
          resolve(false);
        })
    })

    return promise;
  }

  fetchListOfRoles() {
    this.httpSrv
      .makeGetApiCall('LIST_OF_PROFILES', this.envService.baseUrl, { uriParams: { applicationId: this.applicationData.info.applicationId, resourceActionId: this.applicationData.info.resourceActionId } })
      .subscribe((response: any) => {
        this.rolesTableData = response['response'];
        this.rolesTableDataCopy = response['response'];
        this.initializeRoleResourceAllocationMap();
      }, error => {
        this.utilsSrv.showToastMessage("Failed to fetch customer list", "error");
      })
  }

  initializeCustomerResourceAllocationMap() {
    this.customerTableData.forEach((customerInfo: any) => {
      let { customerId, assigned } = customerInfo;
      this.customerResourceAllocationMap[customerId] = assigned;
    })

    this.customerResourceAllocationMapCopy = { ...this.customerResourceAllocationMap };
  }

  initializeRoleResourceAllocationMap() {

    this.rolesTableData.forEach((roleInfo: any) => {
      let { roleId, isAssigned } = roleInfo;
      this.roleResourceAllocationMap[roleId] = isAssigned;
    })

    this.roleResourceAllocationMapCopy = { ...this.roleResourceAllocationMap };
  }

  toggleCustomerResourceAllocationMap(customerId: string) {
    this.customerResourceAllocationMap[customerId] =
      !this.customerResourceAllocationMap[customerId];

    this.isCustomerSubmitButtonEnabled = this.enableOrDisableSubmitButton('customer');
  }

  toggleRoleResourceAllocationMap(roleId: string) {
    this.roleResourceAllocationMap[roleId] = !this.roleResourceAllocationMap[roleId];
    this.isRoleSubmitButtonEnabled = this.enableOrDisableSubmitButton('role');
  }

  buildLabel(id : number) {
    return `flexCheckChecked${id}`;
  }

  enableOrDisableSubmitButton(type: string): boolean {

    let entityCopy = type === 'customer'
      ? this.customerResourceAllocationMapCopy
      : this.roleResourceAllocationMapCopy;

    let entity = type === 'customer'
      ? this.customerResourceAllocationMap
      : this.roleResourceAllocationMap;

    for (let id in entityCopy) {

      let existingStatus = entityCopy[id];
      let currentStatus = entity[id];

      if ((existingStatus === true && currentStatus === false) ||
        (existingStatus === false && currentStatus === true)) {
        return true;
      }
    }
    return false;
  }

  submitCustomers() {

    let postData = {
      resourceActionId: this.applicationData.info.resourceActionId,
      assignedCustomers: [],
      unAssignedCustomers: []
    }

    for (let customerId in this.customerResourceAllocationMapCopy) {

      // false - true (assign)
      // true - false (unassign)
      let existingStatus = this.customerResourceAllocationMapCopy[customerId];
      let currentStatus = this.customerResourceAllocationMap[customerId];

      if (existingStatus === true && currentStatus === false) {
        postData.unAssignedCustomers.push(customerId);
      } else if (existingStatus === false && currentStatus === true) {
        postData.assignedCustomers.push(customerId);
      }
    }

    this.httpSrv
      .makePostApiCall('ASSIGN_RESOURCE_TO_CUSTOMERS', postData, this.envService.baseUrl)
      .subscribe((response: any) => {
        this.utilsSrv.showToastMessage("Successfully updated resources for customers", "success");
        this.fetchListOfCustomers();
      }, error => {
        this.utilsSrv.showToastMessage("Failed to fetch customer list", "error");
      })
  }

  submitRoles() {

    let postData = {
      resourceActionId: this.applicationData.info.resourceActionId,
      assignedRoleIds: [],
      unAssignedRoleIds: []
    }

    for (let roleId in this.roleResourceAllocationMapCopy) {

      // false - true (assign)
      // true - false (unassign)
      let existingStatus = this.roleResourceAllocationMapCopy[roleId];
      let currentStatus = this.roleResourceAllocationMap[roleId];

      if (existingStatus === true && currentStatus === false) {
        postData.unAssignedRoleIds.push(roleId);
      } else if (existingStatus === false && currentStatus === true) {
        postData.assignedRoleIds.push(roleId);
      }
    }

    this.httpSrv
      .makePostApiCall('ASSIGN_RESOURCE_TO_ROLES', postData, this.envService.baseUrl)
      .subscribe((response: any) => {
        this.utilsSrv.showToastMessage("Successfully assigned resources for roles", "success");
        this.fetchListOfRoles();
      }, error => {
        this.utilsSrv.showToastMessage("Failed to fetch customer list", "error");
      })
  }

  navigateToApplicationsList() {
    this.router.navigate(['', 'applications']);
  }

  onCustomerSelect(e){
    if(!isNullOrUndefined(e)){
      this.customerTableDataCopy = this.customerTableData.filter(x=> x.customerId === e.customerId);
    }
  }

  clearCustomerOptions(){
    this.customerTableDataCopy = this.customerTableData;
  }

  onRoleSelect(e){
    if(!isNullOrUndefined(e)){
      this.rolesTableDataCopy = this.rolesTableData.filter(x=> x.roleId === e.roleId);
    }
  }

  clearRoleOptions(){
    this.rolesTableDataCopy = this.rolesTableData;
  }

  // Return true / false based on if logged in user
  // has role of customer service executive
  checkIfUserIsCSE(): boolean {
    let userPermissions = this.appStateSrv.getUserPermissions();

    if (this.utilsSrv.isObjectNotNullAndEmpty(userPermissions)) {

      // TODO : Currently only considering first application assigned to user
      // TODO : Generalize this if user has access to multiple applications
      let appName = Object.keys(userPermissions)[0];

      if (!isNullOrUndefined(appName)) {
        let appPermissions = userPermissions[appName];

        if (this.utilsSrv.isObjectNotNullAndEmpty(appPermissions)) {
          let { roleName = null } = appPermissions;

          if (!isNullOrUndefined(roleName)) {
            return roleName === USER_MGMT_CNST.CUSTOMER_SERVICE_EXECUTIVE;
          }
        }
      }
    }

    return false;
  }
}
