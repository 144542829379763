import { Component, OnInit, Input } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { DeleteComponent } from 'src/app/modules/shared/components/delete/delete.component';
import { AddResourceComponent } from '../add-resource/add-resource.component';
import { ViewResourceComponent } from '../view-resource/view-resource.component';

import { USER_MGMT_CNST } from 'src/app/constants/proj.cnst';
import { UM_TABLE_CNST } from 'src/app/constants/table.cnst';

@Component({
  selector: 'app-resources-list',
  templateUrl: './resources-list.component.html',
  styleUrls: ['./resources-list.component.scss']
})
export class ResourcesListComponent implements OnInit {

  @Input() tableConfig : any;
  @Input() tableData : any

  constructor(private modal: NgbModal) { }

  dataTypes = UM_TABLE_CNST.CLICKABLE_DATA_TYPE;
  modalData: any = [];

  ngOnInit() { }

  handleOptionClick(e: any) {
    let rowData = e.data;

    if (e.val == USER_MGMT_CNST.OPTIONS.VIEW) {
      const template = this.modal.open(ViewResourceComponent, USER_MGMT_CNST.MODAL_OPTIONS);
      template.componentInstance.modalData = this.modalData;
      this.modalData.name = rowData[0] ? rowData[0]['values'][0] : '-'
      this.modalData.desc = rowData[1] ? rowData[1]['values'][0] : '-'
      this.modalData.status = rowData[2] ? rowData[2]['values'][0] : '-'
    }

    else if (e.val == USER_MGMT_CNST.OPTIONS.DELETE) {
      const template = this.modal.open(DeleteComponent, USER_MGMT_CNST.DELETE_POPUP_OPTIONS);
    }

    else {
      const template = this.modal.open(AddResourceComponent, USER_MGMT_CNST.MODAL_OPTIONS);
      template.componentInstance.modalData = this.modalData;
      this.modalData.application = rowData[0] ? rowData[0]['values'][0] : '-'
      this.modalData.resource = rowData[1] ? rowData[1]['values'][0] : '-'
      this.modalData.resourceurl = rowData[2] ? rowData[2]['values'][0] : '-'
      this.modalData.desc = rowData[3] ? rowData[3]['values'][0] : '-'
      this.modalData.status = rowData[4] ? rowData[4]['values'][0] : '-'
      this.modalData.editResource = true;
    }
  }
}
