import { Component, OnInit,Input } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-view-application',
  templateUrl: './view-application.component.html',
  styleUrls: ['./view-application.component.scss']
})
export class ViewApplicationComponent implements OnInit {
  
  @Input() modalData;
  
  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
  }


}
