import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { USER_MGMT_CNST } from 'src/app/constants/proj.cnst';
import { UM_TABLE_CNST } from 'src/app/constants/table.cnst';
import { DeleteComponent } from 'src/app/modules/shared/components/delete/delete.component';
import { AddRoleComponent } from '../add-role/add-role.component';
import { EditRoleComponent } from '../edit-role/edit-role.component';
import { ViewRolesComponent } from '../view-roles/view-roles.component';
import { CommonService } from 'src/app/services/common.service';
import { DataService } from 'src/app/services/data.service';
@Component({
  selector: 'app-roles-list',
  templateUrl: './roles-list.component.html',
  styleUrls: ['./roles-list.component.scss']
})
export class RolesListComponent implements OnInit {
  tableConfig : any = {}
  tableData : any = [];
  dataTypes = UM_TABLE_CNST.CLICKABLE_DATA_TYPE;
  modalData:any=[];

  constructor(private modal: NgbModal,
    private commonService: CommonService,
    private dataService : DataService
    ) { }

  public addRoleModal(){
    this.modal.open(AddRoleComponent,USER_MGMT_CNST.MODAL_OPTIONS)
  };

  ngOnInit() {
    this.setTableConfig();
    this.setTableData();
  }
  setTableConfig(){
    this.tableConfig = this.commonService.setTableConfig(USER_MGMT_CNST.LIST_NAME_MAPPING.ROLE_LIST);
  }

  setTableData(){
    this.tableData = this.dataService.getListData(USER_MGMT_CNST.LIST_NAME_MAPPING.ROLE_LIST);
  }

  handleOptionClick(e){
    this.commonService.handleOptionClick(e, USER_MGMT_CNST.LIST_NAME_MAPPING.ROLE_LIST);
  
  }
}
