import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TableModule } from 'src/app/lib/table/table.module';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { ProfilesComponent } from './profiles.component';
import { AddProfileComponent } from './components/add-profile/add-profile.component';
import { EditProfileComponent } from './components/edit-profile/edit-profile.component';
import { ViewProfilesComponent } from './components/view-profiles/view-profiles.component';
import { ProfilesListComponent } from './components/profiles-list/profiles-list.component';
import { AuthorizationModule } from '../../modules/authorization/authorization.module';
import { RoleValidScopeComponent } from './components/role-valid-scope/role-valid-scope.component';
import { AddOrViewProfileComponent } from './components/add-or-view-profile/add-or-view-profile.component';

const routes = [
  { path: '', pathMatch: "full", component: ProfilesComponent },
  { path: 'role', pathMatch: "full", component: AddOrViewProfileComponent },
]


@NgModule({
  declarations: [
    ProfilesComponent,
    ProfilesListComponent,
    AddProfileComponent,
    EditProfileComponent,
    ViewProfilesComponent,
    RoleValidScopeComponent,
    AddOrViewProfileComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    TableModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    AuthorizationModule,
    RouterModule.forChild(routes),
  ],
  entryComponents:[AddProfileComponent, EditProfileComponent,ViewProfilesComponent]

})
export class ProfilesModule { }
