import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { AppStateService, HttpService } from 'src/app/services';
import { USER_MGMT_CNST } from 'src/app/constants';
import { isNullOrUndefined } from 'util';
import { EnvService } from 'src/app/services/env.service';

@Component({
  selector: 'app-add-profile',
  templateUrl: './add-profile.component.html',
  styleUrls: ['./add-profile.component.scss']
})
export class AddProfileComponent implements OnInit {
  profileData: any;

  isFormNotValid: boolean = true;
  isNameTouched: boolean = false;
  applicationList: any[] = [];
  userMetaInfo: any;

  constructor(
    public activeModal: NgbActiveModal,
    private httpSrv: HttpService,
    private appStateSrv: AppStateService,
    private envService : EnvService) { }

  ngOnInit() {
    this.userMetaInfo = this.appStateSrv.getUserMetaInfo();
    this.profileData =[];
    this.loadApplications();
  }
  
  loadApplications() {
    this.httpSrv
          .makeGetApiCall('APP_LIST',this.envService.baseUrl)
          .subscribe((response : any) => {
           this.applicationList = response;
          }, err => this.applicationList = []);
  }


  inputHasOnlyAlphabets(event: any) {
    const pattern = /^[ A-Za-z0-9_@./#()]*$/;
    let inputText = event.target.value;

    this.isFormNotValid = pattern.test(inputText) && inputText.length > 0 ? false : true;
    this.isNameTouched = true;
  }

  onFormSubmit(form: NgForm) {
    let { OPERATION_TYPES } = USER_MGMT_CNST; 

    //*If description was not entered set it to blank string
    form.value.description = isNullOrUndefined(form.value.description) ? '' : form.value.description;
    let customerDetails = {};
    if(this.userMetaInfo && this.userMetaInfo['customerId'] && this.userMetaInfo['customerName']){
      customerDetails = {
        customerId: this.userMetaInfo['customerId'],
        customerName: this.userMetaInfo['customerName']
      }
    }else{
      customerDetails = {
        customerId: null,
        customerName: null
      }
    }
    this.activeModal.close({
      operation: OPERATION_TYPES.PROFILE.ADD_PROFILE,
      postData: form.value,
      customerDetails: customerDetails
    })

    form.reset();
  }

}
