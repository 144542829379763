import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { USER_MGMT_CNST } from 'src/app/constants/proj.cnst';


@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss']
})
export class DeleteComponent implements OnInit {

  content :any;
  isAlertMessage: any;

  constructor(
    public activeModal: NgbActiveModal,
    private router: Router) { }

  ngOnInit() {
     let route = this.router.url.split('/')[1].toLowerCase();
     this.content = USER_MGMT_CNST.CONTENT_OPTIONS[route];
  }

  removeActiveTab(){
    this.activeModal.close(true);
  }

}
