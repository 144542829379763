import { isNullOrUndefined } from 'util';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators, FormArray, FormBuilder } from '@angular/forms';
import { LOCATION_WISE_DESIGNATION_LIST, ptypeCtye, USER_MGMT_CNST, UsersMapping, UsersMappingArea } from 'src/app/constants/proj.cnst';
import { AppStateService, HttpService } from 'src/app/services';
import { forkJoin, Observable, of } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { EnvService } from 'src/app/services/env.service';
import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {

  @Input() modalData;
  selectedStationsData:any={};
  @Input() editUserConfig;
  count=0

  selectedDashboards:any=[];
  emailPattern = USER_MGMT_CNST.EMAIL_PATTERN;
  formGroup: FormGroup;
  dashBoardNames: any[] = []; // Initialize it if not done already
  selectedOptionsCount: number = 0;
  selected
  metaGlobal:any={};
  dashboardkeys=[];
  dashboarddetailskeys={};
  regionNames:any=[]
  circleNames:any=[];
  divisionNames:any=[];
  subDivisionNames:any=[];
  sectionNames:any=[];
  isLocationHierarchy:boolean=false;
  locationList=[
    {"key":"State","value":"State"},
    {"key":"Region","value":"Region"},

  ]
  designationList=[
    
  ]
  customerName: string = null;
  keys:any=[];
  customerId : string = null;
  userName : string = null;
  visibleFields:any=[]
  mobileNumber : string = null;
  selectedDash= new FormControl('', []);
  userForm: FormGroup = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', []),
    // username: new FormControl('', [Validators.required]),
    status: new FormControl(true, [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    // mobileNo: new FormControl('', [Validators.required, Validators.pattern(/^[9876][0-9]{9}$/)]),
    newRole: new FormControl('', []),
    customer : new FormControl('', []),
    scope: new FormArray([]),
    dashboards:new FormControl([], []),
    roleName:new FormControl('', []),
    // dashboarddetails1: new FormGroup({}),

    // selectedDashboards: new FormControl('', []),
  })

  userDbSnapshot: any = {};
  applications: any[] = [];
  length:any=0;
  addedRoles : any = 1;
  selectedProfiles : any = [];
  selectedCustomers : any = [];
  selectedDashboardDetails:any= {};

  customer :any;

  roles : any[] = [];
  filteredRoles: any[] = [];
  selectedApplications: any = {};
  filterOptions: any = {}; // key: filterName, value: options.

  appIdMap: {[key: string]: any} = {}; // Application: ScopeEndPoint 

  currentRoleName : string = null;
  currentApplicationName : string = null;
  roleRemoved : boolean = false;
  fieldsToAdd: any;
  stateName: any[]=[];
  districtNames: any=[];
  blockNames: any=[];

  constructor(
    public activeModal: NgbActiveModal,
    private httpSrv: HttpService,
    private appStateSrv: AppStateService,
    private envService : EnvService,
    private dataSrv : DataService,
    private fb: FormBuilder,
    private http:HttpClient
  ) { }

  ngOnInit() {
    // this.userForm = this.fb.group({
    //   firstName: ['', Validators.required],
    //   lastName: [''],
    //   status: [true, Validators.required],
    //   email: ['', [Validators.required,]],
    //   newRole: [''],
    //   customer: [''],
    //   scope: this.fb.array([]),
    //   dashboards: [['']], // Set as an empty array
    //   dashboarddetails: [['']] // Set as an empty array
    // });
   

    //TODO:
    /**
     *  
     */

   
    

  

    let configLabelName = USER_MGMT_CNST.CONFIG_LABELS.EDIT_USER;
    let sortedConfigFields = this.dataSrv.sortConfigFields(this.editUserConfig.editUser, configLabelName);
    let reArrangedConfigFields = this.dataSrv.rearrangeConfigFields(sortedConfigFields, configLabelName);
    // console.log(reArrangedConfigFields);
    
    // TODO: Load Profiles
    this.loadData();

    let { applicationDetails } = this.modalData;
    let { roleName, applicationName } = applicationDetails;

    this.currentRoleName = roleName;
    this.currentApplicationName = applicationName;

    // TODO: Prepopulate user data.
    // this.prepopulateUserData(this.modalData);
    // this.userForm.controls['dashboards'].setValue(['Content Manage']);
  }

  loadData(){
    this.loadRoles();
  }

  loadRoles() {
    this.filteredRoles = [];
    let userId =  this.modalData.userId;
    let options = {
      uriParams:{
        userId : userId
      }
    }
   
   
      this.httpSrv.makeGetApiCall('LIST_OF_PROFILES_FOR_USER', this.envService.baseUrl,options)
      .subscribe(roles => {
        this.roles = roles['response'] || [];
        this.loadScopeEndPoints();
        this.filteredRoles = this.roles.map(r => {
          if(r.roleDetailsJson && r.roleDetailsJson.validScopes) {
            r.roleDetailsJson.validScopes = r.roleDetailsJson.validScopes.map(s => (
              {...s, valuesLoaded: false, scopeValue: this.applications}
            ))
          }
          return r;
        });
      }, err => this.roles = []);
    // }
  }

  loadScopeEndPoints() {
    this.appIdMap = {};
    this.httpSrv.makeGetApiCall("GET_APPLICATIONS_LIST", this.envService.baseUrl)
    .subscribe(apps => {
      apps.forEach(app => {
        this.appIdMap[app.applicationId] = app;
      })
      // console.log("Scope EndPoints updated:: ", this.appIdMap);
      this.prepopulateUserData(this.modalData);
    }, err => console.log("Err:: ", err));
  }

  /**================== Scope Changes ======================== */
  get scopeArray(): FormArray {
    return this.userForm.get("scope") as FormArray;
  }

  getFiltersOfRole(index: number): any[] {
    const roleId = this.getRoleIdFromScopeArray(index);
    // console.log("RoleId:: ", roleId);

    if(!roleId) return [];

    return this.getValidScopeOfRole(roleId);
  }

  newRoleFormGroup(roleId: string, filters: any[]): FormGroup {
    // Build formControl obj.
    let formControls = {
      roleId: new FormControl(roleId, []),
    }

    // Build formControls for filters(scope) and push it to formControl Obj..
    filters.forEach(f => {
      if(f.scopeName) {
        // Filters are not required
        // TODO: Replace scope name with scopeId()
        formControls[f.scopeName] = new FormControl([], []);
      }
    })

    // Build formgroup with roleId and filters and return it.
    return new FormGroup(formControls);
  }

  addRole(roleId: string = '') {
    // fetch the new role
    const newRoleId = roleId || this.userForm.get('newRole').value;
    
    if(!newRoleId) return;

    // Get role info
    const roleInfo = this.getRoleInfoById(newRoleId);
    if(roleInfo&&roleInfo.roleName){

      this.userForm.controls['roleName'].setValue(roleInfo.roleName);
    }
    // this.userForm.setValue({
    //   roleName:roleInfo.roleName
    // })
    // console.log("roleID",roleId,)

    if(roleInfo && roleInfo.applicationId) {
      this.roleRemoved = false;
      this.selectedApplications[roleInfo.applicationId] = true;
    }

    // Get the filters for role.
    const filters = this.getValidScopeOfRole(newRoleId);

    // Find the list of filters for which we need to load the values. 
    const loadValuesForFilters = filters.filter(f => {
      const filterId = this.getScopeId(f);
      return !this.filterOptions[filterId];
    });

    this.loadFilterOptions(newRoleId, loadValuesForFilters);

    this.scopeArray.push(this.newRoleFormGroup(newRoleId, filters));

    // Filter the roles dropdown.
    this.filterRoles();

    // reset the new role value.
    this.userForm.get('newRole').reset();
  }

  removeScope(index: number) {
    const roleId = this.getRoleIdFromScopeArray(index);
    const roleInfo = this.getRoleInfoById(roleId);
    this.dashBoardNames=[];
    this.count=0;

    if (!isNullOrUndefined(roleInfo)) {
      this.roleRemoved = false;
      if (this.selectedApplications[roleInfo.applicationId]) {
        delete this.selectedApplications[roleInfo.applicationId];
      }
    } else {
      this.roleRemoved = true;
    }
    this.userForm.get('roleName').setValue('');

    this.scopeArray.removeAt(index);
    this.filterRoles();
  }

  filterRoles() {
    // Filter the roles dropdown.
    this.filteredRoles = this.roles.filter(r => !this.selectedApplications[r.applicationId]);
  }

  getApplicationScopeEndPoint(appId: string) {
    if(!appId) return null;
    return this.appIdMap[appId] ? this.appIdMap[appId].scopeEndPoint : null;
  }

  getApplicationIdOfRole(roleId: string) {
    if(!roleId) return null;

    const roleInfo = this.roles.find(r => r.roleId === roleId);
    return roleInfo ? roleInfo.applicationId : null;
  }

  getScopeId(scope: any) {
    if(!scope) return null;

    return `${scope.scopeType}__${scope.scopeName}`;
  }
 selectedDashboard(event){
  this.selectedDashboards=event
 
 


 }
 addModule(){
  this.selectedDashboardDetails={};
  for(let i in this.userForm.get('dashboards').value){
    // let options = {uriParams: {userId : this.userForm.get('dashboards').value[i]}};
    let options = {
      uriParams: {
        userId: this.userForm.get('dashboards').value[i]
          .replace(/module/gi, '') // Remove the word "module" (case-insensitive)
          .replace(/\s+/g, '') // Remove all spaces
      }
    };
    const dynamicProperties = {
      [this.userForm.get('dashboards').value[i]]: [[],[]],}
      let dashboarddetailsFormGroup = this.userForm.get('dashboarddetails1') as FormGroup;
  if (!dashboarddetailsFormGroup) {
      // If it doesn't exist, create a new FormGroup and assign it to dashboarddetailsFormGroup
      dashboarddetailsFormGroup = new FormGroup({});
      this.userForm.addControl('dashboarddetails1', dashboarddetailsFormGroup);
  }
  // Add properties to the dashboarddetails FormGroup dynamically
  for (const [key, value] of Object.entries(dynamicProperties)) {
    const [defaultValue, validators] = value;
    dashboarddetailsFormGroup.addControl(key, new FormControl(defaultValue, validators));
  }

    
    
    //  this.httpSrv
    //      .makeGetApiCall('DASHBOARDS', this.envService.uiBaseUrl, options)
    //      .subscribe((response: any) => {
    //       if(typeof response =="object"){

    //         this.selectedDashboardDetails[this.userForm.get('dashboards').value[i]]=response;
    //         this.selectedStationsData[this.userForm.get('dashboards').value[i]]=[];
    //       }else{
    //         this.selectedDashboardDetails[this.userForm.get('dashboards').value[i]]=[];
    //         this.selectedStationsData[this.userForm.get('dashboards').value[i]]=[];
    //       }
           
    //        this.length=Object.keys(this.selectedDashboardDetails).length
    //      this.keys=Object.keys(this.selectedDashboardDetails);

    //        return response;
           
    //      }, error => {
    //       if(error){

    //         this.selectedDashboardDetails[this.userForm.get('dashboards').value[i]]=[];
    //       }
    //        console.log("err",error)
    //      })
         
   }

 }
 
 selectedDashboardDetails2(event:any,key1:any){
  this.selectedStationsData[key1] = [];
  for(let i in event){
  let obj={};
  for(let key in event[i]){
      if(key!=="locationID"){

        obj[key]=event[i][key];
      }
    
  }
  if (this.selectedStationsData[key1].every(item => !_.isEqual(item, obj))) {
    this.selectedStationsData[key1].push(obj);
}


  }
  
  

 }
 deselectedDashboardDetails(event: any, key: string) {
  // Handle deselection here
  // You may want to update your data model accordingly
}
  getRoleIdFromScopeArray(index: number) {
    if(isNullOrUndefined(index)) return null;

    if(!this.scopeArray.controls || !this.scopeArray.controls[index]) return null;
    
    return this.scopeArray.controls[index].get('roleId').value;
  }

  getRoleName(index: number, roleName : string): string {
    const roleId = this.getRoleIdFromScopeArray(index);

    if(!roleId) return '';
    const roleInfo = this.getRoleInfoById(roleId);
    
     if(roleInfo&&roleInfo.roleName==="Reservoir Data Team"){
        if(this.count==0){
          let data=this.setTableData();
          this.count+=1;
        }
     }else{
      this.dashBoardNames=[];
      this.count=0;
     }
    return roleInfo ? roleInfo.roleName+' ('+roleInfo.applicationName+")" : this.currentRoleName +' ('+this.currentApplicationName+")"
     
  }
  setTableData() {
    this.httpSrv
      .makeGetApiCall('GET_RESOURCES_LIST', this.envService.baseUrl)
      .subscribe((response: any) => {
        if(response&&response.length>0){
          for(let i in response){
            let obj={}
            if(response[i].resourceTypeName=="Dashboards"&&response[i].resourceName=="RESERVOIR MODULE"){
              obj['value']=response[i].resourceName
              obj['label']=response[i].resourceName
              obj['id']=response[i].resourceId
              this.dashBoardNames.push(obj)
            }
           
          }
        }
        return response;
        
      }, error => {
        console.log("err",error)
      })
  }

  getRoleInfoById(roleId: string) {
    return this.roles.find(r => r.roleId === roleId);
  }

  getValidScopeOfRole(roleId: string): any[] {
    const roleInfo = this.getRoleInfoById(roleId);
    if(!roleInfo) return [];

    if(!roleInfo.roleDetailsJson || !roleInfo.roleDetailsJson.validScopes) return [];

    return roleInfo.roleDetailsJson.validScopes;
  }

  // TODO: replace with api call.
  fetchScopeValuesForFilter(roleId: string, scope: any): Observable<any> {
    // console.log("[fetchScopeValuesForFilter]:: roleId: ", roleId, ", Scope:: ", scope);
    if(!roleId || !scope) return of([]);

    // fetch application id of role.
    const applicationIdOfRole = this.getApplicationIdOfRole(roleId);
    // console.log("Application Id Of role: ", applicationIdOfRole);
    if(!applicationIdOfRole) return of([]);

    // Fetch scope end point of application.
    const applicationScopeEndPoint = this.getApplicationScopeEndPoint(applicationIdOfRole);
    // console.log("applicationScopeEndPoint:: ", applicationScopeEndPoint);
    if(!applicationScopeEndPoint) return of([]);

    return this.httpSrv.makeGetApiCall('GET_SCOPE_VALUES_OF_TYPE', applicationScopeEndPoint, {
      replaceParams: {
        REPLACE_SCOPE_TYPE: scope.scopeType,
        REPLACE_SCOPE_NAME: scope.scopeName
      }
    });
    // const scopeId = this.getScopeId(scope);
    // if(scopeId === this.getScopeId({scopeType: "NON_HIERARCHY", scopeName: 'Application'})) {
    //   return new Promise((res, rej) => res(this.applications));
    // }
    // return new Promise((res, rej) => res([]));
  }

  loadFilterOptions(roleId, filters) {
    // console.log("[loadFilterOptions]:: filters: ", filters);
    // let promises = filters.map(f => this.httpSrv.makeGetApiCall(
    //   'GET_SCOPE_VALUES', this.envService.baseUrl, {scopeType: f.scopeType, scopeName: f.scopeName}
    // ).toPromise());
    let promises = filters.map(f => this.fetchScopeValuesForFilter(roleId, f));

    forkJoin(promises)
    .subscribe((res: any) => {
      // console.log("LoadFilterOptions:: res: ", res);
      filters.forEach((f, i) => {
        const data = res[i];
        const filterOptions = data.response ? data.response  : [];
        this.filterOptions[this.getScopeId(f)] = filterOptions;
        // console.log("Filter Options ", this.filterOptions);
      });
    }, err => {
      console.log("Err:: ", err);
    })
  }


  //TODO: Need to have autocomplete search for all values.
  handleAutoCompleteSuggestions(index: number, filter: any) {
    // console.log("[handleAutoCompleteSuggestions]: ", index, filter);
    if(!index || !filter) return;

    filter.scopeValue = ['Search'];
  }
  /**============== End of scope changes ======================== */


  




  getRegions(userDetailsJson?:any){
    let postData={
    pType:'REGION',
    cType:'REGION',
   
  }
  const result = 'region' + '_uuid';
    const controlName = 'region';

  this.getData(postData).subscribe((res: any) => {
    this.regionNames=[];
  
    for(let key in res){
      let obj={}
      obj["value"]=key;
      obj["label"]=res[key];
      this.regionNames.push(obj);
      if (userDetailsJson&&userDetailsJson.hierarchyDetails&&userDetailsJson.hierarchyDetails[result]) {
        if (obj['value'] === userDetailsJson.hierarchyDetails[result]) {
          // Make sure the form control name exists and is correct
         console.log("equal")
    
          // Check if the control exists in the form before setting its value
          if (this.userForm.controls[controlName]) {
            this.userForm.controls[controlName].setValue(obj);
          } else {
            console.error(`Form control for ${controlName} does not exist.`);
          }
          if(userDetailsJson){
            this.callFunction(controlName.toUpperCase(),userDetailsJson)
          }
        }
      }

    }
  }, err => console.log(err));

  }
  getData(postdata) {
    return this.http.post(
      // environment.url.PLAY_URL + 'get-loc-types',
     'https://tnswip.vassarlabs.com/encroachmentApi/emApi/'+'get-locations',


      postdata
    );
  }


  callFunction(item: string,userDetailsJson?:any) {
    console.log("item",item)
    switch (item) {
      case 'REGION':
        this.getCircles(item.toUpperCase(),userDetailsJson);
        this.resetFormControls('circle');
        this.showNextField('circle');
        break;
      case 'CIRCLE':
        this.getDivisions(item.toUpperCase(),userDetailsJson);
        this.resetFormControls('division')
        this.showNextField('division');
        break;
      case 'DIVISION':
        this.getsubDivisions(item.toUpperCase(),userDetailsJson);
        this.showNextField('subDivision');
        this.resetFormControls('subDivision')
        break;
      case 'SUBDIVISION':
        this.getsections('SUB DIVISION',userDetailsJson);
        this.showNextField('section');
        this.resetFormControls('section')
        break;
      case 'STATE':
        this.getdistricts(item.toUpperCase(),userDetailsJson);
        this.showNextField('district');
        this.resetFormControls('district')
        break;
      case 'DISTRICT':
          this.getblocks(item.toUpperCase(),userDetailsJson);
          this.showNextField('block');
          this.resetFormControls('block')
          break;
           


      default:
        console.log(`No action for ${item}`);
    }
  }

  // Show the next field in the hierarchy if available
  showNextField(nextField: string) {
    let designation = this.userForm.value.designation.value;
designation = designation.replace(/\s+/g, '_');
    if (UsersMapping[designation].includes(nextField)) {
      this.visibleFields.push(nextField);
    }
  }
  getDataList(item) {
    switch (item) {
      case 'region':
      return this.regionNames;
      case 'circle':
        return this.circleNames;
      case 'division':
        return this.divisionNames;
      case 'subDivision':
        return this.subDivisionNames;
      case 'section':
       return this.sectionNames;
      case 'district':
        return this.districtNames;
      case 'state':
         return this.stateName;
      case 'block':
        return this.blockNames;
      
      default:
        return [];
    }
  }

  


  getCircles(key,userDetailsJson?:any){

      let postData={
        pType:key,
        cType:ptypeCtye[key],
        pUUID:this.userForm.value.region.value
      }
      const result = ptypeCtye[key].toLowerCase() + '_uuid';
    const controlName = ptypeCtye[key] ? ptypeCtye[key].toLowerCase() : '';
      if(this.fieldsToAdd.includes("circle")){
        this.getData(postData).subscribe((res: any) => {
          this.circleNames=[];
          for(let key in res){
            let obj={}
            obj["value"]=key;
            obj["label"]=res[key];
            this.circleNames.push(obj);
            if (userDetailsJson&&userDetailsJson.hierarchyDetails&&userDetailsJson.hierarchyDetails[result]) {
              if (obj['value'] === userDetailsJson.hierarchyDetails[result]) {
                // Make sure the form control name exists and is correct
               console.log("equal")
          
                // Check if the control exists in the form before setting its value
                if (this.userForm.controls[controlName]) {
                  this.userForm.controls[controlName].setValue(obj);
                } else {
                  console.error(`Form control for ${controlName} does not exist.`);
                }
                if(userDetailsJson){
                  this.callFunction(controlName.toUpperCase(),userDetailsJson)
                }
              }
            }
  
          }
        
        }, err => console.log(err));
      }

    


  }
 
  getDivisions(key,userDetailsJson?:any){

      let postData={
        pType:key,
        cType:ptypeCtye[key],
        pUUID:this.userForm.value.circle.value
      }
      const result = ptypeCtye[key].toLowerCase() + '_uuid';
    const controlName = ptypeCtye[key] ? ptypeCtye[key].toLowerCase() : '';
      if(this.fieldsToAdd.includes("division")){


        this.getData(postData).subscribe((res: any) => {
          this.divisionNames=[];
  
          for(let key in res){
            let obj={}
            obj["value"]=key;
            obj["label"]=res[key];
            this.divisionNames.push(obj);
            if (userDetailsJson&&userDetailsJson.hierarchyDetails&&userDetailsJson.hierarchyDetails[result]) {
              if (obj['value'] === userDetailsJson.hierarchyDetails[result]) {
                // Make sure the form control name exists and is correct
               console.log("equal")
          
                // Check if the control exists in the form before setting its value
                if (this.userForm.controls[controlName]) {
                  this.userForm.controls[controlName].setValue(obj);
                } else {
                  console.error(`Form control for ${controlName} does not exist.`);
                }
                if(userDetailsJson){
                  this.callFunction(controlName.toUpperCase(),userDetailsJson)
                }
              }
            }
  
          }
        
        }, err => console.log(err));
      }else{
      }

    


  }
  getsubDivisions(key,userDetailsJson?:any){
      let postData={
        pType:key,
        cType:ptypeCtye[key],
        pUUID:this.userForm.value.division.value
      }
    //   const result = ptypeCtye[key].toLowerCase() + '_uuid';
    // const controlName = ptypeCtye[key] ? ptypeCtye[key].toLowerCase() : '';
    let rawString = ptypeCtye[key] ? ptypeCtye[key].toLowerCase() : '';

  // If the string contains spaces, remove spaces and capitalize the first letter of each word after the first one
  let result = rawString.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
    if (index === 0) return match.toLowerCase(); // Keep first word lowercase
    return match.toUpperCase(); // Capitalize subsequent words
  }) ; 
  let controlName=result;
  result=result+ '_uuid'; 
  
  controlName=controlName.replace(/\s+/g, '')
  result=result.replace(/\s+/g, '');
  console.log("result",result,controlName)
      if(this.fieldsToAdd.includes("subDivision")){
      this.getData(postData).subscribe((res: any) => {
        this.subDivisionNames=[];
        for(let key in res){
          let obj={}
          obj["value"]=key;
          obj["label"]=res[key];
          this.subDivisionNames.push(obj);
          if (userDetailsJson&&userDetailsJson.hierarchyDetails&&userDetailsJson.hierarchyDetails[result]) {
            if (obj['value'] === userDetailsJson.hierarchyDetails[result]) {
              // Make sure the form control name exists and is correct
             console.log("equal")
        
              // Check if the control exists in the form before setting its value
              if (this.userForm.controls[controlName]) {
                this.userForm.controls[controlName].setValue(obj);
              } else {
                console.error(`Form control for ${controlName} does not exist.`);
              }
              if(userDetailsJson){
                this.callFunction(controlName.toUpperCase(),userDetailsJson)
              }
            }
          }

        }
      
      }, err => console.log(err));

      }


  }

  getsections(key,userDetailsJson?:any){

      let postData={
        pType:key,
        cType:ptypeCtye[key],
        pUUID:this.userForm.value.subDivision.value
      }
      const result = ptypeCtye[key].toLowerCase() + '_uuid';
    const controlName = ptypeCtye[key] ? ptypeCtye[key].toLowerCase() : '';
      if(this.fieldsToAdd.includes("section")){
      this.getData(postData).subscribe((res: any) => {
        this.sectionNames=[];
        for(let key in res){
          let obj={}
          obj["value"]=key;
          obj["label"]=res[key];
          this.sectionNames.push(obj);
          if (userDetailsJson&&userDetailsJson.hierarchyDetails&&userDetailsJson.hierarchyDetails[result]) {
            if (obj['value'] === userDetailsJson.hierarchyDetails[result]) {
              // Make sure the form control name exists and is correct
             console.log("equal")
        
              // Check if the control exists in the form before setting its value
              if (this.userForm.controls[controlName]) {
                this.userForm.controls[controlName].setValue(obj);
              } else {
                console.error(`Form control for ${controlName} does not exist.`);
              }
              if(userDetailsJson){
                this.callFunction(controlName.toUpperCase(),userDetailsJson)
              }
            }
          }

        }
      
      }, err => console.log(err));

    
    }else{
    }

  }
  prepopulateUserData(data) {
    console.log("data",data)
    if(!data) return;

    const roles = (data.applications || []).filter(a => !!a.roleId).map(a => a.roleId);
    this.userForm.disable();
    this.scopeArray.clear();
    let roleName1=data['applicationDetails'].roleName;

    let globalScopeObject = JSON.parse(data['globalScope']);
    let keys=[];
    let obj={};
    let defaultDesignation;
    if(globalScopeObject!==null&&globalScopeObject.hasOwnProperty('data')){
      keys= Object.keys(globalScopeObject.data);
      this.metaGlobal=globalScopeObject.data;
      // this.dashboardkeys=Object.keys(globalScopeObject.dtnswipCustomerata);
 this.dashboarddetailskeys=globalScopeObject;
 
    }

// Now you can access properties within the 'globalScope' object
 if(keys.length>0){

   this.userForm.setValue({
     firstName: data.firstName || '',
     lastName: data.lastName || '',
     // username: data.username || '',
     email: data.emailId || '',
     // mobileNo: data.mobileNo || '',
     status: isNullOrUndefined(data.status)? true : data.status,
     newRole: null,
     customer : this.customerName,
     scope: [], // TODO: Prepopulate scope,
     dashboards:keys,
    //  designation:defaultDesignation,
    //  dashboarddetails:this.dashboarddetailskeys,
     roleName:roleName1,
    //  dashboarddetails1:this.dashboarddetailskeys,
     
   });
  //  this.checkDesignation()
 }else{
  this.userForm.setValue({
    firstName: data.firstName || '',
    lastName: data.lastName || '',
    // username: data.username || '',
    email: data.emailId || '',
    // mobileNo: data.mobileNo || '',
    status: isNullOrUndefined(data.status)? true : data.status,
    newRole: null,
    customer : this.customerName,
    scope: [], // TODO: Prepopulate scope,
    dashboards:keys,
    // dashboarddetails:this.dashboarddetailskeys,
    roleName:roleName1,
    // dashboarddetails1:this.dashboarddetailskeys
  });
  
 }

    this.addModule();
  //   const dashboarddetailsFormGroup = this.userForm.get('dashboarddetails1') as FormGroup;
  //   if (dashboarddetailsFormGroup) {
  //     // If it doesn't exist, create a new FormGroup and assign it to dashboarddetailsFormGroup
  //     for (const key of Object.keys(dashboarddetailsFormGroup.controls)) {
  //       // Check if the key exists in dashboarddetailskeys array
  //       if (this.dashboarddetailskeys.hasOwnProperty(key)) {
  //           const control = dashboarddetailsFormGroup.get(key);
  //           if (control) { // Check if control is not null
  //               // Set the value to the corresponding value in dashboarddetailskeys
  //               control.setValue(this.dashboarddetailskeys[key]);
  //           }
  //       }
  //   }
  // }
// Set values based on dashboarddetailskeys


    this.httpSrv.makeGetApiCall('GET_USER_PERMISSIONS', this.envService.baseUrl, {uriParams:  {userId: data.userId}})
    .subscribe(res => {
      // console.log("UserInfo:: ", res);
      // const roles =  
      const metaInfo = res.response.meta;
      if(metaInfo.userDetailsJson&&metaInfo.userDetailsJson.data&&metaInfo.userDetailsJson.data.locationHierarchy){
        
        // this.fieldsToAdd.forEach(field => {
        //   this.userForm.addControl(field, new FormControl('', [Validators.required]));
        // });
        
          this.userForm.addControl("location", new FormControl('', [Validators.required]));
          this.locationList.forEach(
            obj=>{
              console.log("obj",obj)
              if(obj.key==metaInfo.userDetailsJson.data.locationHierarchy){
                this.userForm.controls['location'].setValue(obj);
              }
            }
          )
          if(metaInfo.userDetailsJson.data.designation){
             this.checkLocation(metaInfo.userDetailsJson)
          }
          this.isLocationHierarchy=true;

      }
      const permissions = res.response && res.response.permissions ? res.response.permissions : {};
      this.userDbSnapshot = res.response;

      if (Object.keys(metaInfo).length > 0) {
        let { customerName, username, mobileNo, customerId } = metaInfo; 

        this.customerName = customerName;
        this.userName = username;
        this.mobileNumber = mobileNo;
        this.customerId = customerId;
      }

      // console.log("permissions:: ", permissions);
      let roles: any[] = [];
      for(let key in permissions) {
        // console.log('key:: ', key);
        let { applicationId, applicationName, roleId, roleName, userRoleScope } = permissions[key];
        roles.push({ applicationId, applicationName, roleId, roleName, userRoleScope });
      }
      // console.log("role: ", roles);

      roles.forEach((r, i) => {
        this.selectedApplications[r.applicationId] = true;
        this.addRole(r.roleId);
        const scopeGroupValue = {
          roleId: r.roleId
        }
        if(r.userRoleScope && r.userRoleScope.scope) {
          // console.log("scope:: ", r.userRoleScope);
          r.userRoleScope.scope.forEach(s => {
            scopeGroupValue[s.scopeName] = s.scopeValue;
          })
        }
        this.prepopulateFiltersOfRole(i, scopeGroupValue);
      })
      this.userForm.enable();
    }, err => {
      console.log("error loading user info: ", err);
      this.userForm.enable();
    })
    // roles,
    // TODO: update filteredRoles depends on the user scope.
  }

  prepopulateFiltersOfRole(index: number, values: any) {
    if(isNullOrUndefined(index) || !values || !values.roleId) return;

    // console.log("[prepopulateFiltersOfRole]:: values: ", values);
    if(this.scopeArray.controls.length <= index) return;

    // console.log("Prepopulating");
    for(let key in values) {
      // console.log("key:: ", key);
      if(this.scopeArray.controls[index].get(key)) {
        // console.log("Updating ",key," value with ", values[key]);
        this.scopeArray.controls[index].get(key).setValue(values[key]);
      }
    }
  }

  // TODO: move this logic to directive.
  onlyAlphabet(event: any) {
    const pattern = /[a-zA-Z ]/;

    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  //! Not in use.
  // Constraint: One role per application
  // If we select duplicate role in application then latest one will be saved.
  handleRoleChange(ev: any[]) {
    const selectedRole = ev[ev.length - 1];
    const roleIndex = ev.findIndex(r => r.roleId === selectedRole.roleId);
    const roles = roleIndex !== ev.length - 1 || roleIndex !== -1 ? ev.splice(roleIndex, 1) : ev;
    this.userForm.get('roles').setValue(roles.map((r: any) => r.roleId));
  }

  removeRole(role: any) {
    // console.log("Deleted Role:: ", role);
  }

  handleUpate() {
    // console.log("[HandleUpdate]:: ", this.userForm.value);
    if(this.userForm.invalid) {
      console.log("invalid edit form: ", this.userForm.errors);
      return;
    }

    // console.log("Calling Handle Update .....")
    const updatedUserInfo = this.userForm.value;
    const userInfo = {...this.modalData, email: this.modalData.emailId};

    const scope = updatedUserInfo.scope || [];
    const userRoleMapping = {};

    scope.forEach(s => {
      // fetch existing role info 
      // if not present create a new entry.
      userRoleMapping[s.roleId] = userRoleMapping[s.roleId] || {};

      // Get filter of role
      const filtersOfRole = this.getValidScopeOfRole(s.roleId);

      // Build scope of the role.
      const scope = [];
      filtersOfRole.forEach(filter => {
        const scopeObj = {
          scopeType: filter.scopeType,
          scopeName: filter.scopeName,
          scopeValue: s[filter.scopeName]
        }
        scope.push(scopeObj);
      });

      // Fetch existing scope if any.
      userRoleMapping[s.roleId].scope = userRoleMapping[s.roleId].scope || [];
      
      // add the current scope to the role.
      userRoleMapping[s.roleId].scope = userRoleMapping[s.roleId].scope.concat(scope);
    })
    // console.log("userRoleMapping:: ", userRoleMapping);

    let roleIdToNameMapping = {};
    this.roles.forEach(r => roleIdToNameMapping[r.roleId] = r.roleName);
    // console.log("RoleIdToNameMapping:: ", roleIdToNameMapping);

    // Compare the updatedRoleMapping with dbSnapshot
    // And move the roles to appropriate buckets(addRoles,UpdateRole,deleteRole)
    const existingPermissions = this.userDbSnapshot.permissions || {};
    const existingRolesOfUser = {};
    Object.values(existingPermissions).forEach((p:any) => existingRolesOfUser[p.roleId] = p);
    // console.log("existingRolesOfUser:: ", existingRolesOfUser);

    let addRoles = {};
    let updateRoles = {};
    let deleteRoles = [];

    // Find New roles
    // If role not found in existingPermissions then it's a new one.
    for(let role in userRoleMapping) {
      const roleName = roleIdToNameMapping[role];
      // console.log("Role name for ", role, " is:: ", roleName);
      if(existingRolesOfUser[role]) {
        // TODO: check for scope if scope chagned then only push it to updateRoles.
        // console.log("Existing role check for scope");
        for(let key in existingRolesOfUser ){
          if(existingRolesOfUser[key].roleName=="Customer Administrator"){
            updateRoles={};

          }else{
            updateRoles[role] = userRoleMapping[role]; 
          }

        }
        
      }

      if(!existingRolesOfUser[role]) {
        // console.log("new Role");
        addRoles[role] = userRoleMapping[role];
      }
    };

    // Find deleted Roles
    for(let role in existingRolesOfUser) {
      const roleName = roleIdToNameMapping[role];
      if(!userRoleMapping[role]) {
        // console.log("Delete role: ", roleName);
        deleteRoles.push(role);
      }
    }

    //TODO : Send meta information (username, mobileNo etc) only if these fields are changed
    let metaInfo :any=null;

    // console.log("Updated user info ", this.getMetaInfoStr(updatedUserInfo));
    // console.log("Old user info ", this.getMetaInfoStr(userInfo));

    let isMetaInfoChanged = this.getMetaInfoStr(updatedUserInfo) !== this.getMetaInfoStr(userInfo);

    if (!this.arraysEqual<string>(this.dashboardkeys, this.userForm.get('dashboards').value)) {
        isMetaInfoChanged = true;
    } if (Object.keys(this.dashboarddetailskeys).length > 0) {
        let allConditionsPassed = true; // Initialize the variable to true
    
        for (const key of Object.keys(this.dashboarddetailskeys)) {
            // Get the array from this.dashboarddetailskeys
            const arrayToCompare = this.dashboarddetailskeys[key];
    
            // Get the FormControl from dashboarddetails1 FormGroup
            const control = this.userForm.get('dashboarddetails1.' + key);
    
            // If the control doesn't exist or it's not a FormControl, set the variable to false and break the loop
            if (!control || !(control instanceof FormControl)) {
                allConditionsPassed = false;
                break;
            }
    
            // Check if the value of the FormControl matches the array
            if (!this.arraysEqual(control.value, arrayToCompare)) {
                allConditionsPassed = false;
                break;
            }
        }
        if (!allConditionsPassed) {
            isMetaInfoChanged = true; // Update isMetaInfoChanged if all conditions passed
        }
    }
    
    // return isMetaInfoChanged;
    

    // let keys=Object.keys(this.selectedStationsData)
    // if(keys.length>0){
    //   isMetaInfoChanged=true;
    // }
    if (isMetaInfoChanged) {

      metaInfo = updatedUserInfo;

      metaInfo = {
        ...metaInfo, 
        username : this.userName, 
        mobileNo : this.mobileNumber,
        customerName : this.customerName,
        customerId : this.customerId,
        "userDetailsJson": {
          "data": {}}
      }
      
    }

  
    const postData = {
      metaInfo,
      addRoles,
      updateRoles,
      deleteRoles,
      userId: this.modalData.userId,
    }

    const details = {
      operation: USER_MGMT_CNST.OPERATION_TYPES.USER.EDIT_USER,
      postData
    };

    if(isMetaInfoChanged){
      const dashboarddetails1FormGroup = this.userForm.get('dashboarddetails1') as FormGroup;
const dashboardDetailsValues: { [key: string]: any } = {};

if (dashboarddetails1FormGroup) {
    Object.keys(dashboarddetails1FormGroup.controls).forEach(controlName => {
        const control = dashboarddetails1FormGroup.get(controlName);
        if (control) {
            dashboardDetailsValues[controlName] = control.value;
        }
    });
}

const DashboardArrays = ['dashboards', 'dashboarddetails1', 'dashboarddetails'];
// Now you have an object `dashboardDetailsValues` containing all control values from the `dashboarddetails1` FormGroup
DashboardArrays.forEach(element => {
  if (details.postData.metaInfo.hasOwnProperty(element)) {
      // If the key exists, delete it from metaInfo
      delete details.postData.metaInfo[element];
  }
});




    details.postData.metaInfo={...details.postData.metaInfo}
   
    let designation = this.userForm.value.designation.value;
    designation = designation.replace(/\s+/g, '_');
  
   let control;
   let controlValue;
   let primaryName;
   let primaryUuid;
  
    // Check if designationKey exists in UsersMappingArea
    if (UsersMappingArea.hasOwnProperty(designation)) {
      let formcontrolKey = UsersMappingArea[designation].toLowerCase(); // No optional chaining needed here
     if(formcontrolKey=="sub division"){
      formcontrolKey="subDivision";
     }
  
      control = this.userForm.get(formcontrolKey);
      controlValue = control ? control.value : null;
  
       primaryName = controlValue && typeof controlValue === 'object' && 'label' in controlValue
        ? controlValue.label
        : '';
        primaryUuid = controlValue && typeof controlValue === 'object' && 'value' in controlValue
        ? controlValue.value
        : '';
  
     
  
    }
    let hierarchyDetails: any = {
        
    };
    if (UsersMapping.hasOwnProperty(designation)) {
     
      const levels = UsersMapping[designation]; // Levels associated with designation
    
    
      levels.forEach((level: string) => {
        console.log("level",level)
        const formControlKey = level!="subDivision"?level.toLowerCase():level; // Form control key
        const control = this.userForm.get(formControlKey); // Get form control
        const controlValue = control ? control.value : null; // Get form control value
    
        if (controlValue) {
          const uuid =
            typeof controlValue === 'object' && 'value' in controlValue
              ? controlValue.value
              : null;
          const name =
            typeof controlValue === 'object' && 'label' in controlValue
              ? controlValue.label
              : null;


    details.postData.metaInfo={...details.postData.metaInfo}
    
    details.postData.metaInfo={...details.postData.metaInfo}
        
          hierarchyDetails[`${level}_uuid`] = uuid;
          hierarchyDetails[`${level}_name`] = name;
        }
      });
    
      console.log(hierarchyDetails); // Output the constructed object
    }
    let userDetailsJson={
          data:{
            "designation":this.userForm.value.designation.key,
            "location_type":UsersMappingArea[designation],

            "primary_uuid":primaryUuid,
            "primary_name":primaryName,
            "secondary_uuid":null,
            "secondary_name":null,
            "locationHierarchy":this.userForm.value.location.key
          },
          hierarchyDetails:hierarchyDetails
        }
   

    if(Object.keys(this.dashboarddetailskeys).length>0){
  
        details.postData.metaInfo['userDetailsJson']=userDetailsJson;
      }else{
         details.postData.metaInfo['userDetailsJson']={}
      }
    
    let lastmetaData= {...this.selectedStationsData}
  
    // details.postData.metaInfo['userDetailsJson']['data']=lastmetaData;

    }
    
    this.appStateSrv.operationRef.next(details);
    this.activeModal.close();
  }


  getMetaInfoStr(userInfo: any) {
    if(!userInfo) return '';
    return JSON.stringify({
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      email: userInfo.email,
      status: userInfo.status,


    })
  }
  selectAlldashboards(){
    let all=[]
    for(let i in this.dashBoardNames){
      all.push(this.dashBoardNames[i].label)
      
    }
    this.userForm.controls['dashboards'].setValue(all);

  }
  unselectAlldashboards(){
    let all=[]
    this.userForm.controls['dashboards'].setValue(all);

  }
  selectAlldashboardsdetails(key){
    let all=[]
    for(let i in this.selectedDashboardDetails[key]){
      all.push(this.selectedDashboardDetails[key][i].ln)
    
      
    }
    this.selectedStationsData[key]=this.selectedDashboardDetails[key];
    this.userForm.controls['dashboarddetails'].setValue(all);
    const dynamicProperties = {
      [key]: [[],[]],}
      let dashboarddetailsFormGroup = this.userForm.get('dashboarddetails1') as FormGroup;
  if (!dashboarddetailsFormGroup) {
      // If it doesn't exist, create a new FormGroup and assign it to dashboarddetailsFormGroup
      dashboarddetailsFormGroup = new FormGroup({});
      this.userForm.addControl('dashboarddetails1', dashboarddetailsFormGroup);
  }
  
  // Add properties to the dashboarddetails FormGroup dynamically
  for (const [key, value] of Object.entries(dynamicProperties)) {
    const [defaultValue, validators] = value;
    dashboarddetailsFormGroup.addControl(key, new FormControl(defaultValue, validators));
  }
  
   
      // Check if the key exists in dashboarddetailskeys array
      if (this.selectedDashboardDetails.hasOwnProperty(key)) {
        const control = dashboarddetailsFormGroup.get(key);
        if (control) { // Check if control is not null
          // Set the value to the corresponding value in dashboarddetailskeys
          for(let i in this.selectedDashboardDetails[key]){
           
            control.setValue(all);
            
          }
        }
      }
    

  }
  unselectAlldashboardsdetails(key){
    let all=[]
    const dynamicProperties = {
      [key]: [[],[]],}
      let dashboarddetailsFormGroup = this.userForm.get('dashboarddetails1') as FormGroup;
  if (!dashboarddetailsFormGroup) {
      // If it doesn't exist, create a new FormGroup and assign it to dashboarddetailsFormGroup
      dashboarddetailsFormGroup = new FormGroup({});
      this.userForm.addControl('dashboarddetails1', dashboarddetailsFormGroup);
  }
  
  // Add properties to the dashboarddetails FormGroup dynamically
  for (const [key, value] of Object.entries(dynamicProperties)) {
    const [defaultValue, validators] = value;
    dashboarddetailsFormGroup.addControl(key, new FormControl(defaultValue, validators));
  }
    const control = dashboarddetailsFormGroup.get(key);
        if (control) { // Check if control is not null
          // Set the value to the corresponding value in dashboarddetailskeys
          for(let i in this.selectedDashboardDetails[key]){
           
            control.setValue(all);
            
          }
        }
        this.selectedStationsData[key]=[];


  }
  arraysEqual<T>(arr1: T[], arr2: T[]): boolean {
    // Check if the arrays have the same length
    if (arr1.length !== arr2.length) {
        return false;
    }

    // Check if each element is equal
    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
            return false;
        }
    }

    // If all elements are equal, return true
    return true;
}
 
setInitialVisibleFields() {
  let designation = this.userForm.value.designation.value;
  designation = designation.replace(/\s+/g, '_');
  
      this.visibleFields = UsersMapping[designation].slice(0, 1); // Initially only the first field
    
}

clearDynamicControls(location?:any) {
  const dynamicFields = ['region', 'circle', 'division', 'subDivision', 'section'];
  dynamicFields.forEach(field => {
    if (this.userForm.contains(field)) {
      this.userForm.removeControl(field);
    }
  });
  if(location){
    this.fieldsToAdd=[];
  }
}
shouldDisplayField(item: string) {
  return this.visibleFields.includes(item);
}

capitalizeFirstLetter(item: string): string {
  if (!item) return item;
  return item.charAt(0).toUpperCase() + item.slice(1).toLowerCase();
}

checkLocation(userDetailsJson?: any){
  const locationControl = this.userForm.get('location')
  const location = this.userForm.value.location.key; //
  console.log("location",locationControl)
  this.designationList=LOCATION_WISE_DESIGNATION_LIST[location];
  if (locationControl.valid && !this.userForm.contains('designation')) {
    this.userForm.addControl('designation', new FormControl('', [Validators.required]));
  }else{
    
    this.clearDynamicControls(true);
    this.userForm.removeControl('designation');
    this.userForm.addControl('designation', new FormControl('', [Validators.required]));
  // this.setInitialVisibleFields();
  }
  if(userDetailsJson){
    this.designationList.forEach(
      obj=>{
        console.log("obj",obj)
        if(obj.key==userDetailsJson.data.designation){
          this.userForm.controls['designation'].setValue(obj);
        }
      }
    )
    this.checkDesignation(userDetailsJson)
  }
 
}


checkDesignation(userDetailsJson?:any) {
  let designation = this.userForm.value.designation.value;
designation = designation.replace(/\s+/g, '_');
  this.fieldsToAdd = UsersMapping[designation] || [];
  console.log("fieldsToAdd",this.fieldsToAdd)
  this.clearDynamicControls();
  this.setInitialVisibleFields();
  if (this.fieldsToAdd&&this.userForm.value.location.key=="Region") {
    this.fieldsToAdd.forEach(field => {
      this.userForm.addControl(field, new FormControl('', [Validators.required]));
    });
    this.getRegions(userDetailsJson);
  }else if (this.fieldsToAdd&&this.userForm.value.location.key=="State") {
    this.fieldsToAdd.forEach(field => {
      console.log("fileds",field)
      this.userForm.addControl(field, new FormControl('', [Validators.required]));
    });
    let data=  {
      "value":"e98cd5b7-6556-4c0f-a778-3429e1c14a6b",
      "label":"TAMILNADU"
      }
       this.stateName=[];
      this.stateName.push(data)
      this.userForm.controls['state'].setValue(data);
      if(userDetailsJson){
        this.callFunction(this.userForm.value.location.value.toUpperCase(),userDetailsJson)
      }
      this.showNextField('district');
  }
 
 
}
getdistricts(key,userDetailsJson?:any){
  console.log("coming",key,userDetailsJson)

  let postData={
    pType:key,
    cType:ptypeCtye[key],
    pUUID:this.userForm.value.state.value
  }
  if(this.fieldsToAdd.includes("district")){
    // this.userForm.get('section').setValue('');
    if (this.userForm.get('district')) {
      this.userForm.get('district').setValue('');
    }
    const result = ptypeCtye[key].toLowerCase() + '_uuid';
    const controlName = ptypeCtye[key] ? ptypeCtye[key].toLowerCase() : '';
  this.getData(postData).subscribe((res: any) => {
    this.districtNames=[];
    for(let key in res){
      let obj={}
      obj["value"]=key;
      obj["label"]=res[key];
      this.districtNames.push(obj);
     
      console.log("result",result,controlName)

      if (userDetailsJson&&userDetailsJson.hierarchyDetails&&userDetailsJson.hierarchyDetails[result]) {
        if (obj['value'] === userDetailsJson.hierarchyDetails[result]) {
          // Make sure the form control name exists and is correct
         console.log("equal")
    
          // Check if the control exists in the form before setting its value
          if (this.userForm.controls[controlName]) {
            this.userForm.controls[controlName].setValue(obj);
          } else {
            console.error(`Form control for ${controlName} does not exist.`);
          }
          if(userDetailsJson){
            this.callFunction(controlName.toUpperCase(),userDetailsJson)
          }
        }
      }

    }
  
  
  }, err => console.log(err));


}else{
}

}

getblocks(key,userDetailsJson?:any){
console.log("block",this.fieldsToAdd,key,userDetailsJson)
let postData={
  pType:key,
  cType:ptypeCtye[key],
  pUUID:this.userForm.value.district.value
}
if(this.fieldsToAdd.includes("block")){
  // this.userForm.get('section').setValue('');
  if (this.userForm.get('block')) {
    this.userForm.get('block').setValue('');
  }
  const result = ptypeCtye[key].toLowerCase() + '_uuid';
    const controlName = ptypeCtye[key] ? ptypeCtye[key].toLowerCase() : '';
this.getData(postData).subscribe((res: any) => {
  this.blockNames=[];
  for(let key in res){
    let obj={}
    obj["value"]=key;
    obj["label"]=res[key];
    this.blockNames.push(obj);
    if (userDetailsJson&&userDetailsJson.hierarchyDetails&&userDetailsJson.hierarchyDetails[result]) {
      if (obj['value'] === userDetailsJson.hierarchyDetails[result]) {
        // Make sure the form control name exists and is correct
       console.log("equal",controlName,result,this.fieldsToAdd)
  
        // Check if the control exists in the form before setting its value
        if (this.userForm.controls[controlName]) {
          this.userForm.controls[controlName].setValue(obj);
        } else {
          console.error(`Form control for ${controlName} does not exist.`);
        }
      }
    }

  }


}, err => console.log(err));


}else{
}

}


resetFormControls(field?: string) {
  const dynamicFields = ['region', 'circle', 'division', 'subDivision', 'section', 'state', 'district', 'block'];
  // If a specific field is provided, reset it
  if (field && this.userForm.get(field)) {
    this.userForm.get(field).setValue('');
  }

  // If no specific field is provided, reset all fields
  if (!field) {
    dynamicFields.forEach(fieldName => {
      if (this.userForm.get(fieldName)) {
        this.userForm.get(fieldName).setValue('');
      }
    });
  }

  // If 'circle' is reset, also reset related fields
  if (field === 'circle') {
    this.resetRelatedFields(['circle', 'division', 'subDivision', 'section']);
  }
  
  // If 'division' is reset, also reset related fields
  if (field === 'division') {
    this.resetRelatedFields(['division', 'subDivision', 'section']);
  }
  
  // If 'subDivision' is reset, also reset related fields
  if (field === 'subDivision') {
    this.resetRelatedFields(['subDivision', 'section']);
  }

  // If 'state' is reset, also reset related fields
  if (field === 'state') {
    this.resetRelatedFields(['district', 'block']);
  }

  // If 'district' is reset, also reset related fields
  if (field === 'district') {
    this.resetRelatedFields(['block']);
  }
}

// Helper method to reset a set of fields
resetRelatedFields(fields: string[]) {
  fields.forEach(field => {
    if (this.userForm.get(field)) {
      this.userForm.get(field).setValue('');
    }
  });
}

}