import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpService, AppStateService, UtilsService } from 'src/app/services';
import { USER_MGMT_CNST } from 'src/app/constants';
import { NgForm } from '@angular/forms';
import { isNullOrUndefined } from 'util';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { EnvService } from 'src/app/services/env.service';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-add-or-view-profile',
  templateUrl: './add-or-view-profile.component.html',
  styleUrls: ['./add-or-view-profile.component.scss']
})
export class AddOrViewProfileComponent implements OnInit, OnDestroy {

  profileData: any = {};
  // customerId:any;
  isFormNotValid: boolean = true;
  isNameTouched: boolean = false;
  applicationList: any[] = [];
  userMetaInfo: any;
  operation:string;
  // roleName:any;
  // customerNames:any=[];
  // userPermissionsInfo:any;
  isRoleNameNotValid : boolean = false;
  subscriptions : Subscription[] = [];

  constructor(
    private httpSrv: HttpService,
    private utilsSrv: UtilsService,
    private appStateSrv: AppStateService,
    private envService : EnvService,
    private dataSrv : DataService,
    private router: Router,
    private route : ActivatedRoute) { }

  ngOnInit() {

    let isProfileDataExists = this.utilsSrv.isObjectNotNullAndEmpty(this.appStateSrv.getProfileData());

    const queryParamRef = this.route.queryParams.subscribe(async (params : Params) => {
      let roleId = params['roleId'];
      let operation = params['operation'];

      this.operation = !isNullOrUndefined(operation) ? operation : this.operation;

      if (!isProfileDataExists) {

        let options = {
          'uriParams': {
            roleId
          }
        }

        if (operation === 'view') {

          let roleInfo = await this.dataSrv.getRoleInfoById(options);

          if (this.utilsSrv.isObjectNotNullAndEmpty(roleInfo)) {
            let { response } = roleInfo;

            if (this.utilsSrv.isObjectNotNullAndEmpty(response)) {
              this.buildProfileData(response);
            }
          }
        } 
      }
    })

    this.subscriptions.push(queryParamRef);

    if (isProfileDataExists) {

      let { operation, info } = this.appStateSrv.getProfileData();

      this.operation = operation;

      if (operation === 'view') {
        this.buildProfileData(info);
      }

    }

    this.userMetaInfo = this.appStateSrv.getUserMetaInfo();
    // this.userPermissionsInfo=this.appStateSrv.getUserPermissions();
    // for (let key in this.userPermissionsInfo){
    //   if(this.userPermissionsInfo[key]&&this.userPermissionsInfo[key].roleName){
    //     this.roleName=this.userPermissionsInfo[key].roleName
    //   }
    // }
    // console.log("this.userMetaInfo",this.userMetaInfo,this.userPermissionsInfo)
    this.loadApplications();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription : Subscription) => subscription.unsubscribe());
  }
  
  loadApplications() {
    this.httpSrv
          .makeGetApiCall('APP_LIST',this.envService.baseUrl)
          .subscribe((response : any) => {
           this.applicationList = response;
          }, err => this.applicationList = []);
  }
  // loadCustomersandAdmin(){
    
  //     console.log("hlo",this.profileData)
  //     if(this.roleName=="Super Administrator"){

  //       this.httpSrv.makeGetApiCall('GET_CUSTOMERS_LIST', this.envService.baseUrl)
  //       .subscribe(roles => {
  //         console.log("okkkkk",roles)
         
  //         if(roles){
  //           for(let key in roles){
  //             if(roles[key]){
             
  //           this.customerNames.push({"value":roles[key].customerId,"label":roles[key].customerName});
  //             console.log("thiscustomer",this.customerNames)
   
  //             }
  //           }
  //         }
         
         
  //        return roles;
       
          
  //       }, err => (console.log("hlo")));
  //     }
  // }


  inputHasOnlyAlphabets(event: any) {
    const pattern = /^[ A-Za-z0-9_@./#()]*$/;
    let inputText = event.target.value;

    this.isRoleNameNotValid = inputText.trim().length === 0 ? true : false;

    this.isFormNotValid = pattern.test(inputText) && inputText.length > 0 ? false : true;
    this.isNameTouched = true;
  }

  onFormSubmit(form: NgForm) {
    let { OPERATION_TYPES } = USER_MGMT_CNST; 

    //*If description was not entered set it to blank string
    form.value.description = isNullOrUndefined(form.value.description) ? '' : form.value.description;
    let customerDetails = {};
    if(this.userMetaInfo && this.userMetaInfo['customerId'] && this.userMetaInfo['customerName']){
      customerDetails = {
        customerId: this.userMetaInfo['customerId'],
        customerName: this.userMetaInfo['customerName']
      }
    }else{
      customerDetails = {
        customerId: null,
        customerName: null
      }
      // if(this.roleName=="Super Administrator"){
      //   if(this.customerId!=null||this.customerId!=undefined){
      //     customerDetails = {
      //       customerId:this.customerId,
      //       customerName: null
      //     }
      //   }
      // }
    }
    
    this.addProfile(form.value, customerDetails);
    form.reset();
  }

  addProfile(profileInfo: any,customerDetails) {
    let { name, description, application } = profileInfo;
    const userInfoStr = localStorage.getItem('userInfo');
    const userInfo = userInfoStr ? JSON.parse(userInfoStr) : null;

    let postData = {
      "meta": {
        roleName: name,
        description,
        applicationId: application,
        customerId: customerDetails['customerId'],
        createdBy:userInfo.userId
      }
    };
    this.httpSrv
      .makePostApiCall("ADD_PROFILE", postData, this.envService.baseUrl)
      .subscribe(
        (response: any) => {
          if (response.statusCode === 201) {
            this.utilsSrv.showToastMessage(response.message, "success");
            this.router.navigate(['','roles']);
          }
        },
        (err) => {
          
          let { error } = err;

          if (this.utilsSrv.isObjectNotNullAndEmpty(error)) {

            if (error.message.includes("Duplicate entry")) {
              this.utilsSrv.showToastMessage("Role name exists", "error");
            } else {
              this.utilsSrv.showToastMessage(
                "Error occured while creating role",
                "error"
              );
            }
          } else {
            this.utilsSrv.showToastMessage(
              "Error occured while creating role",
              "error"
            );
          }
        }
      );
  }

  buildProfileData(response : any) {
    let { roleName, applicationId, description, applicationName } = response;
    
    this.profileData['firstName'] = roleName;
    this.profileData['selectedApplication'] = applicationId;
    this.profileData['applicationName'] = applicationName;
    this.profileData['description'] = description;
    // this.profileData['customerName']
    console.log("profile Data ", this.profileData);
  }

  navigateToProfilesList() {
    this.router.navigate(['', 'roles']);
  }

}
