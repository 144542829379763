import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LOCATION_WISE_DESIGNATION_LIST, ptypeCtye, USER_MGMT_CNST, USER_MGMT_DEFAULT_CONFIG_CNST, UsersMapping, UsersMappingArea } from 'src/app/constants';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpService, UtilsService, AppStateService } from 'src/app/services';
import { EnvService } from 'src/app/services/env.service';
import { countryCodes } from '../../../../constants/country-codes';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { Country } from 'ngx-intl-tel-input/lib/model/country.model';
import { isNullOrUndefined } from 'util';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {

  userData: any;
  emailPattern: any = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
  isEmailValid : boolean = true;
  fieldsToAdd:any;

  @Input() addUserConfig;
  @Input() customerList;
  designationList=[
  
  ]
  locationList=[
    {"key":"State","value":"State"},
    {"key":"Region","value":"Region"},

  ]
  addApiCalled=false;
  constants = USER_MGMT_DEFAULT_CONFIG_CNST.ADD_USER_CONST;
  superAdmin: any = USER_MGMT_CNST.SUPER_ADMIN; 
  regionNames:any=[]
  countryCodeList : any[] = [...countryCodes];
  circleNames:any=[];
  divisionNames:any=[];
  subDivisionNames:any=[];
  sectionNames:any=[];
  blockNames:any=[];
  
  userForm: FormGroup = new FormGroup({
    // firstName: new FormControl('', [Validators.required]),
    // lastName: new FormControl('', []),
    // username: new FormControl('', [Validators.required]),
    // email: new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]),
    // mobileNo: new FormControl('', [Validators.required, Validators.pattern(/^[9876][0-9]{9}$/)]),
    // customers:  new FormControl('',[])
  });

  SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;

  primaryMobileForm = new FormGroup({
		primaryMobileNo: new FormControl(undefined, [Validators.required]),
	});

  templateTypes: any = {};
  userPermission:any;
  userMetaInfo: any;
  isCustomer: any;

  isPrimaryMobileNoValid : boolean = true;
  currentCountryCode : string = "in";
  visibleFields: any;
  stateName: any=[];
  districtNames:any=[];
  constructor(
    public activeModal: NgbActiveModal,
    private httpSrv: HttpService,
    private utilsSrv: UtilsService,
    private envService : EnvService,
    private appState: AppStateService,
    private http:HttpClient
  ) { }

  ngOnInit() {
    this.userData = [];
    this.userPermission = this.appState.getUserPermissions();
    this.userMetaInfo = this.appState.getUserMetaInfo();
    if(localStorage.getItem("designation")&&(localStorage.getItem("designation")=="EE")){
  this.designationList=[ {"key":"AEE","value":"AEE"},
    {"key":"AE","value":"AE"},]
    }
    
    this.isCustomer = this.userMetaInfo && (this.userMetaInfo['customerId']|| this.userMetaInfo['customerName']) ? true : false;

    if (!this.isCustomer) {
      this.userForm = new FormGroup({
        firstName: new FormControl('', [Validators.required]),
        lastName: new FormControl('', []),
        username: new FormControl('', [Validators.required,Validators.pattern(/^\S*$/)]),
        email: new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]),
        customers: new FormControl('', [Validators.required]),
        // designation: new FormControl('', [Validators.required]),
        location:new FormControl('', [Validators.required])
      });
    } else {
      this.userForm = new FormGroup({
        firstName: new FormControl('', [Validators.required]),
        lastName: new FormControl('', []),
        username: new FormControl('', [Validators.required,Validators.pattern(/^\S*$/)]),
        email: new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]),
        // designation: new FormControl('', [Validators.required]),
        location:new FormControl('', [Validators.required])
      });
    }
  }
  

 

  // TODO: move this logic to directive.
  onlyAlphabetInteger(event: any) {
    const pattern = /[a-zA-Z0-9]/;
  
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  onlyDigit(event: any) {
    const pattern = /[1-9]/;

    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  validateEmail(event: any) {
    let inputStr = event.target.value;
    this.isEmailValid = this.utilsSrv.isEmailValid(this.emailPattern, inputStr);
  }
  getRegions(){
    let postData={
    pType:'REGION',
    cType:'REGION',
   
  }

  this.getData(postData).subscribe((res: any) => {
    this.regionNames=[];
  
    for(let key in res){
      let obj={}
      obj["value"]=key;
      obj["label"]=res[key];
      this.regionNames.push(obj);

    }
  }, err => console.log(err));

  }
  getData(postdata) {
    return this.http.post(
      // environment.url.PLAY_URL + 'get-loc-types',
     'https://tnswip.vassarlabs.com/encroachmentApi/emApi/'+'get-locations',


      postdata
    );
  }





 

  
 
  getdistricts(key){
    console.log("coming")

    let postData={
      pType:key,
      cType:ptypeCtye[key],
      pUUID:this.userForm.value.state.value
    }
    if(this.fieldsToAdd.includes("district")){
      // this.userForm.get('section').setValue('');
      if (this.userForm.get('district')) {
        this.userForm.get('district').setValue('');
      }
    this.getData(postData).subscribe((res: any) => {
      this.districtNames=[];
      for(let key in res){
        let obj={}
        obj["value"]=key;
        obj["label"]=res[key];
        this.districtNames.push(obj);

      }
    
    
    }, err => console.log(err));

  
  }else{
  }

}



  // Add user to db.
  handleAdd() {

    if (this.userForm.invalid) {
      // TODO: handle errors.
      return;
    }

    //* If it is super admin need to check selected customer
    if(!this.isCustomer && (!this.userForm.value.customers || !this.userForm.value.customers.customerId || !this.userForm.value.customers.customerName)){
        this.utilsSrv.showToastMessage("Please select customer","error");
        return;
    }
    
    //* Create post data for add user
    const postdata = this.postDataCreationForAddUser();

    //* Api call for add user
    if(postdata){
      this.addApiCalled=true;
    }
    this.httpSrv.makePostApiCall('ADD_USER', postdata, this.envService.baseUrl)
      .subscribe(res => {
        this.addApiCalled=false;
        if (res.result) {
          this.utilsSrv.showToastMessage(
            "Successfully added new user",
            "success"
          );
          // if(this.userForm.value.designation.key=="EE"){
          //   this.updateUser(res.response, postdata);

  
          // }
          this.activeModal.close({ updateTable: true }); 
          

        } else {
          this.utilsSrv.showToastMessage(
            "Error occured while creating user",
            "error"
          );
        }
      }, err => {
        this.addApiCalled=false;
        let { error } = err;

        if (this.utilsSrv.isObjectNotNullAndEmpty(error)) {
          let { message } = error;

          if (!isNullOrUndefined(message)) {
            this.utilsSrv.showToastMessage(message, "error");
          } else {
            this.utilsSrv.showToastMessage(
              "Error occured while creating user",
              "error"
            );
          }

        } else {
          this.utilsSrv.showToastMessage(
            "Error occured while creating user",
            "error"
          );
        }
      })
  }

  //* post data creation for add user
  postDataCreationForAddUser(){
    const userInfoStr = localStorage.getItem('userInfo');
    const token = localStorage.getItem('token');
    const userInfo = userInfoStr ? JSON.parse(userInfoStr) : null;
    let designation = this.userForm.value.designation.value;
    designation = designation.replace(/\s+/g, '_');
  
   let control;
   let controlValue;
   let primaryName;
   let primaryUuid;
  
    // Check if designationKey exists in UsersMappingArea
    if (UsersMappingArea.hasOwnProperty(designation)) {
      let formcontrolKey = UsersMappingArea[designation].toLowerCase(); // No optional chaining needed here
     if(formcontrolKey=="sub division"){
      formcontrolKey="subDivision";
     }
  
      control = this.userForm.get(formcontrolKey);
      controlValue = control ? control.value : null;
  
       primaryName = controlValue && typeof controlValue === 'object' && 'label' in controlValue
        ? controlValue.label
        : '';
        primaryUuid = controlValue && typeof controlValue === 'object' && 'value' in controlValue
        ? controlValue.value
        : '';
  
     
  
    }
    let hierarchyDetails: any = {
        
    };
    if (UsersMapping.hasOwnProperty(designation)) {
     
      const levels = UsersMapping[designation]; // Levels associated with designation
    
    
      levels.forEach((level: string) => {
        const formControlKey = level!="subDivision"?level.toLowerCase():level; // Form control key
        const control = this.userForm.get(formControlKey); // Get form control
        const controlValue = control ? control.value : null; // Get form control value
    
        if (controlValue) {
          const uuid =
            typeof controlValue === 'object' && 'value' in controlValue
              ? controlValue.value
              : null;
          const name =
            typeof controlValue === 'object' && 'label' in controlValue
              ? controlValue.label
              : null;
    
          // Add to the result object dynamically
          hierarchyDetails[`${level}_uuid`] = uuid;
          hierarchyDetails[`${level}_name`] = name;
        }
      });
    
      console.log(hierarchyDetails); // Output the constructed object
    }
    
    // Check if the control value is an object and contains 'label'
   
    const postdata = {
      metaInfo: {
        "username": this.userForm.value.username,
        "firstName": this.userForm.value.firstName,
        "lastName": this.userForm.value.lastName,
        "email": this.userForm.value.email,
        "mobileNo": this.primaryMobileForm.controls['primaryMobileNo'].value.e164Number,
        "customerId": "",
        "customerName": "" ,
       // "associatedUserId":userInfo.userId,
       "createdBy":userInfo.userId,
        // "associatedUsername" : userInfo.username,
        "userDetailsJson":{
          data:{
            "designation":this.userForm.value.designation.key,
            "location_type":UsersMappingArea[designation],

            "primary_uuid":primaryUuid,
            "primary_name":primaryName,
            "secondary_uuid":null,
            "secondary_name":null,
            "locationHierarchy":this.userForm.value.location.key
          },
          hierarchyDetails:hierarchyDetails
        }

      }
    }
    
    //* super admin get from selected customer / for customers fetch from login details
    if (!this.isCustomer && this.userForm.value.customers && this.userForm.value.customers.customerId && this.userForm.value.customers.customerName) {
      postdata['metaInfo']['customerId'] = this.userForm.value.customers.customerId;
      postdata['metaInfo']['customerName'] = this.userForm.value.customers.customerName;
    } else {
      this.userMetaInfo = this.appState.getUserMetaInfo();
      postdata['metaInfo']['customerId'] = this.userMetaInfo['customerId'];
      postdata['metaInfo']['customerName'] = this.userMetaInfo['customerName'];
    }

    return postdata;
  }

  onPaste(event: ClipboardEvent, key: any) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text');
    let trimmedText = pastedText.replace(/[^a-zA-Z]/g, '');
    setTimeout(() => {
      trimmedText = trimmedText.substr(0, 25);
      this.userForm.patchValue({
        [key]: trimmedText
      });
    }, 1);
  }

  resetField(event : Country) {
    this.isPrimaryMobileNoValid = true;
    this.primaryMobileForm.controls.primaryMobileNo.setValue(null);
    this.currentCountryCode = event.iso2;
  }
  updateUser(userId: any, addUserPostData : any) {
  
    let postData = {
      "metaInfo": null,
      "addRoles": USER_MGMT_CNST.EE_USER_ADD_ROLE,
      "updateRoles": {},
      "deleteRoles": [],
      "userId": userId
  }
    this.httpSrv.makePutApiCall('UPDATE_USER', postData, this.envService.baseUrl, {
      uriParams: {
        userId
      }
    }).subscribe(res => {
      setTimeout(() => {
        
        this.utilsSrv.showToastMessage(
          "Successfully added customer admin ",
          "success"
        );   
      }, 3000);
      this.activeModal.close({ updateTable: true }); 
    }, err => {
      this.utilsSrv.showToastMessage(
        "Error occured while creating customer admin",
        "error"
      );    
    })
  }
  
  checkLength(event: any) {
    let value = event.target.value;

    if (value.length > 0) {
      this.isPrimaryMobileNoValid = this.primaryMobileForm.invalid ? false : true;
    } else {
      this.isPrimaryMobileNoValid = true;
    }
  }

  


  callFunction(item: string) {
    console.log("item", item);
    
    switch (item) {
      case 'REGION':
        // Pass relevant arguments for 'circle'
        this.getDataForField('circle', item, this.userForm.value.region.value);
        this.showNextField('circle');
        break;
      case 'CIRCLE':
        // Pass relevant arguments for 'division'
        this.getDataForField('division', item, this.userForm.value.circle.value);
        this.showNextField('division');
        break;
      case 'DIVISION':
        // Pass relevant arguments for 'subDivision'
        this.getDataForField('subDivision', item, this.userForm.value.division.value);
        this.showNextField('subDivision');
        break;
      case 'SUBDIVISION':
        // Pass relevant arguments for 'section'
        this.getDataForField('section', 'SUB DIVISION', this.userForm.value.subDivision.value);
        this.showNextField('section');
        break;
      case 'STATE':
        // Pass relevant arguments for 'district'
        this.getDataForField('district', item, this.userForm.value.state.value);
        this.showNextField('district');
        break;
      case 'DISTRICT':
        // Pass relevant arguments for 'block'
        this.getDataForField('block', item, this.userForm.value.district.value);
        this.showNextField('block');
        break;
      default:
        console.log(`No action for ${item}`);
    }
  }
  

  // Show the next field in the hierarchy if available
  showNextField(nextField: string) {
    let designation = this.userForm.value.designation.value;
designation = designation.replace(/\s+/g, '_');
    if (UsersMapping[designation].includes(nextField)) {
      this.visibleFields.push(nextField);
    }
  }
  getDataList(item) {
    switch (item) {
      case 'region':
      return this.regionNames;
      case 'circle':
        return this.circleNames;
      case 'division':
        return this.divisionNames;
      case 'subDivision':
        return this.subDivisionNames;
      case 'section':
       return this.sectionNames;
      case 'district':
        return this.districtNames;
      case 'state':
         return this.stateName;
      case 'block':
        return this.blockNames;
      
      default:
        return [];
    }
  }
  checkDesignation() {
    let designation = this.userForm.value.designation.value;
designation = designation.replace(/\s+/g, '_');
    this.fieldsToAdd = UsersMapping[designation] || [];
    console.log("fieldsToAdd",this.fieldsToAdd)
    this.clearDynamicControls();
    this.setInitialVisibleFields();
    if (this.fieldsToAdd&&this.userForm.value.location.key=="Region") {
      this.fieldsToAdd.forEach(field => {
        this.userForm.addControl(field, new FormControl('', [Validators.required]));
      });
      this.getRegions();
    }else if (this.fieldsToAdd&&this.userForm.value.location.key=="State") {
      this.fieldsToAdd.forEach(field => {
        console.log("fileds",field)
        this.userForm.addControl(field, new FormControl('', [Validators.required]));
      });
      let data=  {
        "value":"e98cd5b7-6556-4c0f-a778-3429e1c14a6b",
        "label":"TAMILNADU"
        }
         this.stateName=[];
        this.stateName.push(data)
        this.userForm.controls['state'].setValue(data);
        this.getdistricts('STATE');
        this.showNextField('district');
    }
   
  }
  checkLocation(){
    const locationControl = this.userForm.get('location')
    const location = this.userForm.value.location.key; //
    console.log("location",locationControl)
    this.designationList=LOCATION_WISE_DESIGNATION_LIST[location];
    if (locationControl.valid && !this.userForm.contains('designation')) {
      this.userForm.addControl('designation', new FormControl('', [Validators.required]));
    }else{
      
      this.clearDynamicControls(true);
    // this.setInitialVisibleFields();
    this.userForm.removeControl('designation');
    this.userForm.addControl('designation', new FormControl('', [Validators.required]));
    }
   
  }

  clearDynamicControls(location?:any) {
   
    console.log("clearing")
    const dynamicFields = ['region', 'circle', 'division', 'subDivision', 'section','state','district','block'];
    dynamicFields.forEach(field => {
      if (this.userForm.contains(field)) {
        this.userForm.removeControl(field);

      }
    });
    if(location){
      this.fieldsToAdd=[];
    }
  }
  capitalizeFirstLetter(item: string): string {
    if (!item) return item;
    return item.charAt(0).toUpperCase() + item.slice(1).toLowerCase();
  }
  setInitialVisibleFields() {
    let designation = this.userForm.value.designation.value;
designation = designation.replace(/\s+/g, '_');

    this.visibleFields = UsersMapping[designation].slice(0, 1); // Initially only the first field
  }

  // Dynamically show/hide fields based on user input and progress
  shouldDisplayField(item: string) {
    return this.visibleFields.includes(item);
  }
  


  // Generalized method to handle fetching data based on different fields
getDataForField(field: string, pType: string, pUUID: string) {
  // Create the payload for the request dynamically
  let postData = {
    pType: pType,
    cType: ptypeCtye[pType],
    pUUID: pUUID
  };

  // Reset form controls related to the next steps (section, division, etc.)
  this.resetFormControls(field);

  // Fetch data dynamically based on the field
  this.getData(postData).subscribe((res: any) => {
    // Dynamically set the list based on the field
    switch (field) {
      case 'circle':
        this.circleNames = this.createNamesList(res);
        break;
      case 'division':
        this.divisionNames = this.createNamesList(res);
        break;
      case 'subDivision':
        this.subDivisionNames = this.createNamesList(res);
        break;
      case 'section':
        this.sectionNames = this.createNamesList(res);
        break;
      case 'district':
        this.districtNames = this.createNamesList(res);
        break;
      case 'block':
        this.blockNames = this.createNamesList(res);
        break;
      default:
        console.log(`Unknown field: ${field}`);
    }
  }, err => console.log(err));
}




resetFormControls(field?: string) {
  const dynamicFields = ['region', 'circle', 'division', 'subDivision', 'section', 'state', 'district', 'block'];
  // If a specific field is provided, reset it
  if (field && this.userForm.get(field)) {
    this.userForm.get(field).setValue('');
  }

  // If no specific field is provided, reset all fields
  if (!field) {
    dynamicFields.forEach(fieldName => {
      if (this.userForm.get(fieldName)) {
        this.userForm.get(fieldName).setValue('');
      }
    });
  }

  // If 'circle' is reset, also reset related fields
  if (field === 'circle') {
    this.resetRelatedFields(['circle', 'division', 'subDivision', 'section']);
  }
  
  // If 'division' is reset, also reset related fields
  if (field === 'division') {
    this.resetRelatedFields(['division', 'subDivision', 'section']);
  }
  
  // If 'subDivision' is reset, also reset related fields
  if (field === 'subDivision') {
    this.resetRelatedFields(['subDivision', 'section']);
  }

  // If 'state' is reset, also reset related fields
  if (field === 'state') {
    this.resetRelatedFields(['district', 'block']);
  }

  // If 'district' is reset, also reset related fields
  if (field === 'district') {
    this.resetRelatedFields(['block']);
  }
}

// Helper method to reset a set of fields
resetRelatedFields(fields: string[]) {
  fields.forEach(field => {
    if (this.userForm.get(field)) {
      this.userForm.get(field).setValue('');
    }
  });
}


// Helper method to convert data response into a list of objects
createNamesList(res: any) {
  let namesList = [];
  for (let key in res) {
    let obj = {};
    obj["value"] = key;
    obj["label"] = res[key];
    namesList.push(obj);
  }
  return namesList;
}

}


