import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { USER_MGMT_CNST } from 'src/app/constants/proj.cnst';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-add-action',
  templateUrl: './add-action.component.html',
  styleUrls: ['./add-action.component.scss']
})
export class AddActionComponent implements OnInit {

  @Input() modalData:any;
  isEditAction : boolean = false;
  status : boolean = true;
  actionType : any = 'Add New'

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    this.isEditAction = this.modalData && this.modalData.editAction ?  this.modalData.editAction : false;
    if(this.isEditAction){
      this.actionType = USER_MGMT_CNST.OPTIONS.EDIT;
      this.status =  this.modalData.status=='Active' ? true : false;
    }
    if(!this.isEditAction){
      this.modalData = [];
    }
  }


  onlyAlphabet(event: any) {
    const pattern = /[a-zA-Z ]/;

    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  onFormSubmit(form: NgForm) {
  }

}
