import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { ToastrModule } from 'ngx-toastr';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { keyCloakInitializer } from './keycloak-initializer';

import { AppComponent } from './app.component';
import { LayoutModule } from './modules/layout/layout.module';
import { ApplicationsModule } from './pages/applications/applications.module';
import { CustomerModule } from './pages/customer/customer.module';
import { PopupModule } from './pages/popups/popup.module';
import { ActionsModule } from './pages/actions/actions.module';
import { RolesModule } from './pages/roles/roles.module';
import { UserModule } from './pages/user/user.module';
import { ResourcesModule } from './pages/resources/resources.module';
import { SharedModule } from './modules/shared/shared.module';
import { AuthGuard } from "./guards/auth.guard";
import { ProfilesModule } from './pages/profiles/profiles.module';
import { AdministratorsModule } from './pages/administrators/administrators.module';
import { AuthInterceptorService } from './pages/auth/auth-interceptor.service';
import { InitService } from './parsers/init.service';
import { ErrorInterceptor } from './pages/auth/error.service';
import { TemplateReferenceDirective } from './shared_directives/template-reference.directive';
import { ApiResponseInterceptor } from './pages/auth/api-response-interceptor.service';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { EnvServiceProvider } from './services/env.service.provider';
import { EnvService } from './services/env.service';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
// import { CookieService } from 'ngx-cookie-service';

@NgModule({
  declarations: [
    AppComponent,
    TemplateReferenceDirective,
    PageNotFoundComponent,
    LandingPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LayoutModule,
    SharedModule,
    ApplicationsModule,
    CustomerModule,
    PopupModule,
    ActionsModule,
    RolesModule,
    UserModule,
    ResourcesModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    ProfilesModule,
    AdministratorsModule,
    BrowserAnimationsModule,
    KeycloakAngularModule
  ],
  providers: [
    //* Register keycloak app with angular
    EnvServiceProvider,
    {
      provide: APP_INITIALIZER,
      useFactory: keyCloakInitializer,
      multi : true,
      deps : [Injector, KeycloakService, EnvService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass : ApiResponseInterceptor,
      multi : true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    AuthGuard,
    InitService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
