import { Component, OnInit, Input ,OnDestroy} from '@angular/core';

import { USER_MGMT_CNST } from 'src/app/constants/proj.cnst';
import { UM_TABLE_CNST } from 'src/app/constants/table.cnst';

import { EditProfileComponent } from '../../../../pages/profiles/components/edit-profile/edit-profile.component';
import { DeleteComponent } from '../../../../modules/shared/components/delete/delete.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppStateService } from 'src/app/services/app-state.service';
import { HttpService } from 'src/app/services/http.service';
import { UtilsService } from 'src/app/services/utils.service';
import { Router } from '@angular/router';
import { EnvService } from 'src/app/services/env.service';

@Component({
  selector: 'app-profiles-list',
  templateUrl: './profiles-list.component.html',
  styleUrls: ['./profiles-list.component.scss']
})
export class ProfilesListComponent implements OnInit {
 
  @Input() tableConfig : any;
  @Input() tableData : any;
  @Input() isCustomer: any;
  applicationList : any = [];
  modalData: any = [];
  dataTypes = UM_TABLE_CNST.CLICKABLE_DATA_TYPE;
  
  constructor(
    private modal: NgbModal,
    private appStateSrv: AppStateService,
    private httpSrv: HttpService,
    private utilsSrv: UtilsService,
    private envService: EnvService,
    private router: Router) { }

  ngOnInit() { }
  ngOnDestroy(){
    this.modal.dismissAll();
}
  handleOptionClick(e) {
    let rowData = e.data;

    if (e.val == USER_MGMT_CNST.OPTIONS.VIEW) {

      let postData = this.tableData[e.key];

      if (this.utilsSrv.isObjectNotNullAndEmpty(postData)) {
        let { roleId } = postData;

        this.appStateSrv.setProfileData({ operation: 'view', info: postData });

        this.router.navigate(['', 'roles', 'role'], {
          queryParams: {
            operation : 'view',
            roleId
          }
        });
      }
    }

    else if (e.val == USER_MGMT_CNST.OPTIONS.DELETE) {
      const modalRef = this.modal.open(DeleteComponent, USER_MGMT_CNST.DELETE_POPUP_OPTIONS);
      let postData = {
        role_id: this.tableData[e.key]['roleId']
      }

      modalRef.result
        .then((result: any) => {
          if(result == true){
          this.setOperationType({
          operation: USER_MGMT_CNST.OPERATION_TYPES.PROFILE.DELETE_PROFILE,
          postData
        })
      }
      })
        .catch((reason: any) => {
          console.log("Reason for rejection ", reason);
        })

    }
    else {
      this.httpSrv
        .makeGetApiCall('APP_LIST', this.envService.baseUrl)
        .subscribe((response: any) => {
          this.applicationList = response;
          const modalRef = this.modal.open(EditProfileComponent, USER_MGMT_CNST.EDIT_MODAL_OPTIONS);

          modalRef.componentInstance.modalData = this.modalData;
          this.modalData.profilename = rowData[1] ? rowData[1]['values'][0] : '-'
          if(this.isCustomer){
            this.modalData.profiledesc = rowData[3] ? rowData[3]['values'][0] : '-';
            this.modalData.status = rowData[4] ? rowData[4]['values'][0] : '-';
          }else{
            this.modalData.profiledesc = rowData[4] ? rowData[4]['values'][0] : '-';
            this.modalData.status = rowData[5] ? rowData[5]['values'][0] : '-';
          }
          this.modalData.applicationList = this.applicationList;
          this.modalData.editAction = true;
          this.modalData.role_id = this.tableData[e.key]['roleId'];
          this.modalData.applicationId = this.tableData[e.key]['applicationId'];
          this.modalData.roleDetailsJson = this.tableData[e.key]['roleDetailsJson'] || { validScopes: [] };

          modalRef.result
            .then((result: any) => this.setOperationType({
              operation: result.operation,
              postData: result.postData
            }))
            .catch((reason: any) => {
              console.log("Reason for rejection ", reason);
            })
        }, error => {
          this.utilsSrv.showToastMessage("Error occured while fetching list of applications", "error");
        })

    }
  }

  setOperationType(value : any) {
    console.log("setting operation type");
    this.appStateSrv.operationRef.next(value);
  }

}

