import { Injectable } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { USER_MGMT_CNST } from '../constants/proj.cnst';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../modules/authentication/services/authentication.service';

/**
   * NOTE: . 
   * Use this service to make api calls.
   * Don't use HttpClient anywhere else in project.
   */
@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http: HttpClient, private authSrv: AuthenticationService) { }

  makeGetApiCall(api: string, baseUrl: string, options?: {}): Observable<any> {
    let apiEndPoint = this.getApiEndPoint(api);

    if (!isNullOrUndefined(apiEndPoint) && !isNullOrUndefined(baseUrl)) {
      if (options && Object.keys(options).length > 0) {
        let { endPoint, headers, queryParams } = this.buildRequestOptions(apiEndPoint, options);
        return this.http.get(baseUrl + endPoint, { headers : headers, params: queryParams});
      }
      return this.http.get(baseUrl + apiEndPoint).pipe(map((res: any) => res.response ? res.response : res));
    } else {
      console.error('API endpoint / URL should not be null or undefined ', apiEndPoint, '', baseUrl);
      return of(null);
    }
  }

  makePutApiCall(api: string, postData: any, baseUrl: string, options?: {}): Observable<any> {
    let apiEndPoint = this.getApiEndPoint(api);

    if (!isNullOrUndefined(apiEndPoint) && !isNullOrUndefined(baseUrl)) {
      if (options && Object.keys(options).length > 0) {
        let { endPoint, headers, queryParams } = this.buildRequestOptions(apiEndPoint, options);
        return this.http.put(baseUrl + endPoint,postData ,{ headers, params: queryParams });
      }
      return this.http.put(baseUrl + apiEndPoint,postData);
    } else {
      console.error('API endpoint / URL should not be null or undefined ', apiEndPoint, '', baseUrl);
      return of(null);
    }
  }


  makeDeleteApiCall(api: string, baseUrl: string, options?: {}): Observable<any> {
    let apiEndPoint = this.getApiEndPoint(api);

    if (!isNullOrUndefined(apiEndPoint) && !isNullOrUndefined(baseUrl)) {
      if (options && Object.keys(options).length > 0) {
        let { endPoint, headers, queryParams } = this.buildRequestOptions(apiEndPoint, options);
        return this.http.delete(baseUrl + endPoint, { headers, params: queryParams });
      }
      return this.http.delete(baseUrl + apiEndPoint);
    } else {
      console.error('API endpoint / URL should not be null or undefined ', apiEndPoint, '', baseUrl);
      return of(null);
    }
  }

  makePostApiCall(api: string, postData: any, baseUrl: string, options?: {}) : Observable<any> {
    let apiEndPoint = this.getApiEndPoint(api);

    if (!isNullOrUndefined(apiEndPoint) && !isNullOrUndefined(baseUrl)) {
      if (options && Object.keys(options).length > 0) {
        let { endPoint, headers, queryParams } = this.buildRequestOptions(apiEndPoint, options);
        return this.http.put(baseUrl + endPoint,postData ,{ headers, params: queryParams });
      }
      return this.http.post(baseUrl + apiEndPoint,postData);
    } else {
      console.error('API endpoint / URL should not be null or undefined ', apiEndPoint, '', baseUrl);
      return of(null);
    }
  }

  getApiEndPoint(api: string) : string | null {
    if(isNullOrUndefined(api) || api === '' || isNullOrUndefined(USER_MGMT_CNST.API_MAPPING[api])) {
      return null;
    } else {
      return USER_MGMT_CNST.API_MAPPING[api];
    }
  }

  buildOptionsForEndPoint(apiEndPoint: string, options: any): string {
    let values = Object.values(options);

    values.forEach((value, index) => {
      if (index !== (values.length) - 1) {
        apiEndPoint += `/${value}`
      } else {
        apiEndPoint += `/${value}`
      }
    })
    return apiEndPoint;
  }

  buildRequestOptions(endpoint: string, options: any) : any {
    let paramsObj = null;
    let headerObj = null;

    let { uriParams, queryParams, headers, replaceParams } = options;

    let isUriParamsPresent = this.checkIfObjectKeyHasValue(uriParams);
    let isQueryParamsPresent = this.checkIfObjectKeyHasValue(queryParams);
    let isHeadersPresent = this.checkIfObjectKeyHasValue(headers);
    const isReplaceParamsPresent = this.checkIfObjectKeyHasValue(replaceParams);

    if(isUriParamsPresent) {
      endpoint = this.buildOptionsForEndPoint(endpoint, uriParams);
    }

    if(isQueryParamsPresent) {
      paramsObj = this.addQueryParamsToEndPoint(queryParams);
    }

    if(isHeadersPresent) {
      headerObj = this.addHeadersToRequest(headers);
    }

    if(isReplaceParamsPresent) {
      for(let key in replaceParams) {
        const exp = new RegExp(`${key}`, 'g');
        const value = replaceParams[key];
        endpoint = endpoint.replace(exp, value);
      }
    }

    return {
      endPoint: endpoint,
      headers: headerObj,
      queryParams: paramsObj
    }

  }


  addQueryParamsToEndPoint(queryParams: any) : HttpParams {
    let params: HttpParams = new HttpParams();

    for(let key in queryParams) {
      let value = queryParams[key];
      params = params.set(key, value);
    }

    return params;
  }

  addHeadersToRequest(headers: any) : HttpHeaders {
    let header: HttpHeaders = new HttpHeaders();
    // if(this.authSrv.isLoggedIn()) {
    //   console.log("Logged in");
    //   const token = this.authSrv.getToken();
    //   header.set('Authorization', token);
    // }
    // headers.set('Access-Control-Allow-Origin', '*');

    for(let key in headers) {
      let value = headers[key];
      header = header.append(key, value);
    }

    return header;
  }

  checkIfObjectKeyHasValue(obj: any) : boolean {
    if(isNullOrUndefined(obj)) return false;

    return Object.keys(obj).length > 0 ? true : false;
  }
}
