import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppStateService, HttpService, UtilsService } from 'src/app/services';
import { USER_MGMT_CNST } from 'src/app/constants/proj.cnst';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { EnvService } from 'src/app/services/env.service';
import { isNullOrUndefined } from 'util';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-assign-resources',
  templateUrl: './assign-resources.component.html',
  styleUrls: ['./assign-resources.component.scss']
})
export class AssignResourcesComponent implements OnInit, OnDestroy {

  applicationId: any;
  appDetails: any;

  resourcesData: any = null;
  resourcesDataCopy : any = null;
  resourceTypeMap = USER_MGMT_CNST.RESOURCE_TYPES_MAP;

  resourceTypes: string[];
  customerDetails: any;
  customerId: string;

  subscriptions : Subscription[] = [];
  
  constructor(
    private appState: AppStateService,
    private httpSrv: HttpService,
    private utilSrv: UtilsService,
    private dataSrv : DataService,
    private envService: EnvService,
    private router: Router,
    private route : ActivatedRoute) { }

  ngOnInit() {
    this.customerDetails = this.appState.getCustomerData();
    this.customerId = this.appState.getCurrentCustomerId();
    this.appDetails = this.appState.getSelectedResourceDetails();
    this.applicationId = this.appDetails['applicationId'];

    const queryParamRef = this.route.queryParams.subscribe(async (params: Params) => {
      let customerId = params['customerId'];
      let applicationId = params['applicationId'];
      let applicationName = params['applicationName']

      this.customerId = customerId;
      this.applicationId = applicationId;

      let options = {
        'uriParams': {
          customerId
        }
      }

      if (!this.utilSrv.isObjectNotNullAndEmpty(this.appDetails)) {
        let appsListWithResources = await this.dataSrv.fetchAllResourcesForAllAssignedAppsByCustomerId(options);

        if (this.utilSrv.isObjectNotNullAndEmpty(appsListWithResources)) {
          let { response } = appsListWithResources;

          if (this.utilSrv.isObjectNotNullAndEmpty(response)) {

            let appInfo = Object.keys(response)
              .filter((appName: string) => applicationName === appName);

            if (appInfo.length > 0) {
              let appDetails = response[appInfo[0]];

              if (this.utilSrv.isObjectNotNullAndEmpty(appDetails)) {

                let modifiedAppDetails = {
                  key : applicationName,
                  value : appDetails
                }

                this.appDetails = modifiedAppDetails;
              }
            }
          }
        }
      }

      this.fetchData();
    })

    this.subscriptions.push(queryParamRef);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  fetchData() {

    if (!isNullOrUndefined(this.applicationId)) {
      this.httpSrv
        .makeGetApiCall('FETCH_RESOURCES_BY_APPLICATION_ID', this.envService.baseUrl, { uriParams: { applicationId: this.applicationId } })
        .subscribe((response: any) => {
          this.resourcesData = this.formatResponse(response['response']);
          this.resourcesDataCopy = { ...this.resourcesData };
        }, error => {
          this.utilSrv.showToastMessage("Failed to fetch applications list", "error");
        })
    } else {
      console.log("Application id is null or undefined ", this.applicationId);
      this.utilSrv.showToastMessage("Unable to fetch resources for application", "error");
    }
  }

  formatResponse(data: any) {
    let resourceTypeDataMap = {
      "APIs": [],
      "URLs": [],
      "Dashboards": [],
      "Widgets": []
    };
    this.resourceTypes = USER_MGMT_CNST.RESOURCE_TYPES;
    data.forEach(e => {
      if (this.appDetails && this.appDetails['value'] && this.appDetails['value'][e.resourceTypeName]) {
        let filterData = this.appDetails['value'][e.resourceTypeName].find(r => r.resourceId === e.resourceId);
        e['isAssigned'] = filterData ? true : false;
      } else {
        e['isAssigned'] = false;
      }
      resourceTypeDataMap[e.resourceTypeName].push(e);
    });
    return resourceTypeDataMap;
  }

  assignOrUpdateResources() {

    let customerId = !isNullOrUndefined(this.customerId) 
        ? this.customerId
        : this.appState.getCurrentCustomerId();

    let postData = {
      "customerId": null,
      "assignResources": [],
      "deleteResources": [],
      "customerDetailsJson": "{}"
    }

    if (!isNullOrUndefined(customerId)) {

      postData.customerId = customerId;

      let resources = this.getformatResourceData();

      postData.assignResources = this.getAssignedResource(resources.selectedResources, resources.previouslySelectedResource);
      postData.deleteResources = this.getDeletedResource(resources.selectedResources, resources.previouslySelectedResource);

      if (postData.assignResources.length === 0 && postData.deleteResources.length === 0) {
        this.utilSrv.showToastMessage("No resources selected to assign / update", "error");
        return;
      } else {
        this.httpSrv
          .makePostApiCall('ASSIGN_OR_UPDATE_RESOURCES', postData, this.envService.baseUrl)
          .subscribe((response: any) => {
            this.utilSrv.showToastMessage("Resources updated successfully ", "success");
            this.navigateToCustomerView();

          }, err => {
            this.utilSrv.showToastMessage("Error in assigning/updating resources", "error");
          })
      }
    }
  }

  getAssignedResource(selectedResources, previouslySelectedResource){
    let assignedResource = [];
    selectedResources.forEach(e=>{
       if(previouslySelectedResource.indexOf(e) == -1){
         assignedResource.push(e);
       }
    })
    return assignedResource;
  }

  getDeletedResource(selectedResources, previouslySelectedResource){
    let deletedResource = [];
    previouslySelectedResource.forEach(e=>{
       if(selectedResources.indexOf(e) == -1){
         deletedResource.push(e);
       }
    });

    return deletedResource;
  }

  getformatResourceData(){
    let selectedResources = [];
    let previouslySelectedResource = [];
    this.resourceTypes = USER_MGMT_CNST.RESOURCE_TYPES;
    this.resourceTypes.forEach(type=>{
      let selectedList = [];
      if(this.resourcesData && Object.keys(this.resourcesData).length !=0 && this.resourcesData[type]){
        let list = this.resourcesData[type].map(e => {return {resourceActionId:e['resourceActionId'], isAssigned:e['isAssigned']}});
        let filteredList = list.filter(e=> e['isAssigned'] === true);
        selectedList = filteredList.map(e=> e['resourceActionId']);
      }

      let previouslySelectedList = [];
      if(this.appDetails && this.appDetails['value'] && Object.keys(this.appDetails['value']).length !=0 && this.appDetails['value'][type]){
        previouslySelectedList = this.appDetails['value'][type].map(e => e['resourceActionId']);
      }
      selectedResources = selectedResources.concat(selectedList);
      previouslySelectedResource = previouslySelectedResource.concat(previouslySelectedList);
    });
    
    return {selectedResources, previouslySelectedResource};
  }

  navigateToCustomerView() {        
    this.router.navigate(['', 'customers', 'addCustomer'], {
      queryParamsHandling: 'merge'
    });
  }

  onResourceSearch(resourceType: string, event: any) {
    let resourcesUnderResourceType: any[] = this.resourcesDataCopy[resourceType];

    let searchQuery = event.target.value.toLowerCase();

    if (!isNullOrUndefined(resourcesUnderResourceType) && resourcesUnderResourceType.length > 0) {

      if (searchQuery.length > 0) {
        resourcesUnderResourceType = resourcesUnderResourceType
          .filter((resource: any) => resource.resourceName.toLowerCase().includes(searchQuery));

        this.resourcesData[resourceType] = [...resourcesUnderResourceType];
      } else {
        this.resourcesData = { ...this.resourcesDataCopy };
      }
    }
  }

  buildCustomerInfo() {

    if (!isNullOrUndefined(this.customerDetails)) {
      return {
        ...this.customerDetails['info'],
        customerId: this.customerId,
        primaryContact: this.customerDetails['info'].primaryContact,
        secondaryContact: this.customerDetails['info']['secondaryContact']
          ? this.customerDetails['info']['secondaryContact'] : null
      };
    }
  }
}
