import { Component, Input, OnInit } from '@angular/core';
import { USER_MGMT_CNST } from 'src/app/constants';
import { Router } from '@angular/router';
import { AppStateService, HttpService, UtilsService } from 'src/app/services';
import { EnvService } from 'src/app/services/env.service';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-application-resources-table',
  templateUrl: './application-resources-table.component.html',
  styleUrls: ['./application-resources-table.component.scss']
})
export class ApplicationResourcesTableComponent implements OnInit {

  @Input() tableData : any;
  @Input() hasAssignedResources : boolean;
  @Input() customerId: any;
  @Input() operation : string;

  opts = USER_MGMT_CNST.OPTIONS;


  resourceTypes: string[] = [];
  listOfApplications: any;

  constructor(
    private router:Router,          
    private appState:AppStateService,
    private envService : EnvService,
    private httpSrv: HttpService,
    private utilSrv : UtilsService) { }

  ngOnInit() {
    this.resourceTypes = USER_MGMT_CNST.RESOURCE_TYPES;

    if (this.operation !== 'view') {
      this.fetchApplicationList();
    }
  }
  
  fetchApplicationList() {

    this.customerId = !isNullOrUndefined(this.customerId) 
      ? this.customerId 
      : this.appState.getCurrentCustomerId();

    if (!isNullOrUndefined(this.customerId)) {
      this.httpSrv
        .makeGetApiCall(
          'FETCH_ASSIGNED_APPLICATION_LIST_FOR_CUSTOMER',
          this.envService.baseUrl, { uriParams: { customerId: this.customerId } })
        .subscribe((res: any) => {
          this.listOfApplications = res['response'];
          console.log("List of applications ", this.listOfApplications);
        })
    } else {
      console.log("Customer id is null or undefined ", this.customerId);
      this.utilSrv.showToastMessage("Failed to fetch assigned applications for customer", "error");
    }
  }

  handleOptionClick(type: string, app: string) {
    let applicationId;
    console.log(app);
    let applicationInfo = this.listOfApplications.filter(e => e.applicationName === app['key']);

    if (applicationInfo && applicationInfo.length > 0) {
      applicationInfo = applicationInfo[0];
      applicationId = applicationInfo.applicationId;
    } else {
      applicationId = null;
    }


    if (type === 'Edit') {
      this.appState.setSelectedResourceDetails({ applicationId, applicationName: app['key'], value: app['value'] });
      
      this.router.navigate(['', 'customers', 'assignResources'], {
        queryParams: {
          applicationId,
          applicationName: app['key']
        },
        queryParamsHandling : 'merge'
      });

    } else {
      this.appState.setDeletedAppInfoDetails(applicationInfo);
    }
  }
}
