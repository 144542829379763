import { InjectionToken, NgModule } from '@angular/core';
import { Routes, RouterModule, ActivatedRouteSnapshot } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { AuthGuard } from './guards/auth.guard';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';

const externalUrlProvider = 
  new InjectionToken('externalUrlRedirectResolver');

const routes: Routes = [
  // { path: "", redirectTo: "users", pathMatch: "full" },

  { path: "", redirectTo: "authentication", pathMatch: "full" },
  { path: "authentication",loadChildren: () => import("./modules/authentication/authentication.module").then(m => m.AuthenticationModule) },
  
  { path : "landing-page", 
    component : LandingPageComponent, 
    canActivate: [AuthGuard] 
  },

  {
    path: "users",
    loadChildren: () => import('./pages/user/user.module').then(m => m.UserModule),
    canActivate: [AuthGuard],
    data: {resource: "USER"}
  },
  // {
  //   path: "roles",
  //   loadChildren: () => import('./pages/roles/roles.module').then(m => m.RolesModule),
  //   canActivate: USER_MGMT_CNST.ACCESS_TYPE === USER_MGMT_CNST.ACCESS_TYPE_LIST.PROD ? [AuthGuard] : [AuthGuard],
  //   data: {resource: "ROLE"}
  // },
  {
    path: "applications",
    loadChildren: () => import('./pages/applications/applications.module').then(m => m.ApplicationsModule),
    canActivate: [AuthGuard],
    data: {resource: "APPLICATION"}
  },
  {
    path: "resources",
    loadChildren: () => import('./pages/resources/resources.module').then(m => m.ResourcesModule),
    canActivate: [AuthGuard],
    data: {resource: "RESOURCE"}
  },
  {
    path: "actions",
    loadChildren: () => import('./pages/actions/actions.module').then(m => m.ActionsModule),
    canActivate: [AuthGuard],    
    data: {resource: "ACTION"}
  },
  {
    path: "roles",
    loadChildren: () => import('./pages/profiles/profiles.module').then(m => m.ProfilesModule),
    canActivate: [AuthGuard],    
    data: {resource: "ROLE"}
  },
  {
    path: "administrators",
    loadChildren: () => import('./pages/administrators/administrators.module').then(m => m.AdministratorsModule),
    canActivate: [AuthGuard],
  },
  {
    path: "errors",
    loadChildren: () => import('./pages/error-pages/error-pages.module').then(m => m.ErrorPagesModule)
  },
  {
    path: "customers",
    loadChildren: () => import('./pages/customer/customer.module').then(m => m.CustomerModule),
    canActivate: [AuthGuard],    
    data: {resource: "CUSTOMER"}
  },
  {
    // path: USER_MGMT_CNST.REDIRECT_TO_OTHER_APPS.appUrl,
    path: "externalUrl",

    resolve: {
      url: externalUrlProvider,
    },
    // canActivate: [externalUrlProvider],
    
    // We need a component here since a route cannot 
    // be defined otherwise
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers : [
    {
      provide: externalUrlProvider,
      useValue : (route : ActivatedRouteSnapshot) => {
        // We get this param from the directive
        const externalUrl = route.paramMap.get('externalUrl');
        console.log("External Url ", externalUrl);

        if (!isNullOrUndefined(externalUrl)) {
          
          // Open the new app in the same tab
          open(externalUrl, '_self');
        }
      }
    }
  ]
})
export class AppRoutingModule { }
