import { ActionsComponent } from './actions.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddActionComponent } from './components/add-action/add-action.component';
import { RouterModule } from '@angular/router';
import { ActionListComponent } from './components/action-list/action-list.component';
import { TableModule } from 'src/app/lib';
import { ViewActionsComponent } from './components/view-actions/view-actions.component';
import { FormsModule } from '@angular/forms';

const routes = [
  { path: '', redirectTo: "list", pathMatch: "full" },
  { path: 'list', component: ActionListComponent },
  { path: 'add', component: AddActionComponent }
]


@NgModule({
  declarations: [ActionsComponent,
    AddActionComponent,
    ActionListComponent,
    ViewActionsComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TableModule,
    FormsModule
  ],
  entryComponents: [ViewActionsComponent, AddActionComponent]
})
export class ActionsModule { }
